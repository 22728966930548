import { calculateTextDimensions } from "utils";

export const checkForOverlappingAndOutOfBoundsLabels = (
  dataToChartRatio: number,
  chartWidth: number,
  lastLabel: string,
  lastX: number,
  currentLabel: string,
  currentX: number
): boolean => {
  const lastHalvedTextWidth = calculateTextDimensions(lastLabel).width / 2;
  const lastPositionOfLabel = lastX / dataToChartRatio;
  const lastLabelExtent = lastHalvedTextWidth + lastPositionOfLabel;

  const halvedTextWidth = calculateTextDimensions(currentLabel).width / 2;
  const positionOfLabel = currentX / dataToChartRatio;
  const labelExtendsBeyondChart = positionOfLabel + halvedTextWidth > chartWidth;

  const labelOverlaps = lastLabelExtent > positionOfLabel - halvedTextWidth;
  return labelOverlaps || labelExtendsBeyondChart;
};
