import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useMutation } from "@apollo/client";
import { ForecastActivityType } from "constants/settings.constants";

import { FOLDERS, RESTORE_FORECAST } from "api/userArps";

import { useSelectedProject } from "components/project/projects/hooks";

import { RootState } from "../../../../store/rootReducer";
import { useTypeWellUpdater, useUndoRedo } from "../../../arps/hooks";

export interface RestoreForecastRequest {
  forecastId: string;
  folderId: string;
  restoreDate: string;
}

export const useRestoreForecast = (forecastType: ForecastActivityType) => {
  const undoRedoManager = useUndoRedo();
  const { onTypeWellValueChange } = useTypeWellUpdater(undoRedoManager);

  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);

  const { selectedProject: project } = useSelectedProject();
  const [restoreForecastMutation, { loading, error }] = useMutation(RESTORE_FORECAST);

  const restoreForecast = async (RestoreForecastRequest: RestoreForecastRequest) => {
    const input = {
      forecastId: RestoreForecastRequest.forecastId,
      folderId: RestoreForecastRequest.folderId,
      restoreDate: RestoreForecastRequest.restoreDate
    };
    await restoreForecastMutation({
      variables: { input },
      refetchQueries: [
        {
          query: FOLDERS,
          variables: { req: { projectId: project?.projectId, type: forecastType } }
        }
      ],
      onCompleted: (data) => {
        const arpSegments = data.restoreForecast;
        const updatedTypeWell = {
          ...selectedTypeWell,
          arps: arpSegments
        };
        if (updatedTypeWell) {
          onTypeWellValueChange(updatedTypeWell);
        }
      },
      onError: (err) => {
        toast.error(err.message);
      }
    });
  };

  return { restore: restoreForecast, loading, error };
};
