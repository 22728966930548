import { Button } from "antd";
import styled from "styled-components";

export const IconButton = styled(Button)`
  color: #a2aaad;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  &:hover {
    color: var(--color-primary);
  }
`;
