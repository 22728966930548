import { memo, useCallback, useEffect, useMemo } from "react";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Checkbox from "antd/lib/checkbox/Checkbox";
import classnames from "classnames";
import { getProductLabel } from "constants/chart.constants";
import { debounce } from "lodash";
import styled from "styled-components";

import { useHover } from "hooks";

import { IGroupByListColumn } from "models/groupBy";

import FieldGroup from "./FieldGroup";
import FieldListItemInfo from "./FieldListItemInfo";
import FieldOverflowTooltip from "./FieldOverflowTooltip";
import UserFieldAction from "./UserFieldAction";
import { useGroupByState, useGroupByUpdater } from "./group-by.context";
import { useSelectedCategory } from "./hooks";

const OrgDefinedColumnPrefix = "org_focus";
const UserDefinedColumnPrefix = "my_focus";
const OrgDefinedMidstreamColumnPrefix = "org_midstream";
const UserDefinedMidstreamColumnPrefix = "my_midstream";

export type FieldListItemT = {
  value: IGroupByListColumn;
  dragEnabled: boolean;
  groups: FieldGroup[];
  shortcutsEnabled: boolean;
};

function FieldListItem({ value, dragEnabled, groups, shortcutsEnabled }: FieldListItemT) {
  const { tooltip, title, product, defaultUnit, property, isDisabled } = value;
  const { isUserDefinedColumn, isOrgDefinedColumn } = useSelectedCategory();
  const stateDispatch = useGroupByUpdater();
  const isUserDefinedField =
    property.toLowerCase().startsWith(OrgDefinedColumnPrefix) ||
    property.toLowerCase().startsWith(UserDefinedColumnPrefix) ||
    property.toLowerCase().startsWith(OrgDefinedMidstreamColumnPrefix) ||
    property.toLowerCase().startsWith(UserDefinedMidstreamColumnPrefix);

  const isCustomColumn = useMemo(() => {
    return isOrgDefinedColumn || isUserDefinedColumn;
  }, [isOrgDefinedColumn, isUserDefinedColumn]);

  const {
    props,
    checkedFields,
    customFieldsEditToggle,
    selectedField,
    isForecastToggleOn,
    selectedForecastFolder,
    selectedForecastFolderName,
    selectedPdenSource,
    isFiltering,
    categoryColors,
    entityKind
  } = useGroupByState();

  const [hoverRef, isHovered] = useHover();

  const selectField = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newValue: any = { ...value };

    props.onChange(
      newValue,
      isForecastToggleOn,
      selectedForecastFolder,
      selectedForecastFolderName,
      selectedPdenSource
    );

    stateDispatch({ type: "selected_field", payload: newValue });
    stateDispatch({ type: "filter", payload: "" });
  }, [
    value,
    props,
    isForecastToggleOn,
    selectedForecastFolder,
    selectedForecastFolderName,
    selectedPdenSource
  ]);

  // Used for arrow key navigation between focus fields in the group by list
  useEffect(() => {
    const handleArrowKeyPress = (event: KeyboardEvent) => {
      // If the selected field is not the current field, or the component is not being rendered by the preset dropdown, return
      if (selectedField?.id !== value.id || !groups || !shortcutsEnabled) return;

      let nextField: IGroupByListColumn;
      let nextGroupIndex: number;
      let nextFieldIndex: number;

      // Find the index of the current field in the groups array
      const currentGroupIndex = groups.findIndex((group) =>
        group?.fields.some((field) => field.id === value.id)
      );
      const currentFieldIndex = groups[currentGroupIndex]?.fields.findIndex(
        (field) => field.id === value.id
      );

      if (currentGroupIndex === -1 || currentFieldIndex === -1) return;

      // Logic for navigating to the next field with arrow down key
      if (event.ctrlKey && event.key === "ArrowDown") {
        const isNextFieldInSameGroup: boolean =
          currentFieldIndex < groups[currentGroupIndex]?.fields.length - 1;
        const isNextFieldLastItemInSameGroup: boolean =
          currentGroupIndex < groups.length - 1;
        if (isNextFieldInSameGroup) {
          nextGroupIndex = currentGroupIndex;
          nextFieldIndex = currentFieldIndex + 1;
        } else if (isNextFieldLastItemInSameGroup) {
          nextGroupIndex = currentGroupIndex + 1;
          nextFieldIndex = 0;
        } else {
          nextGroupIndex = 0;
          nextFieldIndex = 0;
        }
        // Logic for navigating to the next field with arrow up key
      } else if (event.ctrlKey && event.key === "ArrowUp") {
        const isNextFieldInSameGroup: boolean = currentFieldIndex > 0;
        const isNextFieldFirstItemInSameGroup: boolean = currentGroupIndex > 0;
        if (isNextFieldInSameGroup) {
          nextGroupIndex = currentGroupIndex;
          nextFieldIndex = currentFieldIndex - 1;
        } else if (isNextFieldFirstItemInSameGroup) {
          nextGroupIndex = currentGroupIndex - 1;
          nextFieldIndex = groups[nextGroupIndex]?.fields.length - 1;
        } else {
          nextGroupIndex = groups.length - 1;
          nextFieldIndex = groups[nextGroupIndex]?.fields.length - 1;
        }
      }

      // If the next field index is defined, update the selected field
      if (nextGroupIndex !== undefined && nextFieldIndex !== undefined) {
        nextField = groups[nextGroupIndex]?.fields[nextFieldIndex];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newValue: any = { ...nextField };
        props.onChange(
          newValue,
          isForecastToggleOn,
          selectedForecastFolder,
          selectedForecastFolderName,
          selectedPdenSource
        );
      }
    };

    // Debounce the arrow key press event to prevent multiple events from firing
    const debouncedHandleArrowKeyPress = debounce(handleArrowKeyPress, 200);
    window.addEventListener("keydown", debouncedHandleArrowKeyPress);
    return () => {
      window.removeEventListener("keydown", debouncedHandleArrowKeyPress);
    };
  });

  const rootClassName = classnames({
    selected: value.property === selectedField?.property
  });

  const onClick = useCallback(
    (e) => {
      if (customFieldsEditToggle && isCustomColumn) {
        return;
      }
      return isDisabled ? e.preventDefault() : selectField();
    },
    [customFieldsEditToggle, isCustomColumn, isDisabled, selectField]
  );

  const productContextTitle =
    isFiltering && product ? `${getProductLabel(product, entityKind)} ${title}` : title;
  const dynamicTitle =
    isUserDefinedField && defaultUnit
      ? `${productContextTitle} (${defaultUnit})`
      : productContextTitle;

  const titleId = `title-${property}`;

  const isChecked = useMemo(
    () => checkedFields.includes(value.id),
    [checkedFields, value.id]
  );

  const handleCheckboxChange = useCallback(() => {
    stateDispatch({
      type: "checked_fields",
      payload: isChecked
        ? checkedFields.filter((checkedItem) => checkedItem !== value.id)
        : [...checkedFields, value.id]
    });
  }, [isChecked, checkedFields, value.id, stateDispatch]);

  return (
    <Wrapper
      ref={hoverRef}
      role="listitem"
      tabIndex={0}
      className={rootClassName}
      onClick={(e) => onClick(e)}
      onMouseEnter={() => stateDispatch({ type: "hovered_field", payload: value })}
      onMouseLeave={() => stateDispatch({ type: "hovered_field", payload: null })}
      isDisabled={isDisabled}>
      {isCustomColumn && <Indent />}
      {isCustomColumn && customFieldsEditToggle && (
        <>
          <DragHandleIconWrapper dragEnabled={dragEnabled}>
            <DragHandleIcon />
          </DragHandleIconWrapper>

          <CheckboxItem
            checked={isChecked}
            onClick={(evt) => evt.stopPropagation()}
            onChange={handleCheckboxChange}></CheckboxItem>
        </>
      )}
      {isFiltering && <FieldColorPatch color={categoryColors[value.group]} />}
      <FieldOverflowTooltip title={dynamicTitle} titleId={titleId}>
        <Title id={titleId}>{dynamicTitle}</Title>
      </FieldOverflowTooltip>
      <FieldListItemInfo value={tooltip} />
      <UserFieldAction visible={isHovered} field={value} entityKind={entityKind} />
    </Wrapper>
  );
}

export default memo(FieldListItem);

const CheckboxItem = styled(Checkbox)`
  overflow-x: visible !important;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
`;

const Indent = styled.div`
  min-width: 19px;
`;
const Wrapper = styled.div`
  min-width: 0;
  display: flex;
  align-items: center;
  gap: var(--space-1);
  border-radius: var(--border-radius);
  color: var(--color-action-button);
  font-size: 1.4rem;
  font-weight: var(--fontWeightMedium);
  padding: var(--space-05) var(--space-4);
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  user-select: none;

  &:hover,
  &.selected {
    color: ${(props) =>
      props.isDisabled ? "var(--color-action-button)" : "var(--color-text)"};
    background-color: ${(props) =>
      props.isDisabled ? "var(--color-white)" : "var(--color-accent-hover)"};
  }

  &:focus {
    box-shadow: ${(props) => !props.isDisabled && "var(--shadow-focus)"};
  }
`;

const Title = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

const DragHandleIconWrapper = styled.span`
  visibility: ${({ dragEnabled }) => (dragEnabled ? "visible" : "hidden")};
  color: #c9d1d2;
  font-size: 16px;
`;

const FieldColorPatch = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  border-radius: 2px;
  flex-shrink: 0;
`;
