import { useContext } from "react";

import { ForecastDispatchContext } from "./ForecastContext";

export function useForecastDispatch() {
  const context = useContext(ForecastDispatchContext);
  if (context === undefined) {
    throw new Error("useForecastDispatch must be used within a Forecast Provider");
  }
  return context;
}
