import { toast } from "react-toastify";

import { rgbStringToHex } from "utils/color/rgbHelpers";

import { IColorPalette } from "components/user-settings/models";

const DEFAULT_COLOR = "rgb(0,0,0)"; //Black

const colorPolygons = (activeColorPalette, geomBinItems, dispatch, newPolygon) => {
  const palette = activeColorPalette as unknown as IColorPalette;

  if (!palette) {
    toast.error("Error drawing new polygon, color palette not found.");
    dispatch({
      payload: {
        isLassoSelected: false
      }
    });
    return;
  }

  const totalItems = geomBinItems.length + 1;
  const requiredColorInterpolation = totalItems > palette.colors.length;

  // Calculate step to pick colors from palette manually
  let colorIndexStep =
    totalItems <= 2
      ? palette.colors.length - 1
      : Math.floor(palette.colors.length / (totalItems - 1)) - 1;
  colorIndexStep = colorIndexStep <= 0 ? 1 : colorIndexStep;
  let colorIndex = 0;

  // Start and End color of the palette
  let color1, colorX;

  // Start and End color rgb channels
  let r1, g1, b1, rx, gx, bx;

  const paletteFirstColor = palette.colors[0].replaceAll(" ", "");
  const paletteLastColor = palette.colors[palette.colors.length - 1].replaceAll(" ", "");

  // check if seeding color has correct rgb color format
  // Also, stop calculate color interpolation if we still haven't run out of color in the palete
  if (
    requiredColorInterpolation &&
    /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/.test(paletteFirstColor) &&
    /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/.test(paletteLastColor)
  ) {
    // Start color and rgb channel
    color1 = paletteFirstColor.split(",");
    r1 = parseInt(color1[0].split("(")[1]);
    g1 = parseInt(color1[1]);
    b1 = parseInt(color1[2].split(")")[0]);

    // End color and rgb channel
    colorX = paletteLastColor.split(",");
    rx = parseInt(colorX[0].split("(")[1]);
    gx = parseInt(colorX[1]);
    bx = parseInt(colorX[2].split(")")[0]);
  }

  const newGeomBinItems = !newPolygon
    ? geomBinItems
    : [
        ...geomBinItems,
        {
          id: newPolygon.id.toString(),
          name: "Polygon " + totalItems,
          geom: JSON.stringify(newPolygon),
          order: totalItems,
          color: rgbStringToHex(palette.colors[(totalItems - 1) % palette.colors.length]),
          strokeColor: rgbStringToHex(
            palette.colors[(totalItems - 1) % palette.colors.length]
          ),
          opacity: 0.3,
          thickness: 2
        }
      ];

  const coloredGeomBinItems = newGeomBinItems.map((item, idx) => {
    // ratio of the current item compared to the start and end items
    const ratio = idx / (totalItems > 1 ? totalItems - 1 : 1);
    let calculatedColor = palette.colors[colorIndex];

    if (requiredColorInterpolation && !newPolygon) {
      // calculate color interpolation for the item if we have correct seeding data
      // Fall back to default black color if needed
      calculatedColor = color1?.length
        ? `rgb(${Math.trunc(ratio * rx + (1 - ratio) * r1)},${Math.trunc(
            ratio * gx + (1 - ratio) * g1
          )},${Math.trunc(ratio * bx + (1 - ratio) * b1)})`
        : DEFAULT_COLOR;
    }

    const coloredItem = {
      ...item,
      color: item.color ?? rgbStringToHex(calculatedColor),
      strokeColor: item.strokeColor ?? rgbStringToHex(calculatedColor),
      opacity: item.opacity ?? 0.3,
      thickness: item.thickness ?? 2
    };

    // Step up color until we reach the end of the palette
    colorIndex += colorIndex == palette.colors.length - 1 ? 0 : colorIndexStep;

    return coloredItem;
  });

  return coloredGeomBinItems;
};

export default colorPolygons;
