import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _findIndex from "lodash/findIndex";
import _isEqual from "lodash/isEqual";
import { AppThunk } from "store/store";
import { uid } from "utils";

import {
  generateFilterQuery,
  getFilterCountsFromFilterId,
  getWellsFromFilterId
} from "api/filter";
import { MapExtent } from "api/useTownshipRangeGrid";

import { LegendItemModel } from "models/LegendItem";
import {
  ActiveFilterItem,
  FilterModel,
  IFilterState,
  IPropertyLegendFilter
} from "models/filter";

import toLegendItem from "components/filter/toLegendItem";
import { getLegendFilter } from "components/filter/util";

import {
  EMPTY_FILTER_ID,
  FACILITY_CURRENT_FILTER_ID_LOCAL_STORAGE_KEY
} from "../../../constants/app.constants";
import { FilterState, SortByT } from "./filterSlice";
import hasOnlyMapPolygonFilterChanges from "./utils/hasOnlyMapPolygonFilterChanges";

const initialState: FilterState = {
  filterId: {
    id: "",
    lastUpdated: 0
  },
  sortBy: "AlphabeticalAsc",
  showGroupsNotInFilter: true,
  filterSource: "extent",
  wellListFilter: [],
  polygonFilter: null,
  excludePolygonsFilter: [],
  isFiltering: false,
  wellListFilterExtent: undefined,
  resetPolygonFilter: false,
  updatePlayLegend: "",
  currentWellList: [],
  filterCounts: {},
  propertiesFilter: [],
  currentFilter: undefined, //global currently applied filter
  selectedSavedFilter: ActiveFilterItem,
  filterHistoryJSON: [],
  savedFilters: [],
  filtersLoaded: false,
  currentPlayList: []
};

const facilityFilterSlice = createSlice({
  name: "facilityFilter",
  initialState,
  reducers: {
    updateCurrentFilter(state, action: PayloadAction<FilterModel>) {
      if (action.payload && !action.payload.query.predicates.includes(null)) {
        state.currentFilter = action.payload;
      }
    },
    setFilterCounts(state, action) {
      state.filterCounts = action.payload;
    },
    setFilterState(state, action: PayloadAction<IFilterState>) {
      const filters = action.payload;
      if (!filters) return;
      state.wellListFilter =
        action.payload.wellListFilter && !action.payload.wellListFilter.includes(null)
          ? action.payload.wellListFilter
          : [];
      if (!action.payload.polygonFilter) {
        //no polygon filter saved (i.e. no selection)
        state.resetPolygonFilter = true; // reset filter
      } else {
        state.polygonFilter = action.payload.polygonFilter;
      }

      state.excludePolygonsFilter = action.payload.excludePolygonsFilter ?? [];
      state.propertiesFilter = action.payload.propertiesFilter;
    },
    setFilterId(state, action: PayloadAction<{ id: string; lastUpdated: number }>) {
      const filterId = action.payload;
      // If filterId is null,undefined or empty, don't set filter id
      if (!filterId) return;
      state.filterId = filterId;
      state.filterSource = "well-list";

      // Set filterId in local storage so that it can be removed after browser refresh.
      if (filterId.id !== EMPTY_FILTER_ID) {
        localStorage.setItem(FACILITY_CURRENT_FILTER_ID_LOCAL_STORAGE_KEY, filterId.id);
      }
    },
    setCurrentWellList(state, action) {
      state.currentWellList = action.payload;
    },
    setResetPolygonFilter(state, action) {
      state.resetPolygonFilter = action.payload;
    },
    setUpdatePlayLegend(state, action) {
      state.updatePlayLegend = action.payload;
    },
    setSavedFilters(state, action) {
      state.savedFilters = action.payload;
    },
    removeAllProperties(state) {
      state.propertiesFilter = [];
      state.wellListFilter = [];
      state.excludePolygonsFilter = [];
      state.updatePlayLegend = "";
    },
    removeProperty(state, action) {
      const pred = JSON.parse(JSON.stringify(action.payload));
      const properties = JSON.parse(JSON.stringify(state.propertiesFilter));
      const found = properties.filter((p) =>
        p.predicates.find((prop) => {
          return _isEqual(prop, pred);
        })
      );

      if (found.length === 0) {
        return;
      }
      const idx = _findIndex(found[0].predicates, (p) => {
        return _isEqual(p, pred);
      });
      //if predicate is not found then return
      if (idx < 0) {
        return;
      }
      found[0].predicates.splice(idx, 1);
      if (found[0].predicates.length === 0) {
        found.forEach((item) => {
          const index = _findIndex(properties, (p) => _isEqual(p, item));
          if (index > -1) {
            properties.splice(index, 1);
          }
        });
      }
      if (pred.property === "Header.ResourcePlay") {
        state.updatePlayLegend = "";
      }
      state.propertiesFilter = properties;
    },
    filterWellsFromList(state, wellListPayload: PayloadAction<string[]>) {
      let wellListFilter = null;
      const wellList = wellListPayload.payload;
      if (wellList.length > 0) {
        wellListFilter = {
          type: "WellList",
          property: "UniqueId",
          operator: "in",
          id: uid(),
          filterType: "well-list",
          value: wellList
        };
      }
      state.wellListFilter = [wellListFilter];
      state.propertiesFilter = [];
      state.resetPolygonFilter = true; // reset polygon filter
    },
    setIsFiltering(state, action: PayloadAction<boolean>) {
      state.isFiltering = action.payload;
    },
    ignoreWellsFromWellList(state, wellListPayload) {
      let wellListFilter = null;
      const wellList = wellListPayload.payload;
      if (wellList.length > 0) {
        wellListFilter = {
          type: "WellList",
          property: "UniqueId",
          operator: "nin",
          id: uid(),
          filterType: "well-list",
          value: wellList
        };
        state.wellListFilter.push(wellListFilter);
      }
    },
    removeWellListFilter(state, action) {
      const payload = action.payload;
      const index = state.wellListFilter.findIndex((filter) => filter.id === payload.id);
      if (index < 0) {
        return;
      }
      state.wellListFilter.splice(index, 1);
    },
    filterWellsFromMapExtent(state, polygonPayload) {
      const polygon = polygonPayload.payload;
      //make a copy in case polygon gets cleared
      const json = JSON.stringify(polygon);
      const poly = JSON.parse(json);
      poly.id = uid();
      state.polygonFilter = poly;
    },
    excludeWellsInPolygon(state, polygonPayload) {
      const polygon = polygonPayload.payload;
      //make a copy in case polygon gets cleared
      const json = JSON.stringify(polygon);
      const poly = JSON.parse(json);
      poly.id = uid();
      state.excludePolygonsFilter.push(poly);
    },
    removeExcludePolygon(state, action) {
      const pred = JSON.parse(JSON.stringify(action.payload));
      const polygons = JSON.parse(JSON.stringify(state.excludePolygonsFilter));
      const remaining = polygons.filter((p) => p.id !== pred.id);

      // Nothing removed
      if (remaining.length === polygons.length) {
        return;
      }

      state.excludePolygonsFilter = remaining;
    },
    removeAllExcludePolygon(state) {
      state.excludePolygonsFilter = [];
    },
    setFilterHistory(state, payload: PayloadAction<string[]>) {
      state.filterHistoryJSON = [...payload.payload];
    },
    addToFilterHistory(state, payload: PayloadAction<IFilterState>) {
      if (!hasOnlyMapPolygonFilterChanges(state.filterHistoryJSON, payload.payload)) {
        state.filterHistoryJSON = [
          ...state.filterHistoryJSON,
          JSON.stringify(Object.assign({}, payload.payload))
        ].slice(-200);
      }
    },
    addManyPropertiesToFilter(
      state,
      filterPayload: PayloadAction<IPropertyLegendFilter>
    ) {
      const properties = filterPayload.payload.legendFilters;
      const propertyName = filterPayload.payload.propertyName;
      if (!propertyName) {
        return;
      }
      const props = [...state.propertiesFilter];
      const found = props.filter((p) =>
        p.predicates.find((prop) => prop.property === propertyName)
      );
      found.forEach((item) => {
        const index = props.indexOf(item);
        if (index > -1) {
          props.splice(index, 1);
        }
      });
      if (properties && properties.length > 0) {
        for (const property of properties) {
          for (const pred of property.predicates) {
            pred.id = uid();
          }
          props.push(property);
        }
      }
      state.propertiesFilter = props;
    },
    updateSortBy(state, action: PayloadAction<SortByT>) {
      state.sortBy = action.payload;
    },
    updateShowGroupsNotInFilter(state, action: PayloadAction<boolean>) {
      state.showGroupsNotInFilter = action.payload;
    },
    toggleShowGroupsNotInFilter(state) {
      state.showGroupsNotInFilter = !state.showGroupsNotInFilter;
    },
    setSelectedSavedFilter(state, action) {
      state.selectedSavedFilter = action.payload;
    },
    setWellListExtent(state, action: PayloadAction<MapExtent>) {
      state.wellListFilterExtent = action.payload;
    },
    setFiltersLoaded(state, action: PayloadAction<boolean>) {
      state.filtersLoaded = action.payload;
    },
    resetFacilityFilterSlice: () => initialState
  }
});

export const updateActiveFilterCount =
  (filterId: string): AppThunk =>
  async (dispatch) => {
    if (!filterId || filterId === EMPTY_FILTER_ID) {
      // If filter is null,undefined or empty, don't get filter count
      return;
    }
    const filterCountsResponse = await getFilterCountsFromFilterId(filterId);

    if (filterCountsResponse.status === 200) {
      const filterCounts = filterCountsResponse.data;
      dispatch(setFilterCounts(filterCounts));
    }
  };

export const undoFilter = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const history = [...state.facilityFilter.filterHistoryJSON];
  const mapCoordinates = state.map.mapExtent.coordinates;

  if (history.length > 1) {
    const item = history[history.length - 2];
    dispatch(setFilterHistory(history.slice(0, -1)));
    const filterState: IFilterState = JSON.parse(item);
    filterState.polygonFilter.coordinates = mapCoordinates;
    await dispatch(setFilterState(filterState));
  }
};

export const addLegendItemToFilter =
  (title: string, groupByProperty?: string | undefined): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const legendItems = state.map.txnId?.legend?.legendItems;
    if (!legendItems) {
      return;
    }
    const items: LegendItemModel[] = legendItems.filter(
      (li: LegendItemModel) => li.groupTitle === title || li.title === title
    );
    if (items.length === 0) {
      return;
    }
    const item = toLegendItem(
      items,
      state.groupBy?.globalGroupBy,
      state.filter.propertiesFilter
    )[0];
    const filter = getLegendFilter(
      item.property,
      item.canBin,
      [item].map((item) => item.title),
      item.categoryId,
      item.bin,
      item.normalizeBySettings,
      item.isNotEqual,
      item.pdenSource,
      item.value
    );
    dispatch(
      addManyPropertiesToFilter({
        legendFilters: [filter],
        propertyName: groupByProperty ?? state.groupBy.globalGroupBy?.property
      })
    );
  };

export const addMultipleLegendItemsToFilter =
  (
    titles: string[],
    isExclude: boolean,
    groupByProperty?: string | undefined
  ): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const legendItems = state.map.txnId?.facilityLegend?.legendItems;
    if (!legendItems) {
      return;
    }
    const items: LegendItemModel[] = legendItems
      .filter(
        (li: LegendItemModel) =>
          titles.includes(li.groupTitle) || titles.includes(li.title)
      )
      .map((item: LegendItemModel) => {
        return { ...item, isNotEqual: true };
      });
    if (items.length === 0) {
      return;
    }
    const newLegendItems = toLegendItem(
      items,
      state.groupBy?.globalFacilityFocus,
      state.facilityFilter.propertiesFilter
    );
    const legendFilters = newLegendItems.map((item) => {
      return getLegendFilter(
        item.property,
        item.canBin,
        [item].map((item) => item.title),
        item.categoryId,
        item.bin,
        item.normalizeBySettings,
        isExclude,
        item.pdenSource,
        item.value
      );
    });

    dispatch(
      addManyPropertiesToFilter({
        legendFilters: legendFilters,
        propertyName: groupByProperty ?? state.groupBy.globalGroupBy?.property
      })
    );
  };

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export const doFilter =
  (getFilter): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    dispatch(setIsFiltering(true));
    const previousFilterId =
      localStorage.getItem(FACILITY_CURRENT_FILTER_ID_LOCAL_STORAGE_KEY) ??
      state.facilityFilter.filterId.id;
    const polygonFilter = state.facilityFilter.polygonFilter;
    if (!polygonFilter?.type?.length) {
      dispatch(setIsFiltering(false));
      return;
    }
    const query = generateFilterQuery(
      state.facilityFilter.wellListFilter,
      state.facilityFilter.polygonFilter,
      state.facilityFilter.excludePolygonsFilter,
      state.facilityFilter.propertiesFilter,
      previousFilterId,
      null
    );
    query.entityKind = "Facility";
    const currentFilterState: IFilterState = {
      polygonFilter: state.facilityFilter.polygonFilter,
      excludePolygonsFilter: state.facilityFilter.excludePolygonsFilter,
      wellListFilter: state.facilityFilter.wellListFilter,
      propertiesFilter: state.facilityFilter.propertiesFilter
    };
    dispatch(addToFilterHistory(currentFilterState));
    const result = await getFilter(query);

    let filterId = "";
    if (result && result.ok) {
      filterId = result.value;
    } else {
      return;
    }
    dispatch(updateCurrentFilter(query));
    dispatch(setFilterId({ id: filterId, lastUpdated: Date.now() }));
    if (
      state.facilityFilter?.wellListFilter?.length > 0 &&
      state.facilityFilter?.wellListFilter[0]?.value?.length > 0
    ) {
      const result = await getWellsFromFilterId(filterId);
      const ids = result.data.uwiList.map((uwi) => uwi.uniqueId);
      //only set well list extent if well list is different from current list
      if (
        result?.status === 200 &&
        !arraysEqual(state.facilityFilter.currentWellList, ids)
      ) {
        dispatch(setCurrentWellList(ids));
        dispatch(setWellListExtent(result.data.boundingBox));
      }
    }

    dispatch(setIsFiltering(false));
    return filterId;
  };
export const clearFacilityFilter = (): AppThunk => async (dispatch) => {
  dispatch(setFilterId({ id: EMPTY_FILTER_ID, lastUpdated: Date.now() }));
  localStorage.setItem(FACILITY_CURRENT_FILTER_ID_LOCAL_STORAGE_KEY, EMPTY_FILTER_ID);
};

export const {
  updateCurrentFilter,
  setFilterId,
  setFilterCounts,
  updateSortBy,
  updateShowGroupsNotInFilter,
  toggleShowGroupsNotInFilter,
  setFilterState,
  setCurrentWellList,
  setFilterHistory,
  setResetPolygonFilter,
  setUpdatePlayLegend,
  removeProperty,
  filterWellsFromList,
  ignoreWellsFromWellList,
  removeAllProperties,

  removeWellListFilter,
  setWellListExtent,
  filterWellsFromMapExtent,
  excludeWellsInPolygon,
  setIsFiltering,
  removeExcludePolygon,
  removeAllExcludePolygon,
  addManyPropertiesToFilter,
  setSelectedSavedFilter,
  setSavedFilters,
  addToFilterHistory,
  setFiltersLoaded,
  resetFacilityFilterSlice
} = facilityFilterSlice.actions;

export default facilityFilterSlice.reducer;
