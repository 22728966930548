import { toast } from "react-toastify";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk } from "store/store";

import { ICategoryFieldModel, INormalizeBySetting } from "models";

const columnSvcRoot = process.env.REACT_APP_COLUMN_SET_SERVICE;

interface NormalizeByState {
  useNormalizeBy: boolean;
  globalNormalizeBy: INormalizeBySetting;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  normalizeByItems: any[];
}

const initialState = {
  useNormalizeBy: false,
  globalNormalizeBy: {
    field: "Well_Design.HzLength_m",
    display: "Hz Length (m)",
    per: 100,
    unit: "m",
    useMultilinearNormalization: false,
    threshold: 0,
    lowerScalar: 1,
    higherScalar: 1
  },
  normalizeByItems: []
} as NormalizeByState;

const normalizeBySlice = createSlice({
  name: "normalizeBy",
  initialState,
  reducers: {
    setGlobalNormalizeBy(state, action) {
      state.globalNormalizeBy = action.payload;
    },
    setUseNormalizeBy(state, action) {
      state.useNormalizeBy = action.payload;
    },
    setNormalizeByItems(state, action: PayloadAction<INormalizeBySetting[]>) {
      const list = action.payload;
      state.normalizeByItems = list;
    },
    resetNormalizeBySlice: () => initialState
  }
});

export const getNormalizationItems = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${columnSvcRoot}/column/normalize`);
    if (response.status !== 200) {
      throw response.data;
    }
    const columns: ICategoryFieldModel[] = response.data;
    const normalizeFieldList: INormalizeBySetting[] = columns.map((column) => {
      return {
        field: column.property,
        display: column.title,
        per: column.normalizeByDefault,
        unit: column.defaultUnit,
        useMultilinearNormalization: false,
        threshold: 0,
        lowerScalar: 1,
        higherScalar: 1
      };
    });
    dispatch(setNormalizeByItems(normalizeFieldList));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    toast.error(`An error occurred retrieving normalization options.`);
    return;
  }
};

export const {
  setGlobalNormalizeBy,
  setUseNormalizeBy,
  setNormalizeByItems,
  resetNormalizeBySlice
} = normalizeBySlice.actions;

export default normalizeBySlice.reducer;
