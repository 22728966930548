import { IFilterState } from "../../../../models/filter";

/**
 * Determines if the new filter state only has polygon filter changes, from map movement.
 * Omits the polygon filter from the filter state, and then compares to the filter history.
 *
 * Created to address bug 821. We do not want to add map movement to the filter history stack,
 * and be able to "undo" those map movements.
 * https://turinganalytics.atlassian.net/jira/software/c/projects/EVA/boards/1?modal=detail&selectedIssue=EVA-821
 * @param {string[]} filterHistoryJSON the query to get the predicate models for.
 * @param {IFilterState} filterState The list of counts for the predicates.
 * @returns {IPredicateModel} The predicate model representation of the query.
 */
const hasOnlyMapPolygonFilterChanges = (
  filterHistoryJSON: string[],
  filterState: IFilterState
): boolean => {
  if (!filterHistoryJSON.length) {
    return false;
  }

  //We want to ignore changes to the polygon filter when the map is moved,
  //but not when using the lasso
  const { polygonFilter: currentPolygonFilter, ...currentFilterOmitted } = filterState;

  const lastFilter = JSON.parse(filterHistoryJSON[filterHistoryJSON.length - 1]);
  const { polygonFilter: lastPolygonFilter, ...lastFilterOmitted } = lastFilter;

  if (
    currentPolygonFilter.source === "selection" ||
    lastPolygonFilter.source === "selection"
  ) {
    return JSON.stringify(filterState) === JSON.stringify(lastFilter);
  }
  return JSON.stringify(currentFilterOmitted) === JSON.stringify(lastFilterOmitted);
};
export default hasOnlyMapPolygonFilterChanges;
