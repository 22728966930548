import { ElementType, useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";

import { Popover } from "antd";
import classnames from "classnames";
import _findIndex from "lodash/findIndex";
import _unique from "lodash/uniq";
import { setFlyToGroup, updateHoverLegendItem } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";
import { removeCount } from "utils";

import { LegendItemModel } from "models/LegendItem";

import { useLegendTitle } from "components/ui";

import { EntityKind } from "../../models/entityKind";
import LegendItem from "./LegendItem";

const ctrl_key = "Control";
const cmd_key = "Meta";
const escape_key = "esc";
const shift_key = "Shift";

const list_item_height = 30;

export interface LegendComponentModel {
  allowChecked?: boolean;
  className: string;
  contextMenuContent?: ElementType;
  items: LegendItemModel[];
  itemTemplate?: (item: LegendItemModel) => JSX.Element;
  legendWidth?: number;
  legendLabelSize?: number;
  selectedItems?: LegendItemModel[];
  showTitle?: boolean;
  showOnlyTemplate?: boolean;
  showCounts?: boolean;
  onChange?: (item) => void;
  onCheckChanged?: (checked, item: LegendItemModel) => void;
  onClickLock?: () => void;
  entityKind?: EntityKind;
}
function Legend({
  allowChecked = false,
  className = "",
  contextMenuContent = null,
  items = [],
  itemTemplate = null,
  legendWidth = 0,
  legendLabelSize = 14,
  selectedItems = [],
  showTitle = false,
  showOnlyTemplate = false,
  showCounts = true,
  onChange = null,
  onCheckChanged = undefined,
  onClickLock = null,
  entityKind = EntityKind.Well
}: LegendComponentModel) {
  const dispatch = useDispatch();

  // state from store
  const selectedGroups = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.groupBy.selectedGroups
      : state.groupBy.selectedFacilityGroups
  );

  const hoverLegendItem = useSelector(
    (state: RootState) => state.groupBy.hoverLegendItem
  );

  const showGroupsNotInFilter = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.filter.showGroupsNotInFilter
      : state.facilityFilter.showGroupsNotInFilter
  );

  const placeholderBinsEnabled = useSelector(
    (state: RootState) => state.groupBy.placeholderBinsEnabled
  );

  const title = useLegendTitle();

  // state
  const [activeItem, setActiveItem] = useState("");
  const [mouseEnter, setMouseEnter] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const [selected, setSelected] = useState<LegendItemModel[]>(selectedItems);

  // refs
  const isCtrlKeyDown = useRef(false);
  const isShiftKeyDown = useRef(false);
  const previousSelectedItem = useRef(null);
  const selectedGroupsLengthRef = useRef(0);

  // derived state
  const ContextMenuComponent = contextMenuContent as React.ElementType;
  const popoverContent = ContextMenuComponent ? (
    <ContextMenuComponent onSelect={() => setPopoverVisible(false)} />
  ) : (
    ""
  );
  // If legendWidth props is present, use that width, else use entire width
  const nextWidthInPx = legendWidth ? `${legendWidth}px` : "100%";

  // hotkey bindings
  useHotkeys(escape_key, () => {
    setSelectedItems([]);
  });

  useEffect(() => {
    if (selectedGroupsLengthRef.current !== selectedGroups.length) {
      //if selected groups differs (adding or removing) then force the
      //isSelected method to use only the selectedGroups selection
      const foundItems = selectedGroups
        .map((g) => items.find((si) => si.title === g))
        .filter((f) => f);
      setSelected(foundItems);
    }
    selectedGroupsLengthRef.current = selectedGroups.length;
  }, [selectedGroups, items]);

  function onClick(event, item) {
    // Check if single or double click
    if (event.detail === 1) {
      //clicked on an item so clear hover legend item
      dispatch(updateHoverLegendItem(""));
      setSelectedItems(item);
    } else if (event.detail === 2) {
      setSelectedItems(item);
      dispatch(setFlyToGroup(item.title));
    }
  }

  function setSelectedItems(item) {
    if (!item) return;
    if (isCtrlKeyDown.current) {
      //add to selected
      addToSelectedItem(item);
    } else if (isShiftKeyDown.current) {
      if (previousSelectedItem.current && !!isShiftKeyDown.current) {
        //range selection
        let prevIndex = _findIndex(
          items,
          (item) => item === previousSelectedItem.current
        );
        let currentIndex = _findIndex(items, (x) => x === item);
        if (prevIndex > currentIndex) {
          const temp = prevIndex;
          prevIndex = currentIndex;
          currentIndex = temp;
        }
        if (currentIndex >= 0) {
          const selectedItems = [];
          for (let i = prevIndex; i <= currentIndex; i++) {
            selectedItems.push(items[i]);
          }
          addItemsToSelected(selectedItems);
        }
      }
    } else {
      //replace selected
      if (Array.isArray(item) && item.length == 0) {
        setSelected([]);
      } else {
        setSelected([item]);
      }
    }

    previousSelectedItem.current = item;
  }

  const onLegendItemMouseUp = useCallback(
    (item) => {
      //only set selected if there's a single item selected
      if (selected.length <= 1 && item) {
        setSelectedItems(item);
      }
    },
    [selected]
  );

  useEffect(() => {
    onChange && onChange(selected);
  }, [onChange, selected]);

  useEffect(() => {
    setSelected([]);
  }, [items, setSelected]);

  const isSelected = useCallback(
    (item) => {
      return selected.map((x: LegendItemModel) => x?.title ?? "").includes(item.title);
    },
    [selected]
  );

  function addItemsToSelected(items) {
    const newList = [...selected, ...items];
    const itemsToAdd = _unique(newList);
    setSelected(itemsToAdd);
  }

  function addToSelectedItem(item) {
    if (!item) return;
    const loc = selected.indexOf(item);
    if (loc >= 0) {
      //remove from since it already exists
      const newList = [...selected];
      newList.splice(loc, 1);
      setSelected(newList);
      return;
    }
    setSelected([...selected, item]);
  }

  useEffect(() => {
    function onKeyUp() {
      isCtrlKeyDown.current = false;
      isShiftKeyDown.current = false;
    }
    const onKeyDown = (event) => {
      if (!isCtrlKeyDown.current) {
        isCtrlKeyDown.current = event.key === ctrl_key || event.key === cmd_key;
      }
      if (!isShiftKeyDown.current) {
        isShiftKeyDown.current = event.key === shift_key;
      }
    };
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      isCtrlKeyDown.current = false;
      isShiftKeyDown.current = false;
      document.removeEventListener("keydown", onkeydown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  useEffect(() => {
    // If the mouse is not within the legend area, set the item to active if
    // hovered in the map or a chart.
    if (!mouseEnter) {
      const hoverLegendItemValue = removeCount(hoverLegendItem);
      setActiveItem(hoverLegendItemValue);
    }
  }, [hoverLegendItem, mouseEnter]);

  const handleContextMenu = (item) => (e) => {
    e.preventDefault();

    onLegendItemMouseUp(item);
    setPopoverPosition({ x: e.pageX, y: e.pageY });
    setPopoverVisible(true);
  };

  // Row item for react-window
  const Row = ({ style, index, data }) => {
    const item = data[index];
    return (
      <div style={style} key={item.title}>
        <LegendItem
          style={{ fontSize: `${legendLabelSize / 10}rem` }}
          active={activeItem === item.value}
          allowChecked={allowChecked}
          item={item}
          showOnlyTemplate={showOnlyTemplate}
          selected={isSelected(item)}
          template={itemTemplate}
          showCounts={showCounts}
          onCheckChanged={onCheckChanged}
          onClick={(event) => onClick(event, item)}
          onClickLock={onClickLock}
          onContextMenu={handleContextMenu(item)}
          showGroupsNotInFilter={placeholderBinsEnabled ? showGroupsNotInFilter : false}
        />
      </div>
    );
  };

  // derive classnames
  const wrapperClassnames = classnames("legend", className);

  return (
    <Wrapper className={wrapperClassnames}>
      {showTitle && <TitleContainer labelSize={legendLabelSize}>{title}</TitleContainer>}

      {!items.length && title && (
        <NoWellsContainer>
          No {entityKind == EntityKind.Well ? "wells" : "facilities"} were found.
        </NoWellsContainer>
      )}

      <Popover
        arrowPointAtCenter
        content={popoverContent}
        trigger="contextMenu"
        placement="rightTop"
        open={popoverVisible}
        onOpenChange={(v) => setPopoverVisible(v)}>
        <PopoverTrigger x={popoverPosition.x} y={popoverPosition.y} />
      </Popover>

      <LegendItemsWrapper
        style={{ width: nextWidthInPx }}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => {
          setMouseEnter(false);
          // Clear the hovered item when the users mouse leaves the legend.
          dispatch(updateHoverLegendItem(""));
        }}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              style={{ lineHeight: legendLabelSize * 3 }}
              width={width}
              height={height}
              itemSize={Math.max(list_item_height, legendLabelSize * 1.2)}
              itemData={items.filter((item) => item.count > 0 || showGroupsNotInFilter)}
              itemCount={
                items.filter((item) => item.count > 0 || showGroupsNotInFilter).length
              }>
              {Row}
            </List>
          )}
        </AutoSizer>
      </LegendItemsWrapper>
    </Wrapper>
  );
}

// Removed memo, as it will not help in this case due to complex props. It will only add an overhead of comparing props before re-rendering.
export default Legend;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  font-family: Bai Jamjuree, "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  .react-contextmenu-wrapper {
    display: grid;
    grid-template-rows: max-content 1fr;
    user-select: none;

    .legend-title {
      margin: 0;
      background: var(--color-background-accent);
      display: block;
      text-align: center;
      padding: 5px;
    }
  }
`;

const PopoverTrigger = styled.span`
  position: fixed;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  width: 3px;
  height: 3px;
  background-color: red;
  border-radius: 100vmax;
  translate: -50% -50%;
  visibility: hidden;
`;

const LegendItemsWrapper = styled.div`
  min-height: 0;
  width: 100%;
  height: 100%;
  padding-right: 5px;
  padding-bottom: 5px;

  .legend-item {
    margin: 0;

    .legend-item-title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 0;
    }
  }
`;

const TitleContainer = styled.span`
  min-width: 100%;
  font-weight: bold;
  padding: 6px 12px;
  text-align: left;
  font-size: ${(props) => props.labelSize / 10 + 0.2}rem;
  line-height: ${(props) => props.labelSize / 10 + 0.2}rem;
`;

const NoWellsContainer = styled.div`
  font-size: 1.4rem;
  padding: 2px 16px 5px;
  color: var(--color-danger);
`;
