import {
  DEFAULT_AXIS_FONT_SIZE,
  DEFAULT_AXIS_LABEL_FONT_SIZE,
  DEFAULT_LEGEND_FONT_SIZE,
  DEFAULT_TITLE_FONT_SIZE
} from "constants/chart.constants";
import { THEME_FONT_FAMILY } from "constants/style.constants";
import { calculateTextDimensions } from "utils/dom";

type ChartFontSettingsT = {
  titleFontSize: number;
  axisFontSize: number;
  axisLabelFontSize: number;
  legendFontSize: number;
  fontFamily: string;
};

const applySpacingToChartOptions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartInstance: any,
  fontSettings: ChartFontSettingsT,
  showLogButton: boolean,
  isScreenshot: boolean = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (!options) return options;

  // Determine name gap for each axis
  const yAxisNameGaps = [];
  const yAxisList = Array.isArray(options.yAxis) ? options.yAxis : [options.yAxis ?? {}];
  yAxisList.forEach((axis, i) => {
    const nameGap = getYAxisNameGap(
      chartInstance,
      i,
      fontSettings.axisLabelFontSize,
      fontSettings.fontFamily
    );
    axis.nameGap = nameGap;
    yAxisNameGaps.push(nameGap);
  });

  const xAxisNameGaps = [];
  const xAxisList = Array.isArray(options.xAxis) ? options.xAxis : [options.xAxis ?? {}];
  xAxisList.forEach((axis) => {
    const nameGap = getXAxisNameGap(
      fontSettings.axisLabelFontSize,
      fontSettings.fontFamily
    );
    axis.nameGap = nameGap;
    xAxisNameGaps.push(nameGap);
  });

  // Determine offset for axis
  const offsetAmountY = getYAxisOffsets(
    options,
    yAxisNameGaps,
    fontSettings.axisFontSize,
    fontSettings.fontFamily
  );
  yAxisList.forEach((axis, i) => {
    axis.offset = offsetAmountY[i];
  });

  const offsetAmountX = getXAxisOffsets(
    options,
    xAxisNameGaps,
    fontSettings.axisFontSize,
    fontSettings.fontFamily
  );
  xAxisList.forEach((axis, i) => {
    axis.offset = offsetAmountX[i];
  });
  // Determine grid
  const grid = getGridSpacing(
    chartInstance,
    options,
    xAxisNameGaps,
    yAxisNameGaps,
    fontSettings.axisFontSize,
    fontSettings.titleFontSize,
    fontSettings.legendFontSize,
    fontSettings.fontFamily,
    isScreenshot
  );

  // Determine log position
  if (showLogButton) {
    xAxisList.forEach((axis, i) => {
      axis.logPosition = getLogButtonPosition(
        axis.position,
        grid.left,
        grid.right,
        grid.top,
        grid.bottom,
        0,
        offsetAmountX[i]
      );
    });

    yAxisList.forEach((axis, i) => {
      axis.logPosition = getLogButtonPosition(
        axis.position,
        grid.left,
        grid.right,
        grid.top,
        grid.bottom,
        yAxisNameGaps[i],
        offsetAmountY[i]
      );
    });
  }

  // Determine zoom sliders position
  if (options?.dataZoom?.length > 0) {
    options.dataZoom.forEach((slider) => {
      if (slider.type === "slider") {
        if (slider.xAxisIndex !== undefined) {
          slider.bottom = xAxisNameGaps[slider.xAxisIndex];
        } else if (slider.yAxisIndex !== undefined) {
          slider.left = yAxisNameGaps[slider.yAxisIndex];
        }
      }
    });
  }

  options.grid = grid;

  return options;
};

export default applySpacingToChartOptions;

export const getYAxisOffsets = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any,
  nameGaps: number[] = [],
  axisFontSize: number = DEFAULT_AXIS_FONT_SIZE,
  fontFamily: string = THEME_FONT_FAMILY
): number[] => {
  const offsetArray = [0, 0, 0, 0];

  // Determine what the height of the axis title will be.
  const axisSize = calculateTextDimensions("AXIS", axisFontSize, fontFamily);

  let firstPrimaryAxisIndex = -1;
  let firstSecondaryAxisIndex = -1;
  const yAxisList = Array.isArray(options.yAxis) ? options.yAxis : [options.yAxis ?? {}];
  yAxisList.forEach((axis, i) => {
    if (axis.position === "right") {
      if (firstSecondaryAxisIndex < 0) {
        offsetArray[i] = 0;
        firstSecondaryAxisIndex = i;
      } else {
        offsetArray[i] = nameGaps[firstSecondaryAxisIndex] + axisSize.height;
      }
    } else {
      if (firstPrimaryAxisIndex < 0) {
        offsetArray[i] = 0;
        firstPrimaryAxisIndex = i;
      } else {
        offsetArray[i] = nameGaps[firstPrimaryAxisIndex] + axisSize.height;
      }
    }
  });

  return offsetArray;
};

export const getXAxisOffsets = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any,
  nameGaps: number[] = [],
  axisFontSize: number = DEFAULT_AXIS_FONT_SIZE,
  fontFamily: string = THEME_FONT_FAMILY
): number[] => {
  const offsetArray = [0, 0];

  // Determine what the height of the axis title will be.
  const axisSize = calculateTextDimensions("AXIS", axisFontSize, fontFamily);

  let lastTopAxisIndex = -1;
  let lastBottomAxisIndex = -1;
  const axisList = Array.isArray(options.xAxis) ? options.xAxis : [options.xAxis ?? {}];
  axisList.forEach((axis, i) => {
    if (axis.position === "top") {
      if (lastTopAxisIndex < 0) {
        offsetArray[i] = 0;
        lastTopAxisIndex = i;
      } else {
        // Make sure to cover more than 2 axes
        offsetArray[i] = nameGaps[lastTopAxisIndex] + offsetArray[lastTopAxisIndex];
        lastTopAxisIndex++;
      }
    } else {
      if (lastBottomAxisIndex < 0) {
        offsetArray[i] = 0;
        lastBottomAxisIndex = i;
      } else {
        // Make sure to cover more than 2 axes
        offsetArray[i] =
          nameGaps[lastBottomAxisIndex] +
          axisSize.height +
          offsetArray[lastBottomAxisIndex];
        lastBottomAxisIndex++;
      }
    }
  });

  return offsetArray;
};

export const getYAxisNameGap = (
  chartInstance,
  i: number,
  axisLabelFontSize: number = DEFAULT_AXIS_LABEL_FONT_SIZE,
  fontFamily: string = THEME_FONT_FAMILY
): number => {
  try {
    axisLabelFontSize = axisLabelFontSize ?? DEFAULT_AXIS_LABEL_FONT_SIZE;
    let extent = null;
    if (
      chartInstance &&
      chartInstance._componentsMap["_ec_\u0000series\u00000\u00000_grid"]
    ) {
      const axis =
        chartInstance._componentsMap["_ec_\u0000series\u00000\u00000_grid"].__model
          .coordinateSystem._axesMap?.y[i];

      let padding = 20;
      if (axis && axis.scale && axis.scale.type) {
        // both min and max are compared to find the max absolute value
        // as negative values are also considered
        if (axis.scale.type === "log") {
          const dataMax = axis.scale?.rawExtentInfo._dataMax;
          const dataMin = axis.scale?.rawExtentInfo._dataMin;
          const maxAbsoluteExtent =
            Math.abs(dataMax) > Math.abs(dataMin) ? dataMax : dataMin;
          const exponent = Math.ceil(Math.log10(maxAbsoluteExtent));
          extent = Math.pow(10, exponent);
        } else {
          const extent0 = axis.scale?._extent[0];
          const extent1 = axis.scale?._extent[1];
          const maxAbsoluteExtent =
            Math.abs(extent0) > Math.abs(extent1) ? extent0 : extent1;
          extent = maxAbsoluteExtent;
          if (axis.scale?._interval < 1) {
            padding = 30;
          }
        }
      }

      if (axis?.position === "right") {
        padding = 30;
      }

      if (extent) {
        const num = Number(extent);
        // Trim strings that are between 0.1 and 1 to ensure we don't use too many decimals.
        if (num && num < 1 && num > 0.1) {
          extent = extent?.toString().substring(0, 3);
        }
        // Trim strings that are between 0.1 and 1 to ensure we don't use too many decimals.
        if (num && num < 100 && num > 1) {
          extent = extent?.toString().substring(0, 3);
        }
      }

      // Preserve decimal place to accurately calculate nameGap
      const decimalPlaces = extent?.toString().split(".")[1]?.length ?? 0;
      return (
        calculateTextDimensions(
          extent?.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: decimalPlaces
          }),
          axisLabelFontSize,
          fontFamily
        ).width + padding
      );
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return 30;
};

export const getXAxisNameGap = (
  axisLabelFontSize: number = DEFAULT_AXIS_LABEL_FONT_SIZE,
  fontFamily: string = THEME_FONT_FAMILY
): number => {
  const padding = 8;
  return calculateTextDimensions("1", axisLabelFontSize, fontFamily).height + padding;
};

export const getLogButtonPosition = (
  position,
  leftSpacing,
  rightSpacing,
  topSpacing,
  bottomSpacing,
  nameGap,
  offset
) => {
  switch (position) {
    case "left":
      return {
        left: offset > 0 ? 5 : leftSpacing - nameGap - 15,
        bottom: bottomSpacing
      };
    case "right":
      return {
        right: offset > 0 ? 5 : rightSpacing - nameGap - 15,
        bottom: bottomSpacing
      };
    case "top":
      return {
        top: Number(topSpacing) - offset - 30,
        right: rightSpacing + 16
      };
    case "bottom":
      return {
        bottom: Number(bottomSpacing) - offset - 45,
        right: rightSpacing + 16
      };
    default:
      return {};
  }
};

export const getGridSpacing = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartInstance: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any,
  xAxisNameGaps: number[] = [],
  yAxisNameGaps: number[] = [],
  axisFontSize: number = DEFAULT_AXIS_FONT_SIZE,
  titleFontSize: number = DEFAULT_TITLE_FONT_SIZE,
  legendFontSize: number = DEFAULT_LEGEND_FONT_SIZE,
  fontFamily: string = THEME_FONT_FAMILY,
  isScreenShot: boolean = false
): { top: number; bottom: number; left: number; right: number } => {
  const grid = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  };
  const titleDimensions = calculateTextDimensions("TITLE", titleFontSize, fontFamily);
  const axisTitleDimensions = calculateTextDimensions("AXIS", axisFontSize, fontFamily);

  const xAxisList = Array.isArray(options.xAxis) ? options.xAxis : [options.xAxis ?? {}];
  xAxisList.forEach((axis, i) => {
    if (axis.position === "top") {
      grid.top += xAxisNameGaps[i] + axisTitleDimensions.height;
    } else {
      grid.bottom += xAxisNameGaps[i] + axisTitleDimensions.height;
    }
  });

  const yAxisList = Array.isArray(options.yAxis) ? options.yAxis : [options.yAxis ?? {}];
  yAxisList.forEach((axis, i) => {
    if (axis.position === "right") {
      grid.right += yAxisNameGaps[i] + axisTitleDimensions.height;
    } else {
      grid.left += yAxisNameGaps[i] + axisTitleDimensions.height;
    }
  });

  // Get the space used for the legend.
  const legendOffset = options.legend?.show
    ? calculateTextDimensions("legend", legendFontSize, fontFamily).height
    : 0;

  const titlePadding = options.title?.padding
    ? options.title?.padding[0] + options.title?.padding[2]
    : 0;
  grid.top = (grid.top > 0 ? grid.top : 0) + titleDimensions.height + titlePadding;
  grid.bottom = (grid.bottom > 0 ? grid.bottom : 20) + legendOffset;
  grid.left = grid.left > 0 ? grid.left : 20;
  grid.right = grid.right > 0 ? grid.right : 20;
  grid.right = isScreenShot && yAxisList.length === 1 ? grid.right + 40 : grid.right;

  return grid;
};
