import { useUser } from "hooks";

import { getProjectPermissions } from "../utils";
import { useSelectedProject } from "./useSelectedProject";

export function useSelectedProjectPermissions() {
  const { isAtLeastPowerUser, isAtLeastAdmin, isReadonly, user } = useUser();

  const { selectedProject } = useSelectedProject();

  const permissions = getProjectPermissions(
    selectedProject,
    user,
    isAtLeastPowerUser,
    isAtLeastAdmin,
    isReadonly
  );

  return permissions;
}
