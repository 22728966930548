import { Icon } from "@mdi/react";

import { mdiClose } from "@mdi/js";
import { Button } from "antd";

import { Tooltip } from "components/base";

import { useCancelForecastFolderList } from "./hooks/useCancelForecastFolderList";

export default function CancelForecastFoldersButton() {
  const cancelForecastFolderList = useCancelForecastFolderList();

  return (
    <Tooltip title="Cancel Folders">
      <Button
        className={"small-remove-button"}
        type="text"
        onClick={cancelForecastFolderList}
        icon={<Icon path={mdiClose} size={1} />}
      />
    </Tooltip>
  );
}
