import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";
import { getPropertiesWithForecast } from "utils/data-table";

import { fetchData } from "api/data";

import { EntityKind } from "../../../models/entityKind";
import { DataSourceSetting } from "../DataTableActions";
import { updateWells, useTable } from "../context";

const message =
  "An error occurred connecting to backend services. Please try again at a later time.";

/**
 * Simple `useQuery` abstraction to fetch data from endpoint.
 */
const useDataQuery = (
  selectedProperties: string[],
  dataSources: DataSourceSetting,
  widgetKey: string
) => {
  // context
  const [{ page, pageSize, sortColumn, sortDirection, entityKind }, dispatch] =
    useTable();

  // state from store
  const filterId = useSelector((state: RootState) =>
    entityKind == EntityKind.Well ? state.filter.filterId : state.facilityFilter.filterId
  );

  // state
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const queryFn = () => {
    const properties = getPropertiesWithForecast(selectedProperties, dataSources);
    const params = {
      pdenSource: dataSources.pdenSource,
      pageSize: pageSize,
      pageIndex: page - 1,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      entityKind
    };
    return fetchData(filterId.id, properties, params);
  };

  const successHandler = (data) => {
    setErrorMessage(null);
    updateWells(dispatch, data);
  };

  const errorHandler = (error) => {
    if (error instanceof Error) {
      setErrorMessage(error.message);
      return;
    }
    setErrorMessage(message);
  };

  const queryInfo = useQuery({
    queryKey: [
      widgetKey,
      filterId,
      page,
      pageSize,
      sortColumn,
      sortDirection,
      ...Object.values(dataSources)
    ],
    queryFn: queryFn,
    onError: errorHandler,
    onSuccess: successHandler,
    refetchOnWindowFocus: false,
    enabled: selectedProperties?.length > 0
  });

  return { isFetching: queryInfo.isFetching, refetch: queryInfo.refetch, errorMessage };
};

export default useDataQuery;
