import { useContext, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Tabs } from "antd";
import styled from "styled-components";

import {
  getGeomBinGroupOrder,
  getGroupOrder,
  updateGeomBinGroupOrder,
  updateGroupOrder
} from "api/data";

import { PresetDropdownContext } from "../filter/focus/PresetDropdown";
import FieldGroup from "./FieldGroup";
import { useGroupByState, useGroupByUpdater } from "./group-by.context";
import { groupFields } from "./helpers";
import { useColumns, useFieldsWithForecastLabel, useSelectedCategory } from "./hooks";

const FieldList = () => {
  const columns = useColumns();
  const labelledColumns = useFieldsWithForecastLabel(columns);
  const stateDispatch = useGroupByUpdater();
  const shortcutsEnabled = useContext(PresetDropdownContext);
  const groupedColumns = groupFields(labelledColumns || columns);
  const { isOrgDefinedColumn, isUserDefinedColumn, isOrgGeoDefinedColumn } =
    useSelectedCategory();

  const { customFieldsEditToggle, selectedTab, isFiltering } = useGroupByState();
  const [fieldVisible, setFieldVisible] = useState(true);
  const [fieldGroups, setFieldGroups] = useState([]);
  const [binGroups, setBinGroups] = useState([]);
  const [draggingId, setDraggingId] = useState(null);
  const isCustomColumn = useMemo(() => {
    return isOrgDefinedColumn || isUserDefinedColumn;
  }, [isOrgDefinedColumn, isUserDefinedColumn]);
  useEffect(() => {
    let isCurrent = true;
    setFieldVisible(true);
    setFieldGroups([]);
    if (Object.keys(groupedColumns).length === 0) {
      return;
    }
    const groupedBins = Object.keys(groupedColumns).reduce((acc, key) => {
      const list = groupedColumns[key];
      const geomFields = list
        .filter((l) => l.fieldType === "geom")
        .sort((a, b) => a.withinSubgroupOrder - b.withinSubgroupOrder);
      if (geomFields.length > 0) {
        acc.push({ key, geomFields });
      }
      return acc;
    }, []);

    const groupedFields = Object.keys(groupedColumns).reduce((acc, key) => {
      const list = groupedColumns[key];
      const fields = list
        .filter((l) => l.fieldType !== "geom")
        .sort((a, b) => a.withinSubgroupOrder - b.withinSubgroupOrder);
      if (fields.length > 0) {
        acc.push({ key, fields });
      }
      return acc;
    }, []);

    const setOrder = async () => {
      if (isCustomColumn) {
        try {
          const result = await getGroupOrder(isOrgDefinedColumn);
          const orderMap = new Map();
          result.forEach((item) => orderMap.set(item.name, item.order));
          groupedFields.sort((a, b) => orderMap.get(a.key) - orderMap.get(b.key));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("error sorting");
        }

        try {
          const groupOrderResult = await getGeomBinGroupOrder(isOrgDefinedColumn);
          const geomOrderMap = new Map();
          groupOrderResult.forEach((item) => geomOrderMap.set(item.name, item.order));
          groupedBins.sort((a, b) => geomOrderMap.get(a.key) - geomOrderMap.get(b.key));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("error sorting");
        }
      }

      if (isCurrent) {
        setFieldGroups(groupedFields);
        setBinGroups(groupedBins);
      }
    };
    setOrder();
    return () => {
      isCurrent = false;
    };
  }, [labelledColumns]);
  const onDragEnd = (result) => {
    setDraggingId(null);
    if (!result.destination) {
      return;
    }
    const selectedGroup = fieldVisible ? fieldGroups : binGroups;
    const reorderedItems = JSON.parse(JSON.stringify(selectedGroup));
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    reorderedItems.forEach((item, index) => {
      item.withinSubgroupOrder = index;
    });

    const updatedItems = reorderedItems.map((item, index) => ({
      id: item.key,
      order: index
    }));
    if (fieldVisible) {
      updateGroupOrder(updatedItems, isOrgDefinedColumn);
      setFieldGroups(reorderedItems);
    } else {
      updateGeomBinGroupOrder(updatedItems, isOrgDefinedColumn);
      setBinGroups(reorderedItems);
    }
  };

  const getDragItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    height: isDragging ? "26px" : "auto",
    ...draggableStyle
  });

  const handleTabChanged = (tabKey) => {
    setFieldVisible(tabKey === "1");
    stateDispatch({
      type: "selected_tab",
      payload: tabKey === "1" ? "fields" : "bins"
    });
    stateDispatch({
      type: "checked_fields",
      payload: []
    });
  };

  useEffect(() => {
    if (isCustomColumn) {
      setFieldVisible(true);
    }
  }, [isCustomColumn]);

  const onBeforeCapture = (beforeCapture) => {
    setDraggingId(beforeCapture.draggableId);
  };

  return (
    <>
      {isCustomColumn && !isFiltering && (
        <TabWrapper key={isOrgDefinedColumn ? "1" : "2"}>
          <Tabs
            activeKey={selectedTab == "fields" ? "1" : "2"}
            items={[
              {
                key: "1",
                label: "Fields",
                children: <></>
              },
              {
                key: "2",
                label: "Bins",
                children: <></>
              }
            ]}
            onChange={(item) => handleTabChanged(item)}
          />
        </TabWrapper>
      )}

      {isCustomColumn && (
        <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
          <Droppable droppableId="groups" direction="vertical" type="group">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {selectedTab == "fields" || isFiltering
                  ? fieldGroups.map((group, index) => (
                      <Draggable key={group.key} draggableId={group.key} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getDragItemStyle(
                              snapshot.isDragging || group.key === draggingId,
                              provided.draggableProps.style
                            )}>
                            <FieldGroup
                              dragHandleProps={
                                customFieldsEditToggle ? provided.dragHandleProps : null
                              }
                              key={group.key}
                              groupName={group.key}
                              fields={group.fields}
                              groups={fieldGroups}
                              isDragging={snapshot.isDragging}
                              shortcutsEnabled={shortcutsEnabled}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))
                  : binGroups.map((group, index) => (
                      <Draggable key={group.key} draggableId={group.key} index={index}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <FieldGroup
                              dragHandleProps={
                                customFieldsEditToggle ? provided.dragHandleProps : null
                              }
                              key={group.key}
                              groupName={group.key}
                              fields={group.geomFields}
                              groups={fieldGroups}
                              isDragging={snapshot.isDragging}
                              shortcutsEnabled={shortcutsEnabled}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {!isCustomColumn &&
        !isOrgGeoDefinedColumn &&
        fieldGroups.map((group) => (
          <FieldGroup
            key={group.key}
            groupName={group.key}
            fields={group.fields}
            groups={fieldGroups}
            dragHandleProps={null}
            isDragging={false}
            shortcutsEnabled={shortcutsEnabled}
          />
        ))}
    </>
  );
};

export default FieldList;

const TabWrapper = styled.div`
  padding: 0 var(--space-4);
  // gap: 5px;
`;
