import { useEffect, useState } from "react";

import { Popover } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";

import { IGroupBy, IGroupByMap } from "models";
import { EntityKind } from "models/entityKind";
import { PdenSourceEnum } from "models/pdenDataSourceSetting";

import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";

import GroupByWrapper from "./GroupByWrapper";
import { GroupByProvider } from "./group-by.context";

export type GroupByValueT = IGroupBy | IGroupByMap;

export type GroupByT = {
  children: React.ReactNode;
  dataTypeFilters?: string[];
  excludedCategoryFilters?: string[];
  editable?: boolean;
  placement?: TooltipPlacement;
  value: GroupByValueT;
  chartType?: string;
  axisType?: string;
  entityKind?: EntityKind;
  isDisabled?: boolean;
  onChange: (
    value: IGroupBy,
    isForecastToggleOn: boolean,
    selectedForecastFolder: string,
    selectedForecastFolderName: string,
    selectedPdenSource: PdenSourceEnum
  ) => void;
};

export default function GroupBy({
  children,
  dataTypeFilters = [],
  excludedCategoryFilters = [],
  editable = true,
  placement = "right",
  value = null,
  chartType = null,
  axisType = null,
  entityKind = EntityKind.Well,
  isDisabled = false,
  onChange
}: GroupByT) {
  const [visible, setVisible] = useState(false);
  const { isActive: isGeomBinOpen } = useGeomBinContext();

  const handleChange = (
    newValue,
    isForecastToggleOn,
    selectedForecastFolder,
    selectedForecastFolderName,
    selectedPdenSource
  ) => {
    onChange(
      newValue,
      isForecastToggleOn,
      selectedForecastFolder,
      selectedForecastFolderName,
      selectedPdenSource
    );
    setVisible(false);
  };

  const handleVisibleChange = (val: boolean) => {
    if (!isDisabled) {
      setVisible(val);
    }
  };

  useEffect(() => {
    if (visible && isGeomBinOpen) {
      setVisible(false);
    }
  }, [isGeomBinOpen]);

  const content = (
    <GroupByProvider entityKind={entityKind}>
      <GroupByWrapper
        value={value}
        dataTypeFilters={dataTypeFilters}
        excludedCategoryFilters={excludedCategoryFilters}
        onChange={handleChange}
        editable={editable}
        chartType={chartType}
        axisType={axisType}
        entityKind={entityKind}
      />
    </GroupByProvider>
  );

  return (
    <Popover
      content={content}
      placement={placement}
      trigger="click"
      open={!isDisabled && visible}
      onOpenChange={handleVisibleChange}
      overlayClassName="group-by-popover">
      {children}
    </Popover>
  );
}
