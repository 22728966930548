import { ReactNode, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";

import { Subscription } from "rxjs";

import { useUser } from "hooks";
import useBetaFeatures from "hooks/useBetaFeatures";

import { useWorkspaceContext } from "components/workspace/hooks/useWorkspaceContext";
import { useWorkspaceDispatch } from "components/workspace/hooks/useWorkspaceDispatch";

import useDashboard from "./hooks/useDashboard";
import { useDashboardContext } from "./hooks/useDashboardContext";
import { useDashboardDispatch } from "./hooks/useDashboardDispatch";
import { getDashboardCapabilities, getDashboardPermissions } from "./utils";

let dashboardSub: Subscription = null;

export interface DashboardWrapperModel {
  children: ReactNode;
  isPopoutDashboard?: boolean;
}

export default function DashboardWrapper({
  children,
  isPopoutDashboard = false
}: DashboardWrapperModel): JSX.Element {
  const workspaceDispatch = useWorkspaceDispatch();
  const dashboardDispatch = useDashboardDispatch();

  const [refreshTxn, setRefreshTxn] = useState<string>(null);

  const { workspace, activeDashboardId } = useWorkspaceContext();
  const { dashboard, dashboardRefreshObserver, isModified, dashboardMode, capabilities } =
    useDashboardContext();
  const { user, isAtLeastPowerUser } = useUser();
  const { hasFeature } = useBetaFeatures();

  const {
    data,
    loading,
    error,
    refetch: refetchDashboard
  } = useDashboard(activeDashboardId);

  // Refetch the dashboard when there is a refresh request.
  useEffect(() => {
    if (activeDashboardId === undefined || activeDashboardId !== dashboard?.dashboardId)
      return;

    refetchDashboard();

    // Mark the dashboard as unmodified
    dashboardDispatch({
      payload: {
        dashboard: data?.dashboard,
        isModified: false,
        hasModifiedWidgets: false
      }
    });

    // don't include refetchDashboard in the dependencies array
    // adding it will cause dashboard refresh prematurely when token expires
  }, [refreshTxn]);

  // Update the loading state when the apollo query loading value changes
  useEffect(() => {
    dashboardDispatch({
      payload: {
        isLoading: loading
      }
    });
  }, [loading, dashboardDispatch]);

  // Update the error state when the apollo query error value changes
  useEffect(() => {
    dashboardDispatch({
      payload: {
        error: error
      }
    });
  }, [error, dashboardDispatch]);

  // Update the dashboard when the apollo query successfully finishes
  useEffect(() => {
    if (loading || !data?.dashboard) return;
    if (JSON.stringify(dashboard) === JSON.stringify(data?.dashboard)) return;
    dashboardDispatch({
      payload: {
        isModified: false,
        hasModifiedWidgets: false,
        dashboard: data?.dashboard,
        dashboardMode: "explore"
      }
    });
  }, [data, loading, dashboardDispatch]);

  // Update the dashboard information when the dashboard state changes.
  useEffect(() => {
    if (dashboard) {
      const capabilities = getDashboardCapabilities(dashboard, hasFeature);
      const permissions = getDashboardPermissions(dashboard, user, isAtLeastPowerUser);

      // Update the dashboards capabilities.
      dashboardDispatch({
        payload: {
          capabilities,
          permissions
        }
      });

      // skip caching current dashboard if it doesn't have a map.
      // This can be removed once we address initial map polygon filtering
      if (capabilities && capabilities.hasMapWidget && !isPopoutDashboard) {
        // store the current dashboard in the session.
        sessionStorage.setItem(`dashboardid`, dashboard.dashboardId);
      }
    }
  }, [dashboard, user, isAtLeastPowerUser, dashboardDispatch]);

  // Set the initially selected dashboard when the workspace changes.
  useEffect(() => {
    if (!workspace) return;

    if (!workspace.dashboards?.find((d) => d.dashboardId === activeDashboardId)) {
      // Use the first dashboard in the list.
      workspaceDispatch({
        payload: {
          activeDashboardId: workspace?.dashboards[0].dashboardId
        }
      });
    }
  }, [workspace]);

  // Set whether the map should be locked or not by the dashboard.
  useEffect(() => {
    if (!capabilities) return;

    dashboardDispatch({
      payload: {
        lockMap: !capabilities.hasMapWidget || dashboardMode === "edit"
      }
    });
  }, [dashboardMode, capabilities, dashboardDispatch]);

  // Component mounting
  useEffect(() => {
    if (!isPopoutDashboard) {
      const sessionDashboardId = sessionStorage.getItem(`dashboardid`);
      if (sessionDashboardId) {
        // Select the last used dashboard used.
        workspaceDispatch({
          payload: {
            activeDashboardId: sessionDashboardId
          }
        });
      }
    }

    // Monitor dashboard refresh requests.
    if (dashboardRefreshObserver && !dashboardSub) {
      dashboardSub = dashboardRefreshObserver.subscribe((token: string) => {
        setRefreshTxn(token);
      });
    }
    return () => {
      if (dashboardSub) {
        dashboardSub.unsubscribe();
        dashboardSub = null;
      }
    };
  }, []);

  return (
    <>
      <Prompt
        when={isModified}
        message={"Are you sure you want to go to leave without saving your changes?"}
      />
      {children}
    </>
  );
}
