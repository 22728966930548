/**
 * Extract the unit from the Y-axis title.
 *
 * @param {string} title The Y-axis title.
 * @returns {string | null} The extracted unit or null if no match is found.
 */
export const getUnitFromTitle = (title: string): string | null => {
  if (typeof title !== "string") {
    return null;
  }
  const match = title.match(/\(([^)]+)\)/);
  if (match) {
    const unit = match[1].split("/")[0].trim(); // Split by '/' and take the first part
    return unit;
  }
  return null;
};
