import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { Button, InputNumber } from "antd";
import { FORECAST_KEY } from "constants/settings.constants";
import _debounce from "lodash/debounce";
import { setSelectedTypeWell } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import getColumnLabelWithContext from "utils/column/getColumnLabelWithContext";

import useColumns from "hooks/useColumns";

import { WellData } from "models/UserArpsModel";

import GroupBy from "components/groupBy";
import { useSelectedProjectPermissions } from "components/project/projects/hooks";

import { EntityKind } from "../../../../../models/entityKind";

export interface TypeWellNormalizationParameterProps {
  index: number;
  validateTypeWellData: (wellData: WellData) => boolean;
  onChange?: (value?: WellData) => void;
}

export function TypeWellNormalizationParameter({
  index,
  validateTypeWellData,
  onChange
}: TypeWellNormalizationParameterProps) {
  const projectPermissions = useSelectedProjectPermissions();
  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);
  const wellData = selectedTypeWell?.wellData;
  const parameterData = wellData?.AdditionalData[index];
  const dispatch = useDispatch();

  const changeDebounceRef = useRef(
    _debounce((fn) => {
      fn && fn();
    }, 600)
  );

  const columns = useColumns();

  let property = wellData.AdditionalData[index].field;
  if (property?.endsWith(FORECAST_KEY)) {
    property = property.replace(`.${FORECAST_KEY}`, "");
  }

  let normalizationField = columns.find((c) => property === c.property);

  let fieldTitle = "Select Field";
  if (normalizationField) {
    const normalizationFieldWithContext = getColumnLabelWithContext(
      normalizationField,
      false
    );

    fieldTitle = normalizationFieldWithContext?.fullContextTitle;
  } else {
    normalizationField = columns.find((c) => c.property === "Frac_Data.Stages");
  }

  const changeAdditionalNormalizationField = (field, value) => {
    const newAdditionalData = [...wellData.AdditionalData];
    newAdditionalData[index] = { field, value };
    const updatedWellData: WellData = {
      ...wellData,
      AdditionalData: newAdditionalData
    };
    dispatch(
      setSelectedTypeWell({
        ...selectedTypeWell,
        wellData: {
          ...updatedWellData
        }
      })
    );
    if (validateTypeWellData(updatedWellData)) {
      changeDebounceRef.current(() => onChange && onChange(updatedWellData));
    }
  };

  const removeAdditionalNormalizationField = () => {
    // eslint-disable-next-line prefer-const
    let newAdditionalData = [...wellData.AdditionalData];
    newAdditionalData.splice(index, 1);
    const updatedWellData: WellData = {
      ...wellData,
      AdditionalData: newAdditionalData
    };
    dispatch(
      setSelectedTypeWell({
        ...selectedTypeWell,
        wellData: {
          ...updatedWellData
        }
      })
    );
    if (validateTypeWellData(updatedWellData)) {
      changeDebounceRef.current(() => onChange && onChange(updatedWellData));
    }
  };

  const canEditParams = projectPermissions.canEditTypeWells;
  return (
    <>
      <GroupBy
        value={normalizationField}
        dataTypeFilters={["Number", "Integer"]}
        entityKind={EntityKind.Well}
        placement="rightTop"
        isDisabled={!canEditParams}
        onChange={(gb) =>
          changeAdditionalNormalizationField(gb.property, parameterData.value)
        }>
        <StyledButton block disabled={!canEditParams}>
          {fieldTitle}
          <KeyboardArrowRightIcon style={{ fontSize: 24 }} />
        </StyledButton>
      </GroupBy>
      <StyledNumberInput
        value={parameterData.value}
        disabled={!canEditParams}
        controls={false}
        onChange={(value) =>
          changeAdditionalNormalizationField(parameterData.field, value)
        }
      />
      <RemoveButtonWrapper
        disabled={!canEditParams}
        onClick={removeAdditionalNormalizationField}>
        <RemoveCircleOutlineIcon style={{ fontSize: 20 }} />
      </RemoveButtonWrapper>
    </>
  );
}

const StyledButton = styled(Button)`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  height: 30px;
  text-align: left;

  & > span {
    max-width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledNumberInput = styled(InputNumber)`
  width: 100%;
  height: 30px;
  background-color: #ebf8ef;
`;

const RemoveButtonWrapper = styled(Button)`
  --ant-primary-color: #a2aaad;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  margin-right: 5px;
  cursor: pointer;
  color: var(--color-accent);
  background: none;

  &:hover {
    color: var(--color-primary);
  }

  &:disabled {
    background: none;
    &:hover {
      background: none;
    }
  }

  .icon-toggle {
    color: rgba(194, 194, 194, 0.8);
    opacity: 0;
  }
`;
