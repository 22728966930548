import { ForecastMonth } from "arps_wasm";
import { ICheckedForecast } from "store/features";

export class UserArpsFile {
  file: string;
  source: string;
  fileType: string;
  fileUpload: File;
}

export interface WellData {
  HzLength: number;
  Proppant: number;
  ProppantIntensity: number;
  Stage: number;
  StageSpacing: number;
  AdditionalData?: AdditionalTypeWellData[];
}

export interface AdditionalTypeWellData {
  field: string;
  value: number;
}

export interface ArpSegment {
  product: string;
  segmentIndex: number;
  startDate: string;
  endDate: string;
  qi: number;
  di: number;
  b: number;
  n?: number;
  qf: number;
  trendCum: number;
  df: number;
  slopeType?: number;
  switchMonth?: number;
  maxRate?: number;
  timeCalculated?: boolean;
}

export interface ForecastConstant {
  product: string;
  uniqueId: string;
  value: number;
  unit?: string;
}

export interface WellListRecord {
  changedBy: string;
  date: string;
  changeId: string;
  wellList: string[];
}

export interface WellListRecordCollection {
  wellListRecords: WellListRecord[];
}

export interface ForecastItem {
  uniqueID: string;
  name: string;
  wellData: WellData;
  arps: ArpSegment[];
  constants: ForecastConstant[];
  id: string;
  reserveCategory: string;
  source?: string;
  wellListRecords: WellListRecord[];
  order?: number;
}

export class UserArpsItem implements ICheckedForecast {
  folderId: string;
  folderName: string;
  reserveCategory: string;
  title: string;
  uniqueID: string;
  isChecked: boolean;
  id: string;
  isFolder: boolean;
  key: string;
  arps: ArpSegment[];
  children: ICheckedForecast[];
  constants: ForecastConstant[];
  forecastArray: ForecastMonth[];
  wellData: WellData;
  color: string;
  thickness: number;
  isEdit?: boolean;
  editLocation?: "Widget" | "Activity";
  source?: string;
  order?: number;

  constructor(folderId: string, folderName: string, fi: ForecastItem) {
    this.folderId = folderId;
    this.folderName = folderName;
    this.reserveCategory = fi.reserveCategory;
    this.title = fi.name;
    this.source = fi.source;
    this.uniqueID = fi.uniqueID;
    this.id = fi.id;
    this.arps = fi.arps;
    this.constants = fi.constants;
    this.key = fi.id;
    this.isChecked = false;
    this.children = [];
    this.isEdit = false;
    this.editLocation = "Activity";
    this.order = fi.order;
    const twSettingStr = localStorage?.getItem("tw-" + fi.id) as string;
    const twSetting =
      twSettingStr?.length > 0
        ? (JSON.parse(twSettingStr) as ICheckedForecast)
        : undefined;
    this.color = twSetting?.color ?? "#000";
    this.thickness = twSetting?.thickness ?? 3.0;
    if (fi.wellData && Object.keys(fi.wellData).length !== 0) {
      this.wellData = fi.wellData;
    }
  }

  type: "forecast" | "folder";
}

export interface ForecastFolderItem {
  folderId: string;
  reserveCategory?: string;
  name: string;
  folderName: string;
  projectId: string;
  forecasts: ForecastItem[];
  parentId: string;
  order?: number;
  temporaryId?: string;
}

export class ForecastFolder implements ICheckedForecast {
  isChecked: boolean;
  isFolder: boolean;
  title: string;
  children: UserArpsItem[];
  key: string;
  isEdit: boolean;
  editLocation?: "Widget" | "Activity";
  name: string;
  folderName: string;
  id: string;
  folderId: string;
  projectId: string;
  parentId: string;
  reserveCategory?: string;
  order?: number;
  temporaryId?: string;

  constructor(folder: ForecastFolderItem) {
    this.id = folder.reserveCategory
      ? `${folder.folderId}_${folder.reserveCategory}`
      : folder.folderId;
    this.folderId = folder.folderId;
    this.reserveCategory = folder.reserveCategory;
    this.title = folder.name;
    this.name = folder.name;
    this.folderName = folder.folderName ?? folder.name;
    this.projectId = folder.projectId;
    this.isFolder = true;
    this.isEdit = false;
    this.editLocation = "Activity";
    this.children = folder.forecasts
      ? folder.forecasts.map((f) => new UserArpsItem(this.folderId, this.name, f))
      : [];
    this.parentId = folder.parentId;
    this.order = folder.order;
    this.key = this.id;
    this.temporaryId = folder.temporaryId;
  }

  type: "forecast" | "folder";
  color?: string;
  thickness?: number;
  wellData?: WellData;
  uniqueID: string;
  arps: ArpSegment[];
  constants: ForecastConstant[];
}

interface TypeWellLineStyle {
  opacity: number;
  type: string;
  width: number;
}

interface TypeWellLine {
  originalColor: string;
  originalWidth: number;
}

interface TypeWellItemStyle {
  opacity: number;
  color: string;
}

export interface TypeWellSeries {
  name: string;
  id: string;
  type: string;
  kind: string;
  itemStyle: TypeWellItemStyle;
  showSymbol: boolean;
  lineStyle: TypeWellLineStyle;
  line: TypeWellLine;
  data: Array<[number, number]>;
}
