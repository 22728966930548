import React, { useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

import { updateUser } from "store/features/auth/authSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import {
  requestPhoneNumberCode,
  resendPhoneNumberCode,
  updateMemberPassword,
  verifyPhoneNumberCode
} from "api/users";

import { BaseButton, BaseDivider, BaseInput, Heading } from "components/base";
import PasswordInput from "components/base/PasswordInput";

import "./AccountSecurity.scss";
import AuthenticationAppSetup from "./components/security/AuthenticationAppSetup";

export default function AccountSecurity() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const regexLength = new RegExp(".{12}");
  const regexUppercase = new RegExp("[A-Z]");
  const regexNumber = new RegExp("[0-9]");

  const [changingPassword, setChangingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordFormToggled, setPasswordFormToggled] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasMetPasswordRequirements, setHasMetPasswordRequirements] = useState(false);
  const submitDisabled = !currentPassword.length || !hasMetPasswordRequirements;
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [editPhoneNumber, setEditPhoneNumber] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    setHasSubmitted(true);
    if (!hasMetPasswordRequirements) {
      return;
    }
    setChangingPassword(true);
    setHasMetPasswordRequirements(false);
    setPasswordRequirements(null);
    await changePassword();
  }

  function handleCancelPhoneNumber() {
    sendVerificationCode.reset();
    setEditPhoneNumber(false);
    setVerificationCode("");
    setPhoneNumber(user.phoneNumber ?? "");
  }

  function handlePhoneNumberChange(number) {
    setPhoneNumber(number.replace(/[^\d]+/g, ""));
    sendVerificationCode.reset();
  }

  const sendVerificationCode = useMutation(
    async () => await requestPhoneNumberCode(phoneNumber),
    {
      onError: (err) => {
        toast.error(err);
      }
    }
  );

  const resendVerificationCode = useMutation(
    async () =>
      await resendPhoneNumberCode({
        phoneNumber: phoneNumber,
        code: "",
        requestId: sendVerificationCode.data.data.requestId
      }),
    {
      onError: (err) => {
        toast.error(err);
      }
    }
  );

  const submitVerificationCode = useMutation(
    async () =>
      await verifyPhoneNumberCode({
        phoneNumber: phoneNumber,
        code: verificationCode,
        requestId:
          resendVerificationCode.data?.data?.requestId ??
          sendVerificationCode.data.data.requestId
      }),
    {
      onSuccess: () => {
        dispatch(updateUser({ ...user, phoneNumber: phoneNumber }));
        setEditPhoneNumber(false);
        setVerificationCode("");
        sendVerificationCode.reset();
        toast.success("Successfully updated phone number");
      },
      onError: (err) => {
        toast.error(err);
      }
    }
  );

  async function changePassword() {
    const response = await updateMemberPassword({
      email: user.email,
      newPassword,
      oldPassword: currentPassword
    });
    if (response) {
      toast.success("Successfully updated password.");
      setCurrentPassword("");
      setNewPassword("");
    } else {
      toast.error("Error updating password.");
    }
    setChangingPassword(false);
    setHasSubmitted(false);
  }

  function validatePassword(value: string) {
    const requirements = [
      {
        text: "Password length must be at least <strong>12 characters</strong>.",
        met: regexLength.test(value)
      },
      {
        text: "Password must contain at least <strong>one uppercase character</strong>.",
        met: regexUppercase.test(value)
      },
      {
        text: "Password must contain at least <strong>one number</strong>.",
        met: regexNumber.test(value)
      }
    ];

    setHasMetPasswordRequirements(requirements.every((req) => req.met));

    const requirementsHtml = requirements
      .map(
        (req) =>
          `<div style="color: ${
            req.met ? "var(--color-primary)" : "var(--color-text)"
          };">${req.text}</div>`
      )
      .join("");

    setPasswordRequirements(requirementsHtml || null);
  }

  return (
    <div className="userAccount">
      <div className="userAccountHeader">
        <Heading element="h4">Account & Security</Heading>
      </div>

      <div className="userAccountContent">
        <div className="main">
          <div className="AccountSecurity">
            <div className="company-info flex-row">
              <div className="company-logo flex flex-centered">
                <span>Logo</span>
              </div>
              <div className="flex-column">
                <span className="company-name-label">Your organization</span>
                <div className="company-name">
                  {user.organization ? user.organization.name : ""}
                </div>
              </div>
            </div>
            <div className="user-email">
              <BaseInput type="email" label="Email address" value={user.email} disabled />
            </div>
            <BaseDivider />
            <PhoneNumberSection>
              <SectionHeading>
                <div className="section-heading">Phone Number</div>
                {!editPhoneNumber && (
                  <BaseButton
                    appearance="subtle"
                    onClick={() => setEditPhoneNumber(true)}
                    data-testid="change-phone-number-button">
                    Change Phone Number
                  </BaseButton>
                )}
              </SectionHeading>
              <BaseInput
                value={phoneNumber}
                onChange={(value) => {
                  handlePhoneNumberChange(value);
                }}
                disabled={!editPhoneNumber}
                data-testid="phone-number-input-field"></BaseInput>
              {editPhoneNumber && (
                <div>
                  {sendVerificationCode.isSuccess && (
                    <PhoneNumberVerificationField>
                      <BaseInput
                        label="Verification Code"
                        value={verificationCode}
                        placeholder="Enter the code sent to your phone"
                        onChange={(value) => setVerificationCode(value)}
                        data-testid="verification-code-input-field"></BaseInput>
                    </PhoneNumberVerificationField>
                  )}
                  <PhoneNumberActions>
                    {sendVerificationCode.isSuccess ? (
                      <BaseButton
                        appearance="subtle"
                        onClick={() => resendVerificationCode.mutate()}
                        data-testid="resend-button">
                        Resend
                      </BaseButton>
                    ) : (
                      <VerifyPhoneNumberButton
                        appearance="subtle"
                        onClick={() => sendVerificationCode.mutate()}
                        isDisabled={phoneNumber === user.phoneNumber}
                        data-testid="verify-button">
                        Verify
                      </VerifyPhoneNumberButton>
                    )}
                    <PhoneNumberActionButtons>
                      <BaseButton
                        onClick={() => handleCancelPhoneNumber()}
                        data-testid="cancel-button">
                        Cancel
                      </BaseButton>
                      <BaseButton
                        isDisabled={verificationCode.length !== 6}
                        onClick={() => submitVerificationCode.mutate()}
                        data-testid="save-button">
                        Save
                      </BaseButton>
                    </PhoneNumberActionButtons>
                  </PhoneNumberActions>
                </div>
              )}
            </PhoneNumberSection>
            {(user.organization.requireMfaForOrganization || user.requiresMfa) && (
              <AuthenticationAppSetup />
            )}
            <BaseDivider />
            <div className="section">
              <form onSubmit={handleSubmit}>
                <div className="user-password">
                  <div className="flex-row justify-between ">
                    <div className="section-heading">Password</div>
                    {!passwordFormToggled && (
                      <BaseButton
                        appearance="subtle"
                        onClick={() => {
                          setPasswordFormToggled(true);
                          validatePassword("");
                        }}>
                        Change Password
                      </BaseButton>
                    )}
                  </div>
                  {passwordFormToggled && (
                    <>
                      <PasswordInput
                        label="Current Password"
                        value={currentPassword}
                        onChange={(value) => {
                          setCurrentPassword(value);
                        }}
                      />
                      <PasswordInput
                        label="New Password"
                        value={newPassword}
                        onChange={(value) => {
                          setNewPassword(value);
                          validatePassword(value);
                        }}
                      />
                      <BaseButton
                        appearance="primary"
                        type="submit"
                        isLoading={changingPassword}
                        isDisabled={submitDisabled}>
                        Save Changes
                      </BaseButton>
                      <PasswordRequirements hasSubmitted={hasSubmitted}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: passwordRequirements
                          }}
                        />
                      </PasswordRequirements>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip effect="solid" delayShow={1000} place="top"></ReactTooltip>
    </div>
  );
}

const PhoneNumberSection = styled.div`
  padding: 33px 0;
`;

const SectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .base-button.subtle {
    font-size: 1.6rem;
    font-weight: var(--fontWeightMedium);
  }
`;

const PhoneNumberVerificationField = styled.div`
  padding-top: 10px;
  .label {
    font-size: 1.5rem !important;
    font-weight: normal !important;
  }
`;
const PhoneNumberActions = styled.div`
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PhoneNumberActionButtons = styled.div`
  .button {
    margin-left: 5px;
  }
`;

const VerifyPhoneNumberButton = styled(BaseButton)`
  &.base-button.subtle {
    &.isDisabled {
      color: var(--iconDisabledColor);
    }
    border: 0 !important;
    color: var(--color-primary);
    background: transparent !important;
  }
`;

const PasswordRequirements = styled.div`
  display: flex;
  align-items: center;
  min-height: 70px;
`;
