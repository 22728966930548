import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import axios from "axios";
import { RootState } from "store/rootReducer";

import { ProjectModel, projectServiceEndpoint } from "api/project";

export const useProjectListQueryKeys = {
  currentProjectList: "currentProjectList"
};

export type UseProjectListQueryKeyT = keyof typeof useProjectListQueryKeys;

export interface UseProjectListQueryParamsT {
  onSuccess?: (data) => void;
  onError?: (error) => void;
  select?;
  queryKey?: UseProjectListQueryKeyT;
  isAutoRefetching?: boolean;
}

export interface UseProjectListQueryReturnT {
  data: ProjectModel[];
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
}

export const fetchProjectList = async () => {
  try {
    return await axios.get(`${projectServiceEndpoint}/projects`);
  } catch (error) {
    throw new Error("Unable to fetch project list");
  }
};

export function useProjectListQuery(
  params: UseProjectListQueryParamsT = {}
): UseProjectListQueryReturnT {
  const { onError, onSuccess, isAutoRefetching = true } = params;
  const userId = useSelector((state: RootState) => state.auth?.user?.id);

  const queryInfo = useQuery({
    queryKey: [useProjectListQueryKeys.currentProjectList, userId],
    queryFn: fetchProjectList,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    enabled: isAutoRefetching
  });

  return {
    data: queryInfo.data?.data,
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch
  };
}
