import React, { ReactNode, createContext, useReducer } from "react";

// eslint-disable-next-line import/no-named-as-default
import produce from "immer";

import { ForecastContextState, ForecastUpdates } from "./ForecastContextState";

export const initialForecastContextState: ForecastContextState = {
  error: "",
  forecastFolderNameFieldMap: {},
  forecastNameField: "",
  isAddingFolderList: false,
  isFiltering: false,
  nameChangeError: undefined,
  nameChangeErrorId: undefined,
  temporaryForecastFolderList: []
};
export const ForecastContext = createContext(initialForecastContextState);

export interface Action<T, Type> {
  type?: Type;
  payload: T;
}
export const ForecastDispatchContext =
  createContext<React.Dispatch<Action<ForecastUpdates, "update">>>(undefined);

function ForecastReducer(
  state: ForecastContextState,
  action: Action<ForecastUpdates, "update">
) {
  return produce(state, (draft) => {
    Object.assign(draft, action.payload);
  });
}

export interface ForecastProviderModel {
  children: ReactNode;
  state?: ForecastContextState;
}

export function ForecastProvider({
  children,
  state: overrideState
}: ForecastProviderModel) {
  const [state, dispatch] = useReducer(
    ForecastReducer,
    overrideState ?? initialForecastContextState
  );

  return (
    <ForecastContext.Provider value={state}>
      <ForecastDispatchContext.Provider value={dispatch}>
        {children}
      </ForecastDispatchContext.Provider>
    </ForecastContext.Provider>
  );
}
