import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import {
  setGlobalGroupBy,
  setHasToConfirmSize,
  setOverrideSizeLimit
} from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { IGroupBy } from "models";
import { EntityKind } from "models/entityKind";

import ConfirmDialog from "components/chart/ConfirmDialog";
import { toPdenEnum } from "components/filter/GroupByFilterActivity";

const dataRoot = process.env.REACT_APP_DATA_ROOT;

function MapSeriesSizeConfirmation(): JSX.Element {
  const dispatch = useDispatch();
  const groupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);
  const useNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );
  const globalNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );
  const hasToConfirmSize = useSelector((state: RootState) => state.map.hasToConfirmSize);
  const filterId = useSelector((state: RootState) => state.filter.filterId);
  const canEditBin = !(
    groupBy?.dataType.toLowerCase() === "text" ||
    groupBy?.dataType.toLowerCase() === "date"
  );

  const getDefaultBinSize = async (fieldGb: IGroupBy, filterId: string) => {
    const API_URL = `${dataRoot}/api/v1/data/bin-size/${filterId}/${btoa(
      encodeURIComponent(fieldGb.property)
    )}?normalizeField=${useNormalizeBy ? globalNormalizeBy.field : "''"}&per=${
      useNormalizeBy ? globalNormalizeBy.per : 0
    }&pdenSource=${toPdenEnum(fieldGb.pdenSource)}&entityKind=${
      EntityKind.Well
    }&useDynamicBins=${fieldGb.bin?.UseDynamicBins}`;

    try {
      const response = await axios.get(API_URL);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Failed to fetch bin size: ${response.statusText}`);
      }
    } catch (error) {
      return null;
    }
  };

  const onConfirm = () => {
    dispatch(setHasToConfirmSize(false));
    dispatch(setOverrideSizeLimit(true));
  };
  const onCancel = async () => {
    dispatch(setHasToConfirmSize(false));
    dispatch(setOverrideSizeLimit(false));
    try {
      let newGroupBy;

      if (canEditBin) {
        const defaultBins = await getDefaultBinSize(groupBy, filterId.id);
        const newBin = {
          ...groupBy.bin,
          BinSize: defaultBins.binSize ?? "",
          LessThan: defaultBins.lessThan ?? "",
          GreaterThan: defaultBins.greaterThan ?? ""
        };

        newGroupBy = Object.assign({}, groupBy, { bin: newBin });
      } else {
        newGroupBy = Object.assign({}, groupBy, {
          bin: {
            ...groupBy.bin,
            MaxBins: 100
          }
        });
      }

      dispatch(setGlobalGroupBy(newGroupBy));
    } catch (error) {
      const newBin = {
        ...groupBy.bin,
        MaxBins: 100,
        BinSize: 100
      };

      const newGroupBy = Object.assign({}, groupBy, { bin: newBin });
      dispatch(setGlobalGroupBy(newGroupBy));
    }
  };
  return (
    <ConfirmIndicator className={`${!hasToConfirmSize ? "hidden" : ""}`}>
      <ConfirmDialog
        title="Large number of series"
        message="You are about to generate a large number of series which may slow down the browser."
        onConfirm={() => onConfirm()}
        onClose={() => onCancel()}
      />
    </ConfirmIndicator>
  );
}

export default MapSeriesSizeConfirmation;

const ConfirmIndicator = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(211, 211, 211, 0.424);
  z-index: 9999;

  &.hidden {
    display: none;
  }

  .MuiPaper-root {
    max-width: 320px;
  }

  .MuiCardContent-root {
    padding: 10px 36px 26px 36px;
  }

  .title {
    font-size: 2.2rem;
    font-weight: var(--fontWeightBold);
    color: var(--color-text);
  }

  .content {
    p {
      font-size: 1.4rem;
      font-weight: var(--fontWeightMedium);
      margin-bottom: 15px;
    }

    .button {
      height: 38px;
      width: 100px;
      border-radius: 4px;

      span {
        font-size: 1.6rem;
      }
    }

    .actions {
      gap: 10px;
    }
  }
`;
