import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { Button } from "antd";
import { COMPATIBLE_COLORMAP_NAMES } from "constants/settings.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { spaceOutColorsForDisplay } from "../util/spaceOutColorsForDisplay";

const ColorPaletteList = ({ selected, onSelect, useCustomPalettes = true }) => {
  const [colorSchemes, setColorSchemes] = useState([]);

  const palettes = useSelector((state: RootState) => state.userSetting.colorPalettes);

  const reverseColors = (names = null, event = null) => {
    const namesToReverse = names || colorSchemes.map((c) => c.name);
    const newSchemes = colorSchemes.map((c) => {
      const haveToReverse = namesToReverse.includes(c.name);
      return {
        ...c,
        reverse: haveToReverse ? !c.reverse : c.reverse
      };
    });
    setColorSchemes(newSchemes);
    event?.stopPropagation();
  };

  useEffect(() => {
    let filteredPalettes = palettes;
    if (!useCustomPalettes) {
      filteredPalettes = palettes.filter((palette) =>
        COMPATIBLE_COLORMAP_NAMES.includes(palette.name.toLowerCase())
      );
    }
    if (filteredPalettes) {
      const scheme = [
        //make copy of the colors
        ...filteredPalettes.map((c) => {
          const clone = { ...c };
          if (c.name === selected?.name && selected?.reverse) {
            clone.colors = [...c.colors.slice().reverse()];
            clone.reverse = selected?.reverse;
          }
          return clone;
        })
      ];
      setColorSchemes(scheme);
    }
  }, [palettes]);
  if (!selected) {
    return null;
  }

  return (
    <Wrapper>
      <StyledButton onClick={() => reverseColors()}>
        <SyncAltIcon /> Reverse all palettes
      </StyledButton>

      {colorSchemes.map((scheme) => {
        const { name, colors, reverse } = scheme;
        const displayedColors = reverse ? [...colors].reverse() : colors;
        const spacedOutColors = spaceOutColorsForDisplay(displayedColors);
        return (
          <Item
            data-testid="color-palette-item"
            className={`color-palette-item-${name}`}
            key={name}
            selected={name === selected.name}
            onClick={() => {
              onSelect(scheme);
            }}>
            <div className="color-scheme-descriptor">{name}</div>
            <Palette>
              {spacedOutColors.map((color, i) => (
                <Swatch key={i} color={color} />
              ))}

              <SyncButton
                type="link"
                icon={<SyncAltIcon fontSize="large" />}
                onClick={(event) => reverseColors([name], event)}
              />
            </Palette>
          </Item>
        );
      })}
    </Wrapper>
  );
};

export default ColorPaletteList;

const Wrapper = styled.div`
  display: grid;
  padding-bottom: var(--space-4);
  color: var(--color-text-60);
  max-height: 800px;
  overflow-y: auto;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: var(--space-1);
  margin: var(--space-4);
`;

const Item = styled.div`
  display: grid;
  color: ${(p) => (p.selected ? "var(--color-text)" : "inherit")};
  background-color: ${(p) => (p.selected ? "var(--color-text-06)" : "transparent")};
  font-weight: var(--fontWeightMedium);
  padding: var(--space-1) var(--space-4);
  user-select: none;
  cursor: pointer;

  &:hover {
    color: var(--color-text);
    background-color: var(--color-text-06);
  }
`;

const Palette = styled.div`
  display: flex;
  align-items: center;
`;

const Swatch = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${(p) => p.color};
`;

const SyncButton = styled(Button)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: currentColor;
  margin-left: var(--space-2);
`;
