import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { Button, Divider, Form, Input, Popover, Typography } from "antd";
import { setScreenshotPresets } from "store/features/userSettings/userSettingsSlice";
import styled from "styled-components";

import {
  addScreenshotPreset,
  deleteScreenshotPreset,
  editScreenshotPreset,
  getScreenshotPreset,
  getUserOrgScreenshots
} from "api/users";

import { ScreenshotPresetEditModel, ScreenshotPresetModel } from "models/model";

import { RadioTab } from "components/base/RadioTabs";

import MidstreamChartBackgroundColour from "./components/chart/MidstreamChartBackgroundColour";
import DashboardSettings from "./components/dashboard/DashboardSettings";
import ScreenshotAbbreviations from "./components/screenshot/ScreenshotAbbreviations";
import ScreenshotPresetTable from "./components/screenshot/ScreenshotPresetTable";
import { Info, InfoIconWrapper, InfoTitle, StyledRadioTabs } from "./shared/SharedStyle";

const tooltipText = `The following fields are in Imperial units of measure:
production volume rates and sums.

The following fields are in SI units of measure:
depths, lengths, areas, spacing, frac fluid volumes, proppant tonnage and concentration, pressures.`;

const dashboardTooltipText = `The dashboard display settings control how dashboards look for you.  They do not impact other users using the same dashboard.`;

const midstreamTooltipText =
  "The Midstream Chart Indicator toggle sets the background colour of midstream charts to be grey to allow differentiation from well charts.";
const { Title } = Typography;

const DisplaySettings = () => {
  // dispatches
  const dispatch = useDispatch();

  const [screenshotPresetForm] = Form.useForm();

  // state
  const [currentPreset, setCurrentPreset] = useState<ScreenshotPresetEditModel>({
    title: "",
    width: "",
    height: "",
    titleFontSize: "",
    axisFontSize: "",
    axisLabelFontSize: "",
    topsLabelsFontSize: "",
    dataLabelsFontSize: "",
    gridLabelsFontSize: "",
    legendFontSize: ""
  });

  // queries and mutations
  const {
    data: userScreenshotPresets,
    isError,
    refetch
  } = useQuery("userScreenshotPreset", () => {
    return getScreenshotPreset();
  });
  const {
    data: orgScreenshotPresets,
    isLoading: isOrgLoading,
    isError: isOrgError,
    refetch: orgRefetch
  } = useQuery("OrgScreenshotPreset", () => {
    return getUserOrgScreenshots();
  });
  const editMutation = useMutation(
    async (preset: ScreenshotPresetModel) => {
      return await editScreenshotPreset(preset);
    },
    {
      onSuccess: async () => {
        toast.success("Successfully updated screenshot preset.");
        await refetch();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (error: any) => {
        if (error.response?.data) {
          toast.error(`An error occurred. ${error.response.data}`);
        } else {
          toast.error("Unable to connect to network.");
        }
        await refetch();
      }
    }
  );
  const deleteMutation = useMutation(
    async (preset: ScreenshotPresetModel) => {
      await deleteScreenshotPreset(preset.id);
    },
    {
      onSuccess: async () => {
        toast.success("Successfully deleted preset.");
        await refetch();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data) {
          toast.error(`An error occurred. ${error.response.data}`);
        } else {
          toast.error("Unable to connect to network.");
        }
      }
    }
  );
  const addMutation = useMutation(
    async (preset: ScreenshotPresetModel) => {
      await addScreenshotPreset(preset);
    },
    {
      onSuccess: async () => {
        setCurrentPreset({
          title: "",
          width: "",
          height: "",
          axisFontSize: "",
          titleFontSize: "",
          axisLabelFontSize: "",
          topsLabelsFontSize: "",
          dataLabelsFontSize: "",
          gridLabelsFontSize: "",
          legendFontSize: ""
        });
        screenshotPresetForm.resetFields();
        await refetch();
        await orgRefetch();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data) {
          toast.error(`An error occurred. ${error.response.data}`);
        } else {
          toast.error("Unable to connect to network.");
        }
      }
    }
  );

  // handlers
  function isValidPreset(preset: ScreenshotPresetEditModel) {
    return Object.keys(preset).every((k) => {
      const allPropertiesHaveValues = preset[k].length || preset[k];
      return allPropertiesHaveValues;
    });
  }

  function handleFormChange(changedValue) {
    const key = Object.keys(changedValue)[0];
    const value = changedValue[key];
    const nonDigitCharsRegex = /\D+/g;
    let nextValue = value.trim();
    if (key !== "title") {
      nextValue = nextValue.replace(nonDigitCharsRegex, "");
    }
    setCurrentPreset({ ...currentPreset, [key]: nextValue });
  }

  async function handleAddPreset() {
    if (!isValidPreset(currentPreset)) return;

    await addMutation.mutateAsync({
      title: currentPreset.title,
      width: Number(currentPreset.width),
      height: Number(currentPreset.height),
      axisFontSize: Number(currentPreset.axisFontSize),
      titleFontSize: Number(currentPreset.titleFontSize),
      axisLabelFontSize: Number(currentPreset.axisLabelFontSize),
      topsLabelsFontSize: Number(currentPreset.topsLabelsFontSize),
      dataLabelsFontSize: Number(currentPreset.dataLabelsFontSize),
      gridLabelsFontSize: Number(currentPreset.gridLabelsFontSize),
      legendFontSize: Number(currentPreset.legendFontSize)
    });
  }

  async function handleEditPreset(preset: ScreenshotPresetModel) {
    editMutation.mutate(preset);
  }

  async function handleDeletePreset(preset: ScreenshotPresetModel) {
    if (!preset.id) {
      return;
    }
    deleteMutation.mutate(preset);
  }

  const getTableTitleMarkup = (title: string) => {
    return (
      <Title level={5} type="secondary">
        {title}
      </Title>
    );
  };

  useEffect(() => {
    if (!orgScreenshotPresets || !userScreenshotPresets) return;

    dispatch(setScreenshotPresets([...orgScreenshotPresets, ...userScreenshotPresets]));
  }, [userScreenshotPresets, orgScreenshotPresets, dispatch]);

  if (isError || isOrgError) {
    return <div>Unable to connect to backend service</div>;
  }

  return (
    <RootContainer>
      <Title level={3}>Display Settings</Title>

      <InfoTitle level={5} type="secondary">
        Default units of measurement
        <Popover content={<Info>{tooltipText}</Info>} placement="right">
          <InfoIconWrapper />
        </Popover>
      </InfoTitle>

      <StyledRadioTabs name="unitsMeasures" value={0}>
        <RadioTab value={0} label="Hybrid" isChecked readOnly />
      </StyledRadioTabs>

      <Divider />

      <InfoTitle level={4}>
        Dashboard Display Settings
        <Popover content={<Info>{dashboardTooltipText}</Info>} placement="right">
          <InfoIconWrapper />
        </Popover>
      </InfoTitle>

      <DashboardSettings />
      <SectionContainer>
        <InfoTitle level={4}>
          Midstream Display Settings
          <Popover content={<Info>{midstreamTooltipText}</Info>} placement="right">
            <InfoIconWrapper />
          </Popover>
        </InfoTitle>
        <InfoTitle level={5} type="secondary">
          <MidstreamChartBackgroundColour />
        </InfoTitle>
      </SectionContainer>

      <Divider />

      <div className="screenshot-presets">
        <Title level={4}>Screenshots Settings</Title>

        <ScreenshotPresetTable
          dataSource={orgScreenshotPresets}
          loading={isOrgLoading}
          title={getTableTitleMarkup("Organization Screenshot Presets")}
        />

        <ScreenshotPresetTable
          dataSource={userScreenshotPresets}
          editable
          loading={isOrgLoading}
          title={getTableTitleMarkup("My Screenshot Presets")}
          onChange={handleEditPreset}
          onDelete={handleDeletePreset}
        />

        <StyledForm
          form={screenshotPresetForm}
          layout="vertical"
          size="large"
          onValuesChange={handleFormChange}>
          <Form.Item name="title" label="Title">
            <Input placeholder="Screenshot title" />
          </Form.Item>

          <Form.Item name="width" label="Width">
            <Input placeholder="px" />
          </Form.Item>

          <Form.Item name="height" label="Height">
            <Input placeholder="px" />
          </Form.Item>

          <Form.Item name="titleFontSize" label="Title Size">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="axisFontSize" label="Axis Title">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="axisLabelFontSize" label="Axis Labels">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="topsLabelsFontSize" label="Tops Labels">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="dataLabelsFontSize" label="Data Labels">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="gridLabelsFontSize" label="Grid Labels">
            <Input placeholder="pt" />
          </Form.Item>

          <Form.Item name="legendFontSize" label="Legend Size">
            <Input placeholder="pt" />
          </Form.Item>

          <Button
            type="text"
            loading={addMutation.isLoading}
            onClick={handleAddPreset}
            disabled={!isValidPreset(currentPreset)}>
            + Add
          </Button>
        </StyledForm>
        {Object.values(currentPreset).some((value) => value !== "") &&
          !isValidPreset(currentPreset) && (
            <Typography.Text type="warning">
              All fields must have a value before adding a new preset.
            </Typography.Text>
          )}
      </div>

      <SectionContainer>
        <ScreenshotAbbreviations />
      </SectionContainer>
    </RootContainer>
  );
};

export default DisplaySettings;

const RootContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 32px 100px;
  overflow: auto;

  .section-heading {
    font-size: 1.6rem;
    font-weight: var(--fontWeightMedium);
    padding: 0;
  }

  .section-heading {
    padding-bottom: 15px;
  }

  .preset-category {
    padding-left: 10px;
    margin-top: 5px;

    .category-heading {
      font-size: 1.4rem;
      font-weight: var(--fontWeightMedium);
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

const StyledForm = styled(Form)`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 260px repeat(9, minmax(0, 1fr)) max-content;
  grid-column-gap: 12px;
  margin-top: 12px;

  .ant-form-item {
    margin-bottom: 0; // to adjust +Add button alignment with input fields
  }

  .ant-form-item-label {
    font-weight: 500;
  }

  .ant-form-item-label > label {
    height: unset; // removes default ant label height of 40px
  }

  .ant-form-item:not(:first-child) .ant-input {
    text-align: right; // align text to the right for all input fields except the first one
  }
`;

const SectionContainer = styled.div`
  padding-top: 18px;
`;
