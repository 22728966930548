import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ApolloError, useMutation } from "@apollo/client";
import {
  Business,
  Edit,
  Error,
  KeyboardBackspace,
  Person,
  Warning
} from "@material-ui/icons";
import { Button, Input, Select } from "antd";
import {
  setActiveSetting,
  setWorkspaceId
} from "store/features/userSettings/userSettingsSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { uid } from "utils";

import { useUser } from "hooks";

import {
  ADD_DASHBOARD,
  ADD_WORKSPACE,
  ADD_WORKSPACE_DASHBOARD,
  REMOVE_WORKSPACE_DASHBOARD,
  UPDATE_WORKSPACE,
  WorkspaceClientName
} from "api/workspace";

import { IDashboardGroup } from "models/dashboard";
import {
  Dashboard,
  DashboardInput,
  Workspace,
  WorkspaceDashboardInput,
  WorkspaceUpdate
} from "models/workspace";

import { BaseDivider, Heading, SubHeading, Tooltip } from "components/base";
import AddDashboardLinks from "components/dashboard/AddDashboardLinks";
import NewDashboardInput from "components/dashboard/NewDashboardInput";
import useDashboards from "components/dashboard/hooks/useDashboards";
import { getNewDashboardName } from "components/dashboard/utils/dashboardName";
import { IconSpinner } from "components/icons";
import WorkspaceInput from "components/workspace/WorkspaceInput";
import useWorkspace from "components/workspace/hooks/useWorkspace";

export default function WorkspaceManagement() {
  const dispatch = useDispatch();
  const workspaceId = useSelector((state: RootState) => state.userSetting.workSpaceId);
  const { user, isAtLeastPowerUser } = useUser();

  const [workspace, setWorkspace] = useState<Workspace>();
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [dashboardGroups, setDashboardGroups] = useState<IDashboardGroup[]>([]);
  const [activeDashboards, setActiveDashboards] = useState<IDashboardGroup[]>([]);
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard>(null);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [canAdd, setCanAdd] = useState<boolean>(false);
  const [canCopy, setCanCopy] = useState<boolean>(false);
  const [copyError, setCopyError] = useState("");
  const [showCopyDashboard, setShowCopyDashboard] = useState(false);
  const [selectedDashboardId, setSelectedDashboardId] = useState("");
  const [workspaceType, setWorkspaceType] = useState("");
  const [workspaceTitle, setWorkspaceTitle] = useState("");
  const [workspaceOwner, setWorkspaceOwner] = useState("");
  const [saveWorkspaceError, setSaveWorkspaceError] = useState("");
  const [workspaceDashboardsWarning, setWorkspaceDashboardsWarning] = useState("");
  const [editModalVisible, setEditModalVisible] = useState(false);

  const [addWorkspace] = useMutation(ADD_WORKSPACE);
  const [updateWorkspace, { loading: updateWorkspaceLoading }] =
    useMutation(UPDATE_WORKSPACE);
  const [addWorkspaceDashboard] = useMutation(ADD_WORKSPACE_DASHBOARD);
  const [addDashboard, { loading: addDashboardLoading }] = useMutation(ADD_DASHBOARD);
  const [removeDashboard] = useMutation(REMOVE_WORKSPACE_DASHBOARD);

  const { data: workspaceData, refetch: refetchWorkspace } = useWorkspace(workspaceId);

  const { data: dashboardData, refetch: refetchDashboards, loading } = useDashboards();

  // Refresh the dashboards and set the workspace when the user or selected workspace change.
  useEffect(() => {
    refetchDashboards();

    if (workspaceId) {
      // fetch fresh data from server
      refetchWorkspace();
    } else {
      const newWorkspace: Workspace = {
        workspaceId: uid(),
        title: "",
        type: "Personal",
        owner: user?.id,
        ownerName: `${user?.firstName} ${user?.lastName}`,
        createdDate: undefined,
        updateDate: undefined,
        dashboards: []
      };
      setWorkspace(newWorkspace);
    }
  }, [
    refetchDashboards,
    refetchWorkspace,
    user?.firstName,
    user?.id,
    user?.lastName,
    workspaceId
  ]);

  // Set the current workspace.
  useEffect(() => {
    if (!workspaceData?.workspace) {
      refetchWorkspace();
    } else {
      setWorkspace(workspaceData?.workspace);
    }
  }, [workspaceData, refetchWorkspace, setWorkspace]);

  // Set the current dashboards.
  useEffect(() => {
    if (dashboardData?.dashboards) {
      setDashboards(dashboardData.dashboards);
    }
  }, [dashboardData]);

  // Set the selected dashboard based on the result of a clone.
  useEffect(() => {
    if (selectedDashboardId) {
      const match = dashboards.find((d) => d.dashboardId === selectedDashboardId);
      if (match) {
        setSelectedDashboard(match);
        setSelectedDashboardId(null);
      }
    }
  }, [dashboards, selectedDashboardId]);

  // Take action when a dashboard is selected.
  const handleDashboardSelected = useCallback((dashboard: Dashboard) => {
    setSelectedDashboard(dashboard);
  }, []);

  // Take action when a dashboard is selected.
  const handleDashboardRemoved = useCallback(
    (dashboard: Dashboard) => {
      removeDashboard({
        variables: {
          workspaceId: workspace.workspaceId,
          dashboardId: dashboard.dashboardId
        },
        context: {
          clientName: WorkspaceClientName
        },
        onCompleted: () => {
          refetchWorkspace({ id: workspaceId });
        },
        onError: (error: ApolloError) => {
          // eslint-disable-next-line no-console
          console.error(error.message, error);
          toast.error("Failed to hide dashboard");
        }
      });
    },
    [refetchWorkspace, removeDashboard, workspace?.workspaceId, workspaceId]
  );

  // Take action when the dashboard order is changed.
  const handleReorderDashboards = useCallback(
    (workspaceDashboards: WorkspaceDashboardInput[]) => {
      updateWorkspace({
        variables: {
          workspace: {
            workspaceId: workspace.workspaceId,
            dashboards: workspaceDashboards
          }
        },
        context: {
          clientName: WorkspaceClientName
        },
        onCompleted: () => {
          refetchWorkspace({ id: workspaceId });
        },
        onError: (error: ApolloError) => {
          // eslint-disable-next-line no-console
          console.error(error.message, error);
        }
      });
    },
    [refetchWorkspace, updateWorkspace, workspace?.workspaceId, workspaceId]
  );

  // Update the copy and add state of new dashboards when a dashboard is selected.
  useEffect(() => {
    if (workspace && selectedDashboard) {
      // Determine if the dashboard is already part of the workspace
      const inWorkspace = workspace?.dashboards?.some(
        (d) => d.dashboardId === selectedDashboard?.dashboardId
      );
      setCanAdd(!inWorkspace);
    }

    setCanCopy(selectedDashboard !== null);
  }, [selectedDashboard, workspace]);

  // Handle the workspace changing.
  useEffect(() => {
    if (workspace) {
      setWorkspaceTitle(workspace.title);
      setWorkspaceType(workspace.type);
      setWorkspaceOwner(workspace.ownerName);
      setSaveWorkspaceError("");

      setCanEdit(user?.id === workspace.owner || isAtLeastPowerUser);
    }
  }, [isAtLeastPowerUser, user?.id, workspace]);

  // Update the grouped dashboards state when the remote dashboards data is updated
  useEffect(() => {
    if (dashboards) {
      const getDashboardTypeTitle = (type: string): [string, number, string] => {
        const title: string = type;
        let order = 0;
        let help = "";
        switch (type) {
          case "Personal":
            order = 0;
            help =
              "Dashboards you have personally created and are only available to you.";
            break;
          case "Shared":
            order = 1;
            help = "Dashboards shared by other users in your organization.";
            break;
          case "Organization":
            order = 2;
            help = "Dashboards created and maintained by your admins.";
            break;
          case "System":
            order = 3;
            help = "Dashboards created by the system that cannot be modified.";
            break;
        }
        return [title, order, help];
      };

      const groupBy = (xs: Dashboard[], key: string): IDashboardGroup[] => {
        const map = xs.reduce((rv, x) => {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});

        const groups: IDashboardGroup[] = [];
        for (const [key, dashboards] of Object.entries(map)) {
          const [title, order, help] = getDashboardTypeTitle(key);
          groups.push({
            groupKey: key,
            groupTitle: title,
            order,
            help,
            dashboards: dashboards as Dashboard[]
          });
        }
        return groups.sort((a, b) =>
          a.order > b.order ? 1 : b.order > a.order ? -1 : 0
        );
      };

      const availableDashboards = dashboards.filter(
        (d) =>
          !workspace?.dashboards.some((wd) => wd.dashboardId === d.dashboardId) &&
          (workspaceType === "Personal" || d.type != "Personal")
      );
      setDashboardGroups(groupBy(availableDashboards, "type"));

      const activeDashboardList = workspace?.dashboards.reduce((list, d) => {
        const match = dashboards.filter((dd) => dd.dashboardId === d.dashboardId);
        if (match) {
          list.push(match[0]);
        }
        return list;
      }, []);

      const groups: IDashboardGroup[] = [];
      const filteredDashboards = activeDashboardList;
      groups.push({
        groupKey: "active",
        groupTitle: "Selected Dashboards",
        order: 0,
        help: "Dashboards already added to the workspace",
        dashboards: filteredDashboards
      });
      setActiveDashboards(groups);
    }
  }, [dashboards, workspace?.dashboards, workspaceType]);

  // Determine if any warning need to be shown based on the current selected dashboards.
  useEffect(() => {
    if (activeDashboards?.length > 0 && activeDashboards[0].dashboards?.length === 0) {
      setWorkspaceDashboardsWarning("No dashboards selected.");
    } else if (
      workspaceType !== "Personal" &&
      workspace?.dashboards?.filter((d) => d.type === "Personal").length > 0
    ) {
      setWorkspaceDashboardsWarning(
        `It is not recommended to use Personal dashboards on ${workspaceType} workspaces`
      );
    } else {
      setWorkspaceDashboardsWarning("");
    }
  }, [activeDashboards, workspace?.dashboards, workspaceType]);

  // Take action when the workspace is saved.
  const handleAddWorkspace = useCallback(() => {
    if (!workspace) return;

    if (!workspaceTitle) {
      setSaveWorkspaceError("Title is required.");
      return;
    }

    addWorkspace({
      variables: {
        workspace: {
          title: workspaceTitle,
          type: workspaceType,
          dashboards: []
        }
      },
      context: {
        clientName: WorkspaceClientName
      },
      onCompleted: (data) => {
        setSaveWorkspaceError("");
        if (data?.addWorkspace?.workspaceId) {
          dispatch(setActiveSetting("WorkspaceManagement"));
          dispatch(setWorkspaceId(data.addWorkspace.workspaceId));
        }
      },
      onError: (error: ApolloError) => {
        // eslint-disable-next-line no-console
        console.error(error.message, error);
        setSaveWorkspaceError(error.message);
      }
    });
  }, [addWorkspace, workspace, workspaceTitle, workspaceType]);

  // Take action when the workspace is saved.
  const handleSaveWorkspace = useCallback(
    (title: string, type: string) => {
      if (!workspace) return;

      if (!workspaceTitle) {
        setSaveWorkspaceError("Title is required.");
        return;
      }

      const updatedWorkspace: WorkspaceUpdate = {
        workspaceId: workspaceId,
        title: title,
        type: type
      };

      if (workspace.type !== type && type !== "Personal") {
        // Remove any personal wells from the non-personal workspace.
        const updatedDashboards: WorkspaceDashboardInput[] = workspace.dashboards.reduce(
          (list, d) => {
            if (d.type !== "Personal") {
              list.push({
                dashboardId: d.dashboardId,
                favourite: true
              });
            }
            return list;
          },
          []
        );

        updatedWorkspace.dashboards = updatedDashboards;
      }

      updateWorkspace({
        variables: {
          workspace: updatedWorkspace
        },
        context: {
          clientName: WorkspaceClientName
        },
        onCompleted: () => {
          setSaveWorkspaceError("");
          setEditModalVisible(false);
          refetchWorkspace({ id: workspaceId });
        },
        onError: (error: ApolloError) => {
          // eslint-disable-next-line no-console
          console.error(error.message, error);
          setSaveWorkspaceError(error.message);
        }
      });
    },
    [refetchWorkspace, updateWorkspace, workspace, workspaceId, workspaceTitle]
  );

  // Take action when a selected dashboard is selected to copied to the workspace.
  const handleCopyDashboard = useCallback(
    (newDashboardTitle: string, newDashboardType: string) => {
      if (!selectedDashboard) return;

      const newDashboard: DashboardInput = {
        parentId: selectedDashboard.dashboardId,
        title: newDashboardTitle,
        type: newDashboardType,
        layout: {
          format: selectedDashboard.layout.format,
          width: selectedDashboard.layout.width,
          height: selectedDashboard.layout.height
        },
        widgets: selectedDashboard.widgets.map((w) => {
          return {
            title: w.title,
            type: w.type,
            x: w.x,
            y: w.y,
            width: w.width,
            height: w.height,
            settings: w.settings
          };
        })
      };
      addDashboard({
        variables: {
          dashboard: newDashboard
        },
        context: {
          clientName: WorkspaceClientName
        },
        onCompleted: (data) => {
          refetchDashboards();
          if (data?.addDashboard?.dashboardId) {
            setSelectedDashboardId(data.addDashboard.dashboardId);
          }
          setShowCopyDashboard(false);
        },
        onError: (error: ApolloError) => {
          // eslint-disable-next-line no-console
          console.error(error.message, error);
          setCopyError(error.message);
        }
      });
    },
    [addDashboard, refetchDashboards, selectedDashboard]
  );

  // Take action when a selected dashboard is selected to be added to the workspace.
  const handleAddDashboard = useCallback(() => {
    if (!selectedDashboard) return;

    addWorkspaceDashboard({
      variables: {
        workspaceId: workspace.workspaceId,
        dashboardId: selectedDashboard.dashboardId,
        position: workspace.dashboards.length
      },
      context: {
        clientName: WorkspaceClientName
      },
      onCompleted: () => {
        refetchWorkspace({ id: workspaceId });
      },
      onError: (error: ApolloError) => {
        // eslint-disable-next-line no-console
        console.error(error.message, error);
      }
    });
  }, [
    addWorkspaceDashboard,
    refetchWorkspace,
    selectedDashboard,
    workspace,
    workspaceId
  ]);

  const getWorkspaceTypeOptions = useCallback(() => {
    if (isAtLeastPowerUser) {
      return [
        {
          label: (
            <WorkspaceTypeItem>
              <Person /> Personal
            </WorkspaceTypeItem>
          ),
          value: "Personal"
        },
        {
          label: (
            <WorkspaceTypeItem>
              <Business /> Organization
            </WorkspaceTypeItem>
          ),
          value: "Organization"
        }
      ];
    } else {
      return [
        {
          label: (
            <WorkspaceTypeItem>
              <Person /> Personal
            </WorkspaceTypeItem>
          ),
          value: "Personal"
        }
      ];
    }
  }, [isAtLeastPowerUser]);

  if (!workspace) {
    return (
      <SpinnerContainer>
        <IconSpinner></IconSpinner>
      </SpinnerContainer>
    );
  }

  return (
    <>
      <RootContainer>
        <HeaderContainer>
          <Heading element="h4">Workspace Management</Heading>
          <HeaderActionContainer
            onClick={() => {
              dispatch(setActiveSetting("WorkspacesList"));
              dispatch(setWorkspaceId(null));
            }}>
            <KeyboardBackspace /> Back
          </HeaderActionContainer>
        </HeaderContainer>
        <ContentContainer>
          <SectionHeader>
            <SubHeading>Workspace Details</SubHeading>
            <BaseDivider />
          </SectionHeader>

          <FormContainer>
            <FormInput>
              <FormInputLabel>Title</FormInputLabel>
              <Input
                id="Title"
                placeholder="Title"
                value={workspaceTitle}
                onChange={(e) => setWorkspaceTitle(e.target.value)}
                disabled={workspaceId}
              />
            </FormInput>
            <FormInput>
              <FormInputLabel>Type</FormInputLabel>
              <SelectWrapper
                popupClassName="modal-select"
                value={workspaceType}
                onChange={(value) => setWorkspaceType(value)}
                options={getWorkspaceTypeOptions()}
                disabled={workspaceId}
              />
            </FormInput>
            <FormInput>
              <FormInputLabel>Owner</FormInputLabel>
              <Input
                id="Title"
                placeholder="Title"
                value={workspaceOwner}
                disabled={true}
              />
            </FormInput>
            <FormInput>
              {workspaceId && canEdit && workspace.title !== "Explore" && (
                <>
                  <FormInputLabel>&nbsp;</FormInputLabel>
                  <Tooltip placement="top" title="Edit Workspace Details">
                    <WorkspaceActionButton
                      data-testid="edit-button"
                      onClick={() => setEditModalVisible(true)}>
                      <Edit fontSize="large" />
                    </WorkspaceActionButton>
                  </Tooltip>
                </>
              )}
            </FormInput>
          </FormContainer>
          {!workspaceId && (
            <>
              {saveWorkspaceError && (
                <ErrorContainer>
                  <Error style={{ top: 3 }} /> {saveWorkspaceError}
                </ErrorContainer>
              )}
              <Actions>
                <Button key="copy" type="primary" onClick={() => handleAddWorkspace()}>
                  {workspaceId ? "Save" : "Create"}
                </Button>
              </Actions>
            </>
          )}

          {workspaceId && (
            <>
              <SectionBody>
                <AddDashboardLinks
                  workspace={workspace}
                  dashboardGroups={activeDashboards}
                  selectedDashboard={selectedDashboard}
                  onDashboardSelected={handleDashboardSelected}
                  allowRemove={canEdit}
                  onDashboardRemoved={handleDashboardRemoved}
                  enableReorder={canEdit}
                  onReorderDashboards={handleReorderDashboards}
                />
                {canEdit && workspaceDashboardsWarning && (
                  <WarningContainer>
                    <Warning style={{ top: 3 }} /> {workspaceDashboardsWarning}
                  </WarningContainer>
                )}
              </SectionBody>
              {canEdit && (
                <>
                  <SectionHeader>
                    <div className="section-heading">
                      <SubHeading>Available Dashboards</SubHeading>
                      <BaseDivider />
                    </div>
                  </SectionHeader>

                  {loading && (
                    <DashboardsLoadingWrapper>
                      <IconSpinner />
                    </DashboardsLoadingWrapper>
                  )}

                  <SectionBody>
                    <DashboardsContainer>
                      <AddDashboardLinks
                        workspace={workspace}
                        dashboardGroups={dashboardGroups}
                        selectedDashboard={selectedDashboard}
                        onDashboardSelected={handleDashboardSelected}
                        showDivider={false}
                      />
                    </DashboardsContainer>
                    <Actions>
                      <Button
                        key="copy"
                        type="primary"
                        onClick={() => setShowCopyDashboard(true)}
                        disabled={!canCopy}>
                        Clone Dashboard
                      </Button>
                      <Button
                        key="add"
                        type="primary"
                        onClick={handleAddDashboard}
                        disabled={!canAdd}>
                        Add to Workspace
                      </Button>
                    </Actions>
                  </SectionBody>
                </>
              )}
            </>
          )}
        </ContentContainer>

        <NewDashboardInput
          onAdd={(title: string, type: string) => {
            handleCopyDashboard(title.trim(), type);
          }}
          onCancel={() => setShowCopyDashboard(false)}
          isLoading={addDashboardLoading}
          error={copyError}
          visible={showCopyDashboard}
          title="Clone Dashboard"
          dashboardTitle={getNewDashboardName(workspace?.dashboards, selectedDashboard)}
          setType={true}
          addButtonLabel="Clone"
        />

        <WorkspaceInput
          workspace={workspace}
          isLoading={updateWorkspaceLoading}
          error={saveWorkspaceError}
          visible={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          onSave={(title: string, type: string) => handleSaveWorkspace(title, type)}
        />
      </RootContainer>
    </>
  );
}

//Styled Component
const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  background: #dededec4;
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 750px;
  max-width: 900px;
  overflow: auto;
  padding-right: 100px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
  .ant-btn {
    border-radius: 5px;
  }
  .ant-btn:hover {
    border-radius: 5px;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
  button[disabled],
  button[disabled]:hover {
    background-color: #a2aaad;
    border-color: rgba(0, 0, 0, 0.15);
    color: white;
  }
`;

const ContentContainer = styled.div`
  min-width: 750px;
  max-width: 900px;
  overflow: auto;
  padding-right: 100px;
`;

const DashboardsContainer = styled.div`
  overflow-x: auto;
  overflow-y: visible;
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) auto;
  gap: 12px;
  padding: 20px 0 35px 0;
  .ant-input {
    border-radius: 5px;
    width: 100%;
  }
`;

const FormInput = styled.div``;

const FormInputLabel = styled.div`
  padding-bottom: 10px;
`;

const RootContainer = styled.div`
  gap: 10px;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  text-align: left;
  padding: 32px 100px;
  overflow: auto;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  .ant-divider-horizontal {
    margin: 5px 0;
  }
  .section {
    padding: 33px 0;
  }
  .section-heading {
    font-size: 1.6rem;
    padding-bottom: 22px;
    font-weight: var(--fontWeightMedium);
    padding: 0;
  }
`;

const SectionBody = styled.div`
  padding: 33px 0;
`;

const SectionHeader = styled.div`
  width: 100%;
  h3 {
    font-size: 1.8rem;
    color: #a2aaad;
    font-weight: var(--fontWeightMedium);
  }
`;

const HeaderActionContainer = styled.button`
  flex: 1;
  display: flex;
  max-width: 650px;
  flex-direction: row;
  flex-grow: 1;
  border: 0;
  justify-content: flex-end;
  cursor: pointer;
  background: transparent;
  color: var(--color-primary);
  font-weight: var(--fontWeightMedium);
  &:hover {
    color: var(--color-text);
  }
`;

const WorkspaceTypeItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const SelectWrapper = styled(Select)`
  width: 100% !important;
`;

const ErrorContainer = styled.div`
  color: var(--color-danger);
  font-weight: var(--fontWeightMedium);
`;

const WarningContainer = styled.div`
  color: var(--orange);
  font-weight: var(--fontWeightMedium);
`;

const DashboardsLoadingWrapper = styled.div`
  text-align: center;
`;

const WorkspaceActionButton = styled.button`
  flex: 1 1 auto;
  border: 0;
  padding: 0;
  margin: 0;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: transparent;
  color: #a2aaad;
  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }
`;
