import { Clear } from "@material-ui/icons";
import { Button } from "antd";
import styled from "styled-components";

import { IGroupByMap } from "models";

import { BaseButton } from "components/base";
import GroupBy from "components/groupBy";

interface XdaDataPickerSelectorProps {
  groupByValue: IGroupByMap;
  setGroupBy: (params: { title: string; property: string }) => void;
}

export function XdaDataPickerSelector(props: XdaDataPickerSelectorProps) {
  const { title } = props.groupByValue;

  const clearButton = title ? (
    <StyledXdaClearButton
      type="link"
      title="Clear"
      onClick={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        props.setGroupBy({ title: undefined, property: "None.Right" });
      }}
      icon={<Clear className="clear-icon" />}
    />
  ) : null;

  return (
    <ButtonWrapper>
      <GroupBy onChange={props.setGroupBy} value={props.groupByValue}>
        <BaseButton iconAfter={clearButton}>{title ?? "Select Field"}</BaseButton>
      </GroupBy>
    </ButtonWrapper>
  );
}

const StyledXdaClearButton = styled(Button)`
  margin-top: 3px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  &:hover {
    .clear-icon {
      color: white;
    }
  }

  ${StyledXdaClearButton}:hover .clear-icon {
    color: black;
  }
`;
