import { createSlice } from "@reduxjs/toolkit";

import { GeoCalculationsJob } from "components/org-settings/models/GeoCalculations";

interface IOrganizationSettings {
  isUpdateGeoCalculationJobModalOpen: boolean;
  selectedGeoCalculationJob: GeoCalculationsJob;
}

const initialState: IOrganizationSettings = {
  isUpdateGeoCalculationJobModalOpen: false,
  selectedGeoCalculationJob: undefined
};

const organizationSettingsSlice = createSlice({
  name: "organizationSettings",
  initialState,
  reducers: {
    setIsUpdateGeoCalculationJobModalOpen(state, action) {
      state.isUpdateGeoCalculationJobModalOpen = action.payload;
    },
    setSelectedGeoCalculationJob(state, action) {
      state.selectedGeoCalculationJob = action.payload;
    },
    resetOrgSettingsSlice: () => initialState
  }
});

export const {
  setIsUpdateGeoCalculationJobModalOpen,
  setSelectedGeoCalculationJob,
  resetOrgSettingsSlice
} = organizationSettingsSlice.actions;

export default organizationSettingsSlice.reducer;
