import { useQuery } from "@apollo/client";

import { DASHBOARD, WorkspaceClientName } from "api/workspace";

import { WorkspaceQuery } from "models/workspace";

///hook to fetch Dashboard
export default function useDashboard(id: string) {
  return useQuery<WorkspaceQuery>(DASHBOARD, {
    context: {
      clientName: WorkspaceClientName
    },
    variables: {
      id: id
    },
    skip: id === undefined // Skip the query if id is undefined
    // fetchPolicy: 'no-cache', // Avoid any caching
    // notifyOnNetworkStatusChange: true // Ensure updates trigger re-renders
  });
}
