import { ALL_CHART_TYPES, ChartTypeLabels } from "constants/chart.constants";

import { BaseChart } from "./baseChart";
import { BaseDeclineRate } from "./baseDeclineRate";
import { CagrChart } from "./cagr";
import { RateDate } from "./rateDate";
import { TotalRateDate } from "./totalRateDate";
import { ChartT } from "./types";
import { WellContributionChart } from "./wellContribution";

export function getChart(label: ChartTypeLabels): ChartT | null {
  switch (label) {
    case ALL_CHART_TYPES.BaseDeclineRate.label:
      return new BaseDeclineRate();
    case ALL_CHART_TYPES.CAGR.label:
      return new CagrChart();
    case ALL_CHART_TYPES.WellContribution.label:
      return new WellContributionChart();
    case ALL_CHART_TYPES.RateDate.label:
      return new RateDate();
    case ALL_CHART_TYPES.TotalRateDate.label:
      return new TotalRateDate();
    case ALL_CHART_TYPES.RateCum.label:
    case ALL_CHART_TYPES.RateTime.label:
    case ALL_CHART_TYPES.CumTime.label:
    case ALL_CHART_TYPES.TotalRateCum.label:
      return new BaseChart();
    default:
      return null;
  }
}
