import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { Select } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { fetchUserDataSources } from "api/dataSource";

import { EntityKind } from "models/entityKind";
import { PdenSourceEnum } from "models/pdenDataSourceSetting";

import { useGroupByState, useGroupByUpdater } from "./group-by.context";

interface PdenSourceSelectInput {
  showLabel?: boolean;
  showGroup?: boolean;
  isDataTable?: boolean;
  initialState?: { selectedProperties: string[]; pdenSource: PdenSourceEnum };
}

export default function PdenSourceSelect({
  showLabel = true,
  showGroup = false,
  isDataTable = false,
  initialState = null
}: PdenSourceSelectInput) {
  const { selectedPdenSource, entityKind } = useGroupByState();
  const initialStateSetRef = useRef(false);
  const updateGroupByState = useGroupByUpdater();
  const groupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);
  const { data, refetch } = useQuery(
    "userSources",
    async () => {
      const response = await fetchUserDataSources();
      if (response.ok) {
        const production = response.value.filter((ds) => ds.category === "Production");
        if (production.length == 1) {
          return production[0];
        }
      }
      return null;
    },
    {
      enabled: false
    }
  );
  const [sourceOptions, setSourceOptions] = useState([]);
  const pdenSource = useSelector((state: RootState) => state.app.pdenDataSourceSetting);
  useEffect(() => {
    refetch();
  }, [pdenSource, refetch]);

  useEffect(() => {
    if (isDataTable) {
      return;
    }
    if (!sourceOptions || sourceOptions.length === 0) {
      return;
    }
    if (
      entityKind === EntityKind.Well &&
      groupBy.property.toLowerCase().includes("performance") &&
      sourceOptions.find((opt) => opt.value === parseInt(groupBy.pdenSource))
    ) {
      updateGroupByState({
        type: "selected_pden_source",
        payload: parseInt(groupBy.pdenSource)
      });
      return;
    }
    updateGroupByState({
      type: "selected_pden_source",
      payload: sourceOptions[0].value
    });
  }, [sourceOptions, groupBy, entityKind, isDataTable]);

  useEffect(() => {
    if (!isDataTable) {
      return;
    }
    if (!sourceOptions || sourceOptions.length === 0) {
      return;
    }

    const hasInitialPdenSource = initialState?.pdenSource != null;
    const isFirstRender = !initialStateSetRef.current;
    const isValidSource = sourceOptions.find(
      (opt) => opt.value === initialState?.pdenSource
    );

    if (hasInitialPdenSource && isFirstRender && isValidSource) {
      updateGroupByState({
        type: "selected_pden_source",
        payload: initialState.pdenSource
      });
      initialStateSetRef.current = true;
    } else {
      updateGroupByState({
        type: "selected_pden_source",
        payload: sourceOptions[0].value
      });
    }
  }, [sourceOptions, entityKind, isDataTable, initialState]);

  useEffect(() => {
    if (!data) {
      setSourceOptions([]);
      return;
    }
    if (pdenSource.source == "Public") {
      setSourceOptions(
        [{ value: PdenSourceEnum.Public, label: "All Public Sources" }].concat(
          data.dataSources.map((ds) => ({
            value: parseInt(ds.source),
            label: ds.dataSourceName
          }))
        )
      );
    } else if (pdenSource.source == "Private") {
      setSourceOptions([{ value: PdenSourceEnum.Private, label: "Private Source" }]);
    } else {
      setSourceOptions(
        [
          {
            value: PdenSourceEnum.Hybrid,
            label: "Hybrid - Private trumps Public"
          },
          { value: PdenSourceEnum.Private, label: "Private" }
        ].concat(
          data.dataSources.map((ds) => ({
            value: parseInt(ds.source),
            label: ds.dataSourceName
          }))
        )
      );
    }
  }, [data, pdenSource]);

  if (!data) {
    return <Select />;
  }

  return (
    <SelectWrapper>
      {showLabel && <label>Production Source</label>}
      <Select
        value={selectedPdenSource}
        onChange={(val) => {
          updateGroupByState({ type: "selected_pden_source", payload: val });
        }}
        options={
          showGroup ? [{ label: "Pden Source", options: sourceOptions }] : sourceOptions
        }></Select>
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  min-width: 100px;
  width: 100%;
  .ant-select {
    width: 100%;
  }
`;
