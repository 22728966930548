import { useEffect, useMemo, useState } from "react";

import {
  ALL_PRODUCT_TYPES,
  CrossKindProduct,
  CrossKindProductList,
  SalesProductList
} from "constants/chart.constants";

import { ArpSegment } from "models/UserArpsModel";

import { useChartState } from "components/chart";
import { useChartDependencies } from "components/chart/hooks";

import { EntityKind } from "../../../models/entityKind";
import { getProductType, productStringToProductTypeEnum, uid } from "../../../utils";
import { ProductTypeEnum } from "../models/ArpsSegment";
import { arpsWasm } from "../utils/UpdateSegment";
import { ArpsSegmentToSegmentDto as arpsSegmentToSegmentDto } from "../utils/arpsUtils";
import { checkSeriesProductBelongsToSameGroupAsChartProduct } from "../utils/checkSeriesProductBelongsToSameGroupAsChartProduct";
import { getTypeWellNormalizationValue } from "../utils/getTypeWellNormalization";
import { groupArpsSegmentsByProduct } from "../utils/groupArpsSegmentsByProduct";
import { getTypeWellSeries } from "../utils/typeWellUtils";
import { useFetchForecastArray } from "./useFetchForecastArray";

export default function useTypeWellSeries(normalizeTypeWell: boolean) {
  const { checkedGlobalTypeWells } = useChartDependencies(EntityKind.Well);
  const { response, settings: chartSettings, entityKind } = useChartState();

  const [typeWellSeries, setTypeWellSeries] = useState([]);

  //type well id only updates when the store value changes
  //this allows us to identify type wells with new data vs no changes
  const typeWellId = useMemo(() => uid(), [checkedGlobalTypeWells]);
  const { data: forecastArrays } = useFetchForecastArray({
    ids: Array.isArray(checkedGlobalTypeWells)
      ? checkedGlobalTypeWells.map((tw) => tw.id)
      : []
  });

  useEffect(() => {
    updateTypeWellSeries();
  }, [
    typeWellId,
    normalizeTypeWell,
    chartSettings.chartType,
    chartSettings.product,
    chartSettings.timeStep,
    chartSettings.useNormalizeBy,
    chartSettings.normalizeBy,
    response?.layout?.yAxis?.title,
    forecastArrays
  ]);

  function updateTypeWellSeries() {
    const chartType = chartSettings.chartType;
    const isNonTotalDeclineChart =
      chartType &&
      (chartType.includes("Rate") || chartType.includes("Cum Time")) &&
      !chartType.includes("Total");
    const isCrossKindProduct = CrossKindProductList.includes(chartSettings.product);
    if (
      !arpsWasm ||
      !checkedGlobalTypeWells ||
      !chartSettings.product ||
      !isNonTotalDeclineChart ||
      entityKind === EntityKind.Facility ||
      (SalesProductList.includes(chartSettings.product) &&
        !isCrossKindProduct &&
        chartSettings.product !== "Sales Gas") // sales gas can be selected as a product to create type wells
    ) {
      setTypeWellSeries([]);
      return;
    }

    const titles = {};
    const temporaryTypeWellSeries = [];
    const product = getProductType(chartSettings.product);

    const crossKindProductKey =
      CrossKindProduct.find((prd) => prd.items.some((item) => item.key === product.key))
        ?.group ?? undefined;

    for (const tw of checkedGlobalTypeWells) {
      const arpsGroupedByProduct = groupArpsSegmentsByProduct(tw.arps);
      const hasSelectedProductionSegment = (tw.arps ?? []).some(
        (a) =>
          a.product?.toLowerCase() === product.key.toLowerCase() ||
          ((product.key === ALL_PRODUCT_TYPES.Oil.key ||
            crossKindProductKey === ALL_PRODUCT_TYPES.Oil.key) &&
            (a.product === "COND." || a.product === "CONDENSATE")) ||
          (isCrossKindProduct &&
            crossKindProductKey?.toLowerCase() === a.product?.toLowerCase())
      );

      // If this has less than 2 segments, we don't need to check for ramp-up
      const isRampUpTw = (v: ArpSegment[]) =>
        v.length > 2 && v[0].di < 0 && v[1].di > 0 && v[2].di > 0;

      let selectedProductSegment = crossKindProductKey
        ? arpsGroupedByProduct[crossKindProductKey.toUpperCase()]
        : arpsGroupedByProduct[product.key.toUpperCase()];
      if (
        selectedProductSegment == null &&
        (product.key === ALL_PRODUCT_TYPES.Oil.key ||
          crossKindProductKey === ALL_PRODUCT_TYPES.Oil.key)
      ) {
        selectedProductSegment =
          "COND." in arpsGroupedByProduct
            ? arpsGroupedByProduct["COND."]
            : arpsGroupedByProduct["CONDENSATE"];
      }

      // Ignore this check if we have a selected production segment
      const checkForRampUpTw =
        (hasSelectedProductionSegment && isRampUpTw(selectedProductSegment)) ||
        Object.values(arpsGroupedByProduct).every(isRampUpTw);
      const hasForecastArray =
        forecastArrays &&
        forecastArrays.forecastArray[tw.id] &&
        forecastArrays.forecastArray[tw.id].length > 0;
      if (!tw.arps?.length && !hasForecastArray) {
        continue;
      }

      // Don't render decline type for ramp-up segment
      const declineSegments = [
        ...tw.arps.filter((a) => !checkForRampUpTw || (a.qi > a.qf && a.di > 0))
      ];

      const dto = arpsSegmentToSegmentDto(arpsWasm, declineSegments);

      if (dto.length == 0 && !hasForecastArray) {
        continue;
      }

      const forecastConstantsWithUnit = (tw.constants ?? [])
        .map((fconst) => {
          const productEnum = productStringToProductTypeEnum(fconst.product);
          //fix the product string so it matches the enum name
          const productString = ProductTypeEnum[productEnum];
          if (!fconst.unit) {
            return null;
          }
          return {
            ...fconst,
            unit: fconst.unit,
            product: productString
          };
        })
        .filter((x) => x != null);

      try {
        const normalize = Object.assign({}, chartSettings.normalizeBy, {
          normalize: chartSettings.useNormalizeBy,
          normalizeTypeWell: normalizeTypeWell,
          // some old Chart widgets will missed those settings by default, so make sure they are set
          // since arpsWasm required these settings, leaving them empty will cause wasm to throw error (and default to default normalization, which is no normalization)
          useMultilinearNormalization:
            chartSettings.normalizeBy?.useMultilinearNormalization ?? false,
          threshold: chartSettings.normalizeBy?.threshold ?? 0.0,
          lowerScalar: chartSettings.normalizeBy?.lowerScalar ?? 0.0,
          higherScalar: chartSettings.normalizeBy?.lowerScalar ?? 0.0
        });

        const wellData = normalize.normalize
          ? {
              length: tw.wellData?.HzLength ?? 0,
              stage: tw.wellData?.Stage ?? 0,
              stage_spacing: tw.wellData?.StageSpacing ?? 0,
              proppant: tw.wellData?.Proppant ?? 0,
              proppant_intensity: tw.wellData?.ProppantIntensity ?? 0,
              additional_data: tw.wellData?.AdditionalData ?? []
            }
          : {
              length: 0,
              stage: 0,
              stage_spacing: 0,
              proppant: 0,
              proppant_intensity: 0,
              additional_data: []
            };

        const seriesData = hasForecastArray
          ? arpsWasm.getForecastFromArray(
              forecastArrays.forecastArray[tw.id],
              normalize,
              wellData,
              {}
            )
          : arpsWasm.getForecastFromArpsSegments(
              dto,
              forecastConstantsWithUnit,
              normalize,
              wellData,
              {},
              {
                isDaily: chartSettings?.timeStep === "day",
                isCumTime: chartType === "Cum Time"
              }
            );

        let normalizeValue = 1.0;
        if (normalize.normalize && normalize.normalizeTypeWell) {
          normalizeValue = getTypeWellNormalizationValue(normalize, wellData);
        }

        for (const series of seriesData) {
          //added indexOf check here because "Total Fluid" key does not match product name
          //from series.product (Total), but Oil and Water should not match for Oil and Water Cut
          const isMismatchedProduct = series.product !== product.key.replace(/\s/g, "");
          const isTotalFluidMatch =
            product.key == ALL_PRODUCT_TYPES.TotalFluid.key &&
            product.key.indexOf(series.product) >= 0;
          const isOnePlusWORMatch =
            series.product == "OnePlusWOR" && product.key == "1+WOR";
          const seriesProductBelongsToSameGroupAsChartProduct =
            isCrossKindProduct &&
            checkSeriesProductBelongsToSameGroupAsChartProduct(series, product);
          if (
            isMismatchedProduct &&
            !isTotalFluidMatch &&
            !isOnePlusWORMatch &&
            !seriesProductBelongsToSameGroupAsChartProduct
          ) {
            continue;
          }
          const yAxisTitle = response ? response?.layout?.yAxis?.title : "";
          titles[tw.title] = tw.title;
          temporaryTypeWellSeries.push({
            name: tw.title,
            id: typeWellId + tw.id,
            originalId: tw.id, // used to identify the type well id in the legend
            type: "line",
            kind: "typewell",
            itemStyle: {
              opacity: 1,
              color: tw.color ?? "#000000"
            },
            showSymbol: false,
            lineStyle: { opacity: 1, type: "dashed", width: tw.thickness },
            line: {
              originalColor: tw.color ?? "#000000",
              originalWidth: tw.thickness
            },
            data: getTypeWellSeries(
              chartSettings.chartType,
              series.product,
              tw.arps,
              forecastConstantsWithUnit,
              series.rate_cum,
              normalizeValue,
              chartSettings?.timeStep === "day",
              checkForRampUpTw,
              yAxisTitle
            )
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return;
      }
    }

    setTypeWellSeries([...temporaryTypeWellSeries]);
  }

  return { typeWellSeries };
}
