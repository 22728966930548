import { useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";

import { UndoTwoTone } from "@material-ui/icons";
import { undoFilter } from "store/features";
import { undoFilter as undoFacilityFilter } from "store/features/filter/facilityFilterSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { ButtonIconCentered } from "components/activity/shared";
import { Tooltip } from "components/base";

import { EntityKind } from "../../models/entityKind";

export interface UndoFilterProps {
  entityKind: EntityKind;
}

export default function UndoFilter({ entityKind }: UndoFilterProps) {
  const canUndo = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.filter.filterHistoryJSON.length > 1
      : state.facilityFilter.filterHistoryJSON.length > 1
  );
  const undoRef = useRef(null);
  const dispatch = useDispatch();

  const undo = () => {
    if (undoRef.current) {
      const componentNode = undoRef.current;
      const computedStyle = window.getComputedStyle(componentNode);
      const isComponentHidden =
        computedStyle.getPropertyValue("display") === "none" ||
        computedStyle.getPropertyValue("visibility") === "hidden";
      if (isComponentHidden) {
        return;
      }
    }
    dispatch(entityKind == EntityKind.Well ? undoFilter() : undoFacilityFilter());
  };

  useHotkeys(
    "ctrl+z",
    () => {
      undo();
    },
    [undo]
  );

  return (
    <RootContainer ref={undoRef}>
      <Tooltip title="Undo Filter">
        <ButtonIconCentered
          aria-label="Undo filter"
          disabled={!canUndo}
          onClick={() => undo()}
          type="text"
          icon={<UndoTwoTone></UndoTwoTone>}
          shape="circle"
        />
      </Tooltip>
    </RootContainer>
  );
}

const RootContainer = styled.div`
  margin-left: auto;
`;
