import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import _debounce from "lodash/debounce";
import {
  removeExcludePolygon as removeFacilityExcludePolygon,
  removeProperty as removeFacilityProperty,
  removeWellListFilter as removeFacilityWellListFilter
} from "store/features/filter/facilityFilterSlice";
import {
  removeExcludePolygon,
  removeProperty,
  removeWellListFilter
} from "store/features/filter/filterSlice";
import { setViewLock } from "store/features/map/mapSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { queryToPredicates } from "utils/filter";

import { EntityKind } from "../../models/entityKind";
import PredicateTree from "./PredicateTree";

const emptyPredicate = {
  id: "",
  title: "",
  name: "",
  children: [],
  expanded: false,
  operator: "",
  type: "",
  isChecked: false
};

type ActiveFilterProps = {
  className?: string;
  onNodeRemoved?: (node) => void;
  onMouseDoubleClick?: (node) => void;
  entityKind: EntityKind;
};

const ActiveFilter: FC<ActiveFilterProps> = ({
  className = "",
  onNodeRemoved,
  onMouseDoubleClick,
  entityKind = EntityKind.Well
}) => {
  const dispatch = useDispatch();
  const [predicates, setPredicates] = useState([emptyPredicate]);

  // state from store
  const filterCounts = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.filter.filterCounts
      : state.facilityFilter.filterCounts
  );
  const currentFilter = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.filter.currentFilter
      : state.facilityFilter.currentFilter
  );

  const isViewLocked = useSelector((state: RootState) => state.map.viewLock);
  const isViewLockedRef = useRef(isViewLocked);

  // sync filter selection with predicates
  useEffect(() => {
    if (!currentFilter) return;

    const debouncedFn = _debounce((val, counts) => {
      const nextPredicates = queryToPredicates(val.query, counts);
      setPredicates([nextPredicates]);
    }, 500);

    debouncedFn(currentFilter, filterCounts);
  }, [currentFilter, filterCounts]);

  useEffect(() => {
    isViewLockedRef.current = isViewLocked;
  }, [isViewLocked]);

  const onDeleteNode = useCallback(
    (node) => {
      if (!node?.title) return;

      const actual = node.actualValue;
      if (node.title.startsWith("Well List")) {
        if (entityKind == EntityKind.Facility) {
          dispatch(removeFacilityWellListFilter(actual));
        } else {
          dispatch(removeWellListFilter(actual));
        }
        return;
      }

      if (node.children.length) {
        node.children.forEach((ch) => {
          if (entityKind == EntityKind.Facility) {
            dispatch(removeFacilityProperty(ch.actualValue));
          } else {
            dispatch(removeProperty(ch.actualValue));
          }
          if (onNodeRemoved) {
            onNodeRemoved(ch.actualValue);
          }
        });
        return;
      }

      if (actual.type === "Polygon" && actual.operator === "!=") {
        // Remove the exclude well polygon predicate.
        if (entityKind == EntityKind.Facility) {
          dispatch(removeFacilityExcludePolygon(actual));
        } else {
          dispatch(removeExcludePolygon(actual));
        }
      } else if (actual.property !== "Polygon") {
        if (entityKind == EntityKind.Facility) {
          dispatch(removeFacilityProperty(actual));
        } else {
          // when syncing with nearby wells, removing the selection filter
          // needs to unlock the map view lock
          if (actual.type == "Polygon" && isViewLockedRef.current) {
            dispatch(setViewLock(false));
          }
          dispatch(removeProperty(actual));
        }
        if (onNodeRemoved) {
          onNodeRemoved(actual);
        }
      }
    },
    [dispatch, onNodeRemoved]
  );

  return (
    <Wrapper className={className}>
      <PredicateTree
        entityKind={entityKind}
        predicates={predicates}
        onMouseDoubleClick={onMouseDoubleClick}
        onDeleteNode={onDeleteNode}
      />
    </Wrapper>
  );
};

export default memo(ActiveFilter);

const Wrapper = styled.div`
  margin: 0;
  overflow-y: auto;
`;
