import { useEffect, useState } from "react";

import styled from "styled-components";
import { removeOverlappingLabels } from "utils/removeOverlappingLabels";

import useTownshipRangeGrid, {
  RangeModel,
  TownshipModel
} from "api/useTownshipRangeGrid";

import { useScreenshotContext } from "components/screenshot/hooks";

import { useMapContext } from "./hooks/useMapContext";

export interface TownshipRangeGridModel {
  // eslint-disable-next-line no-unused-vars
  onVisibleChange?: (bool) => void;
  containerSize?: Size;
}

interface Size {
  width: number;
  height: number;
}
let zoomOffset = 10;
export default function TownshipRangeGrid({
  onVisibleChange,
  containerSize
}: TownshipRangeGridModel) {
  const { mapbox, bounds } = useMapContext();

  const [xOffset, setXOffset] = useState(0);
  const [yOffset, setYOffset] = useState(0);
  const [, setHasGridData] = useState(false);

  const [leftPositions, setLeftPositions] = useState<TownshipModel[]>([]);
  const [upPositions, setUpPositions] = useState<RangeModel[]>([]);
  const [showGrid, setShowGrid] = useState(false);
  const { data, isError, refetch } = useTownshipRangeGrid();
  const { settings } = useScreenshotContext();

  const labelMinimumDistance =
    containerSize && settings?.mapScreenshotScale
      ? 40 * settings?.mapScreenshotScale
      : 40;

  useEffect(() => {
    if (!mapbox) {
      return;
    }
    onVisibleChange && onVisibleChange(false);
    const zoom = mapbox.getZoom();
    setShowGrid(zoom >= 0);
    zoomOffset = Math.floor(zoom * 1.5);
    refetch();
    onVisibleChange && onVisibleChange(true);
  }, [mapbox, bounds, onVisibleChange, refetch]);

  useEffect(() => {
    if (!mapbox || !containerSize) {
      return;
    }
    setTimeout(() => {
      //timeout to give the map canvas some time to update its size
      const canvas = mapbox.getCanvas();
      const canvasWidth = canvas.width / window.devicePixelRatio;
      const canvasHeight = canvas.height / window.devicePixelRatio;
      const xOffset = (canvasWidth - containerSize.width) / 2;
      const yOffset = (canvasHeight - containerSize.height) / 2;
      setYOffset(yOffset);
      setXOffset(xOffset);
    }, 500);
  }, [containerSize, mapbox]);

  useEffect(() => {
    const hasData =
      data &&
      ((data.leftTownships && data.leftTownships.length > 0) ||
        (data.topRanges && data.topRanges.length > 0));
    setHasGridData(hasData);

    if (!hasData) {
      setLeftPositions([]);
      setUpPositions([]);
      return;
    }
    const removedOverlapLeftPositions = removeOverlappingLabels(
      getTownshipCoordinates(data.leftTownships),
      labelMinimumDistance / 4,
      "centerY"
    );
    const removedOverlapUpPositions = removeOverlappingLabels(
      getRangeCoordinates(data.topRanges),
      labelMinimumDistance,
      "centerX"
    );
    setLeftPositions(removedOverlapLeftPositions);
    setUpPositions(removedOverlapUpPositions);
  }, [data, labelMinimumDistance]);
  function getTownshipCoordinates(model: TownshipModel[]): TownshipModel[] {
    if (!mapbox) {
      return [];
    }
    const zoom = mapbox.getZoom();
    const canvas = mapbox.getCanvas();
    const maxHeight = canvas.height;
    const townshipData = model.map((twp) => {
      const loc = mapbox.project([twp.centerX, twp.centerY]);

      const twpModel: TownshipModel = {
        centerX: twp.centerX,
        centerY: loc.y,
        maxY: twp.maxY,
        minY: twp.minY,
        township: twp.township,
        townshipDir: twp.townshipDir,
        scaleFactor: twp.scaleFactor
      };
      return twpModel;
    });
    //zoomed in and out of bounds
    const allOutOfBounds =
      zoom > 10 &&
      townshipData.filter((item) => item.centerY < 30 || item.centerY > maxHeight)
        .length > 0;
    if (townshipData.length <= 2 && allOutOfBounds) {
      for (const item of townshipData) {
        const { y: minY } = mapbox.project([item.centerX, item.minY]);
        const { y: maxY } = mapbox.project([item.centerX, item.maxY]);

        const centerY = canvas.height / 2;
        const centerX = canvas.width / 2;

        const { lat } = mapbox.unproject([centerX, centerY]);
        if (lat > item.minY && lat < item.maxY) {
          item.centerY = centerY;
        }
        if (maxY < maxHeight && maxY > 0) {
          item.centerY = maxY + (maxHeight - maxY) / 2;
        } else if (minY > 0 && minY < maxHeight) {
          item.centerY = minY - minY / 2;
        }
      }
    }
    return townshipData;
  }

  function getRangeCoordinates(model: RangeModel[]) {
    if (!mapbox) {
      return;
    }
    const rangeData = model.map((twp) => {
      const loc = mapbox.project([twp.centerX, twp.centerY]);
      const rangeModel: RangeModel = {
        centerX: loc.x,
        centerY: twp.centerY,
        maxX: twp.maxX,
        minX: twp.minX,
        range: twp.range,
        mer: twp.mer,
        merDir: twp.merDir,
        scaleFactor: twp.scaleFactor
      };
      return rangeModel;
    });
    const zoom = mapbox.getZoom();
    const canvas = mapbox.getCanvas();
    const maxWidth = canvas.width;
    //zoomed in and out of bounds
    const allOutOfBounds =
      zoom > 0 &&
      rangeData.filter((item) => item.centerX < 30 || item.centerX > maxWidth).length > 0;
    if (rangeData.length <= 2 && allOutOfBounds) {
      for (const item of rangeData) {
        const { x: minX } = mapbox.project([item.minX, item.centerY]);
        const { x: maxX } = mapbox.project([item.maxX, item.centerY]);

        item.centerX = maxWidth / 2;
        if (maxX < maxWidth && maxX > 0) {
          item.centerX = maxX / 2;
        } else if (minX > 0 && minX < maxWidth) {
          item.centerX = minX + (maxWidth - minX) / 2;
        }
      }
    }
    return rangeData;
  }
  if (!showGrid || isError || !mapbox) {
    return null;
  }

  return (
    <RootContainer>
      <LeftTownship
        isScreenshot={containerSize}
        mapScreenshotScale={settings?.mapScreenshotScale}>
        {(leftPositions ?? []).map((twp, i) => {
          return (
            <TownshipLabel
              isScreenshot={containerSize}
              key={i}
              mapScreenshotScale={settings?.mapScreenshotScale}
              style={{ top: `${twp.centerY - yOffset - labelMinimumDistance}px` }}>
              T{twp.township}
              {twp.townshipDir}
            </TownshipLabel>
          );
        })}
      </LeftTownship>
      <UpRange
        isScreenshot={containerSize}
        mapScreenshotScale={settings?.mapScreenshotScale}>
        {(upPositions ?? []).map((twp, i) => {
          return (
            <RangeLabel
              isScreenshot={containerSize}
              key={i}
              mapScreenshotScale={settings?.mapScreenshotScale}
              style={{ left: `${twp.centerX - xOffset - zoomOffset}px` }}>
              R{twp.range}
              {twp.merDir}
              {twp.mer}
            </RangeLabel>
          );
        })}
      </UpRange>
    </RootContainer>
  );
}

const RootContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  user-select: none;
  font-family: var(--fontMono);
`;
const TownshipLabel = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 201;
  font-size: ${(props) =>
    props.isScreenshot ? props.mapScreenshotScale * 1.2 + "rem" : "1.1rem"};
  padding-top: 30px;
`;

const RangeLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 201;
  font-size: ${(props) =>
    props.isScreenshot ? props.mapScreenshotScale * 1.2 + "rem" : "1.2rem"};
  padding-left: 25px;
`;

const LeftTownship = styled.div`
  position: absolute;
  top: ${(props) => (props.isScreenshot ? props.mapScreenshotScale * 30 + "px" : "30px")};
  left: 0;
  width: ${(props) =>
    props.isScreenshot ? props.mapScreenshotScale * 35 + "px" : "35px"};
  overflow: hidden;
  background: #f5f5f5;
  bottom: 0;
  z-index: 200;
`;

const UpRange = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  left: 0;
  height: ${(props) =>
    props.isScreenshot ? props.mapScreenshotScale * 30 + "px" : "30px"};
  background: #f5f5f5;
  z-index: 200;
`;
