import axios from "axios";
import { Root } from "protobufjs";
import { IMapRequest } from "store/features";

import { TxnT, XdaConstraintT } from "models";
import { PdenSourceEnum } from "models/pdenDataSourceSetting";

import {
  EntitiesInGroup,
  EntitiesInGroupModel
} from "components/map/contexts/MapContextState";

import { BubbleOptionsT } from "../components/map/toolbar/MapVis";
import { Result } from "./dataSource";

const mapServiceEndpoint = process.env.REACT_APP_MAP_SERVICE;
const dataRoot = process.env.REACT_APP_DATA_ROOT;
const xdaRootUrl = process.env.REACT_APP_XDA_SERVICE;

export interface MapLegendItem {
  color: string;
  count: number;
  groupTitle: string;
  inFilter: boolean;
  title: string;
}

export interface IMapLegend {
  legendItems: MapLegendItem[] | unknown[];
  title: string;
}

export interface IBound {
  sw: number[];
  ne: number[];
}

export interface IMapResult {
  id: string;
  facilityId: string;
  bBox: IBound;
  legend: IMapLegend;
  facilityLegend: IMapLegend;
  error?: string;
  filterId?: string;
}

export const EMPTY_MAP_RESULT = {
  error: null,
  id: "",
  legend: { legendItems: [], title: "" },
  bbox: null,
  facilityLegend: { legendItems: [], title: "" },
  facilityId: "",
  bBox: {
    sw: [],
    ne: []
  }
} as IMapResult;

async function getTxnId(request: IMapRequest, cancelToken): Promise<IMapResult> {
  try {
    const response = await axios.post(`${mapServiceEndpoint}/map`, request, {
      cancelToken
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

export interface MapEntity {
  uwi: string;
  color: string;
  value: string;
  group: string;
  hasSurvey: boolean;
}

export const fetchDefaultSizesForXDA = async (uwis) => {
  const slicedUwis = uwis.slice(0, 120);

  const s = encodeURIComponent(btoa(slicedUwis.join(",")));
  const endpoint = `${xdaRootUrl}/default-size?uwis=${s}`;
  const response = await axios.get<XdaConstraintT>(endpoint);
  return response.data;
};

export const fetchRawMapEntities = async (txnId: string, wells: string[]) => {
  const endpoint = `${mapServiceEndpoint}/well/${txnId}/filter`;
  return await axios.post<MapEntity[]>(endpoint, wells);
};

export async function fetchMapEntities(
  txnId: string,
  wells: string[]
): Promise<Result<MapEntity[]>> {
  const endpoint = `${mapServiceEndpoint}/well/${txnId}/filter`;

  try {
    if (!(txnId && wells.length)) {
      return {
        ok: true,
        value: []
      };
    }
    const response = await axios.post<MapEntity[]>(endpoint, wells);

    if (response.status === 200) {
      return {
        ok: true,
        value: response.data
      };
    }

    return {
      ok: false,
      error: ""
    };
  } catch (err) {
    return {
      ok: false,
      error: err.response?.data ?? ""
    };
  }
}

function getGroupedPlayZonesByPeriod() {
  const url = `${mapServiceEndpoint}/ipdb/groupedPlayZones`;
  return axios.get(url, {});
}

function getIpdbZones(useOld, extent) {
  if (extent.type !== "Polygon") return null;

  const url = `${mapServiceEndpoint}/ipdb/zones/${useOld}`;

  return axios.get(url, {
    params: {
      json: extent
    }
  });
}

function getIpdb(modelSource, selectedIpdbZone, selectedIpdbField) {
  if (!selectedIpdbZone || !selectedIpdbField) return null;

  if (selectedIpdbZone) {
    const url = `${mapServiceEndpoint}/ipdb/minmax`;
    return axios.post(url, {
      zones: selectedIpdbZone,
      modelSource,
      field: selectedIpdbField,
      wkt: ""
    });
  }
  return null;
}

function getExtrusion(
  txnId: TxnT,
  showBubble: boolean,
  bubbleField: { property: string; title: string; pdenSource?: PdenSourceEnum }[],
  showExtrude: boolean,
  bubbleOptions: BubbleOptionsT
) {
  if (!txnId || !txnId.id || !txnId.filterId || bubbleField.length == 0) return null;
  const fields = bubbleField
    .map((field) => `bubbleField=${encodeURIComponent(field.property)}`)
    .join("&");
  const url = `${mapServiceEndpoint}/map-vis/bubble/${txnId.filterId}?txnId=${
    txnId.id
  }&bubble=${showBubble}&${fields}&extrude=${showExtrude}&extrudeField=${encodeURIComponent(
    bubbleField[0].property
  )}&maxBubbleRadius=${bubbleOptions.maxRadiusSizeMeters}&location=${
    bubbleOptions.location
  }&pdenSource=${bubbleField[0].pdenSource ?? PdenSourceEnum.Public}`;
  return axios.get(url);
}

function getIpdbValueExtrusion(
  mapExtent,
  selectedIpdbField,
  selectedIpdbZone,
  useOldIpdb
) {
  if (!selectedIpdbField || !selectedIpdbZone) return null;

  const request = {
    GeoJsonArea: JSON.stringify(mapExtent),
    Field: selectedIpdbField,
    Zones: [selectedIpdbZone],
    useOldIpdb: useOldIpdb
  };
  return axios.post(`${mapServiceEndpoint}/ipdb/value/geojson`, request);
}

function getCompletion(filterId, field, maxVal, length) {
  const completionsUrl = `${dataRoot}/api/v1/frac/completion/${filterId}?field=${field}&maxValue=${maxVal}&maxPerfLengthMeters=${length}`;
  return axios.get(completionsUrl);
}

function getPlay(playName) {
  const playsUrl = `${mapServiceEndpoint}/Location/play?name=${encodeURIComponent(
    playName
  )}`;
  return axios.get(playsUrl);
}

async function getTownshipByDls(values) {
  const locationUrl = `${mapServiceEndpoint}/Location/dls/${values.township}/${values.range}/${values.meridian}`;
  return axios.get(locationUrl);
}

async function getTownshipByNts(values) {
  const locationUrl = `${mapServiceEndpoint}/Location/nts/${values.block}/${values.sheet}/${values.mus}/${values.grid}`;
  return axios.get(locationUrl);
}

async function getPerpendicularLine(uniqueId) {
  try {
    const locationUrl = `${mapServiceEndpoint}/well/perpendicular-line/${uniqueId}`;
    const response = await axios.get(locationUrl);
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  } catch (error) {
    return null;
  }
}

export interface EntitiesOnMapResult {
  groupsMap: { [group: string]: EntitiesInGroup };
}

export async function fetchEntitiesOnMap(
  txnId: string
): Promise<{ [group: string]: EntitiesInGroupModel }> {
  if (!txnId || txnId === "clear") {
    return {};
  }
  const response = await axios.get(`${mapServiceEndpoint}/map/entities/${txnId}`, {
    responseType: "arraybuffer"
  });
  if (response.status !== 200) {
    throw new Error("unable to fetch entities on map.");
  }
  const data = new Uint8Array(response.data);
  const root = Root.fromJSON({
    nested: {
      EntitiesOnMapResult: {
        fields: {
          groupsMap: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            keyType: "string",
            type: ".EntitiesInGroup",
            id: 1
          }
        }
      },
      EntityGroup: {
        fields: {
          id: {
            type: "int32",
            id: 1
          },
          title: {
            type: "string",
            id: 2
          },
          color: {
            type: "string",
            id: 3
          }
        }
      },
      EntityDetail: {
        fields: {
          uwi: {
            type: "string",
            id: 1
          },
          groupId: {
            type: "int32",
            id: 2
          }
        }
      },
      EntitiesInGroup: {
        fields: {
          uwis: {
            rule: "repeated",
            type: "string",
            id: 1
          },
          group: {
            type: "EntityGroup",
            id: 2
          }
        }
      }
    }
  });
  const entitiesOnMap = root.lookupType("EntitiesOnMapResult");
  const message = entitiesOnMap.decode(data);
  const obj = entitiesOnMap.toObject(message) as EntitiesOnMapResult;
  if (!obj || !obj.groupsMap) {
    return {};
  }
  const result = {} as { [uwi: string]: EntitiesInGroupModel };
  for (const key of Object.keys(obj.groupsMap)) {
    const group = obj.groupsMap[key];
    const uwis = {};
    for (const uwi of group.uwis) {
      uwis[uwi] = group.group.color;
    }
    result[key] = {
      uwis,
      group: group.group
    } as EntitiesInGroupModel;
  }

  return result;
}

export {
  getTxnId,
  getIpdbZones,
  getIpdb,
  getIpdbValueExtrusion,
  getExtrusion,
  getCompletion,
  getPerpendicularLine,
  getPlay,
  getTownshipByDls,
  getTownshipByNts,
  getGroupedPlayZonesByPeriod
};
