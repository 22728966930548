import { ArpSegment } from "../../../models/UserArpsModel";
import addMonthsToDate from "../../vis/util/addMonthsToDate";
import { ProductTypeEnumAllCaps } from "../models/ArpsSegment";
import {
  ArpsSegmentToSegmentDto as arpsSegmentToSegmentDto,
  convertDecline,
  convertDeclineToNominal,
  getDiAtDay,
  getRampUpSegmentCumVolume
} from "./arpsUtils";
import {
  AVG_DAYS_IN_MONTH,
  getDurationInDays,
  numberOfMonthsBetweenDates
} from "./dates";

export let arpsWasm: typeof import("wasm/arps") = undefined;
import("wasm/arps.js").then((x) => {
  arpsWasm = x;
});

export interface UpdateSegment {
  (productArps: ArpSegment[], declineType: string, header: string, val): ArpSegment[];
}

function updateEndDates(segments: ArpSegment[]) {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }
  for (let i = 0; i < segments.length; i++) {
    const seg = segments[i];
    const startDate = new Date(Date.parse(seg.startDate));
    const day = arpsWasm.getDayAtRate(seg.qi, seg.di, seg.b, seg.qf);

    if (day === Infinity || isNaN(day)) {
      // This will be caught by the validation
      continue;
    }

    const endDate = new Date(Date.parse(seg.startDate));

    endDate.setDate(endDate.getDate() + day);
    if (endDate < startDate) {
      //something went wrong, just add a day to the start date
      endDate.setDate(startDate.getDate() + 1);
      seg.endDate = endDate.toISOString();
    } else {
      seg.endDate = endDate.toISOString();
    }
    if (i + 1 < segments.length) {
      const next = segments[i + 1];
      next.startDate = seg.endDate;
    }
  }
}

function updateTrendCum(segments: ArpSegment[]) {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }
  for (let i = 0; i < segments.length; i++) {
    const seg = segments[i];
    if (seg.qi <= seg.qf && seg.di < 0 && i == 0) {
      seg.trendCum = getRampUpSegmentCumVolume(seg);
    } else if (seg.endDate && seg.startDate) {
      seg.trendCum = arpsWasm.getSegmentVolume(
        seg.qi,
        seg.di,
        seg.b,
        seg.qf,
        BigInt(Math.round(new Date(seg.startDate).getTime() / 1000)),
        BigInt(Math.round(new Date(seg.endDate).getTime() / 1000))
      );
    } else {
      seg.trendCum = 0;
    }
  }
}

function updateSegmentsFromHeaderValues(
  header: string,
  segments: ArpSegment[],
  declineType: string,
  val
) {
  const hasRampUpSegment =
    segments.length > 2 && segments[0].qi <= segments[0].qf && segments[0].di < 0;
  const hasConstrainedSegment = segments.length === 4 && !hasRampUpSegment;

  const hasInitialSegment = hasRampUpSegment || hasConstrainedSegment;

  const seg0 = hasInitialSegment ? segments[0] : null;
  const seg1 = hasInitialSegment ? segments[1] : segments[0];
  const seg2 = hasInitialSegment ? segments[2] : segments[1];
  const seg3 = hasInitialSegment ? segments[3] : segments[2];
  const is2Segments =
    (!hasInitialSegment && segments.length === 2) ||
    (hasInitialSegment && segments.length === 3);

  if (header === "Q0") {
    seg0.qi = val;

    if (seg0.qf < val && hasRampUpSegment) {
      seg0.qf = val;
      seg1.qi = val;
    } else if (seg0.qf > val && hasConstrainedSegment) {
      seg0.qf = val;
      seg1.qi = val;
    }
  }

  if (header === "Bcons") {
    seg0.b = val;
    seg0.di = getDiAtDay(
      seg0.qi,
      seg0.qf,
      val,
      getDurationInDays(seg0.startDate, seg0.endDate)
    );
  }

  if (header === "tCons") {
    const endDate = new Date(seg0.startDate.split("Z")[0]);
    endDate.setDate(endDate.getDate() + val * AVG_DAYS_IN_MONTH);
    seg0.endDate = endDate.toISOString();
    seg1.startDate = seg0.endDate;

    seg0.di = getDiAtDay(seg0.qi, seg0.qf, seg0.b, val * AVG_DAYS_IN_MONTH);
  }

  if (header === "tRamp") {
    let endDate = new Date(seg0.startDate);
    endDate = addMonthsToDate(endDate, val);
    seg0.endDate = endDate.toISOString();
    seg1.startDate = seg0.endDate;
  }

  if (header == "tTrans") {
    let endDate = new Date(seg1.startDate);
    endDate = addMonthsToDate(endDate, val);
    seg1.endDate = endDate.toISOString();
  }

  if (header === "Qi") {
    if (seg0) {
      seg0.qf = val;

      if (seg0.qi > val && hasRampUpSegment) {
        seg0.qi = val;
      } else if (seg0.qi < val && hasConstrainedSegment) {
        seg0.qi = val;
      }
    }

    seg1.qi = val;
  }

  if (header.includes("Di")) {
    seg1.di = convertDeclineToNominal(val, seg1.b, declineType);
  }

  if (header.includes("Df")) {
    if (seg3) {
      seg2.df = convertDeclineToNominal(val, seg2.b, declineType);
    } else {
      seg1.df = convertDeclineToNominal(val, seg1.b, declineType);
    }
  }

  if (header === "Start Date") {
    const date = val as Date;
    if (seg0) {
      // Update start date for initial segment will also affect first segment start date
      const duration = getDurationInDays(seg0.startDate, seg0.endDate);

      seg0.startDate = date.toISOString();

      const endDate = new Date(seg0.startDate.split("Z")[0]);
      endDate.setDate(endDate.getDate() + duration);
      seg0.endDate = endDate.toISOString();

      seg1.startDate = seg0.endDate;
    } else {
      seg1.startDate = date.toISOString();
    }
  }

  if (header === "B" || header === "Btrans") {
    if (declineType === "Secant") {
      seg1.di = convertDeclineToNominal(
        convertDecline(seg1.di, seg1.b, declineType),
        val,
        declineType
      );
      seg1.df = convertDeclineToNominal(
        convertDecline(seg1.df, seg1.b, declineType),
        val,
        declineType
      );
    }
    seg1.b = val;
  }

  if (header === "Bhyp") {
    const updatingSeg = is2Segments ? seg1 : seg2;
    if (declineType === "Secant") {
      updatingSeg.di = convertDeclineToNominal(
        convertDecline(updatingSeg.di, updatingSeg.b, declineType),
        val,
        declineType
      );
      updatingSeg.df = convertDeclineToNominal(
        convertDecline(updatingSeg.df, updatingSeg.b, declineType),
        val,
        declineType
      );
    }
    updatingSeg.b = val;
  }

  if (header === "Bf") {
    const updatingSeg = is2Segments ? seg2 : seg3;
    updatingSeg.b = val;
  }

  if (header === "Qf") {
    const lastSeg = segments[segments.length - 1];
    lastSeg.qf = val;
  }

  if (header === "EUR") {
    let initialSegmentVol = 0;
    let includedSegments = segments;

    // We should manually calculate the volume of the initial segment
    // These volume shouldn't interfere the EUR calculation
    if (hasRampUpSegment) {
      initialSegmentVol = getRampUpSegmentCumVolume(seg0);

      // remove the initial segment from the included segments
      includedSegments = includedSegments.slice(1);
    } else if (hasConstrainedSegment) {
      const startDate_unixTimestamp_seconds = Math.round(
        new Date(seg0.startDate).getTime() / 1000
      );
      const endDate_unixTimestamp_seconds = Math.round(
        new Date(seg0.endDate).getTime() / 1000
      );
      initialSegmentVol = arpsWasm.getSegmentVolume(
        seg0.qi,
        seg0.di,
        seg0.b,
        seg0.qf,
        BigInt(startDate_unixTimestamp_seconds),
        BigInt(endDate_unixTimestamp_seconds)
      );

      // remove the initial segment from the included segments
      includedSegments = includedSegments.slice(1);
    }

    seg1.switchMonth = numberOfMonthsBetweenDates(
      new Date(seg1.startDate),
      new Date(seg1.endDate)
    );

    const di = arpsWasm.getDiFromCum(
      includedSegments.map(arpsWasm.arpSegmentToSegmentDto),
      // Vol should be whatever the user input subtract the initial Segment Vol
      val * 1000.0 - initialSegmentVol,
      seg1.switchMonth
    );

    if (di > 0) {
      seg1.di = di;
    } else {
      seg1.di = null;
    }
  }
}

export const updateOneSegmentTerminalDecline: UpdateSegment = (
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }
  const clone = productArps.map((item) => ({ ...item }));
  const seg1 = clone[0];
  if (clone.length !== 1) {
    throw "The selected typewell has an invalid number of segments. Please verify and try again.";
  }
  updateSegmentsFromHeaderValues(header, clone, declineType, val);
  seg1.df = arpsWasm.getNominalDeclineAtRate(seg1.di, seg1.qi, seg1.b, seg1.qf);
  // If qf not manually changed then update qf value (if di or n changed then qf recalculates)
  if (header !== "Qf") {
    const productNames = productArps.map((a) => a.product);
    const productEnums = productNames ? ProductTypeEnumAllCaps[productNames[0]] : 0;
    if (productEnums !== 0) {
      const dto = arpsSegmentToSegmentDto(arpsWasm, [seg1]);
      const productEurs = arpsWasm.getProductsEurFromSegments(dto, [productEnums]);
      if (productEurs.length > 0) seg1.qf = productEurs[0].qf;
    } else {
      throw "invalid product";
    }
  }
  updateEndDates(clone);
  updateTrendCum(clone);
  return clone;
};

export const updateTwoSegmentTerminalDecline: UpdateSegment = (
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }

  const clone = productArps.map((item) => ({ ...item }));

  if (clone.length !== 2) {
    throw "The selected typewell has an invalid number of segments. Please verify and try again.";
  }

  const seg1 = clone[0];
  const seg2 = clone[1];
  updateSegmentsFromHeaderValues(header, clone, declineType, val);
  if (seg1.qi >= seg1.qf) {
    const qf = arpsWasm.getRateAtNominal(seg1.di, seg1.df, seg1.qi, seg1.b);
    if (seg1.qi < qf) {
      seg1.qf = seg1.qi;
    } else {
      seg1.qf = qf;
    }
    if (seg1.qf < seg2.qf) {
      seg1.qf = seg2.qf;
      //recalculate df since qf limit hits first
      seg1.df = arpsWasm.getNominalDeclineAtRate(seg1.di, seg1.qi, seg1.b, seg1.qf);
    }
    seg2.qi = seg1.qf;
    seg2.di = seg1.df;
    seg2.df = seg2.di;
  }
  updateEndDates(clone);
  updateTrendCum(clone);

  return clone;
};

export const updateTwoSegmentTerminalDeclineWithRampUp: UpdateSegment = (
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }

  const clone = productArps.map((item) => ({ ...item }));

  if (clone.length !== 3) {
    throw "The selected typewell has an invalid number of segments. Please verify and try again.";
  }

  // Ignore segment 1, this is ramp-up segment
  // const seg1 = clone[0];
  const seg2 = clone[1];
  const seg3 = clone[2];
  updateSegmentsFromHeaderValues(header, clone, declineType, val);
  if (seg2.qi >= seg2.qf) {
    seg2.qf = arpsWasm.getRateAtNominal(seg2.di, seg2.df, seg2.qi, seg2.b);
    if (seg2.qf < seg3.qf) {
      seg2.qf = seg3.qf;
      //recalculate df since qf limit hits first
      seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);
    }
    seg3.qi = seg2.qf;
    seg3.di = seg2.df;
    seg3.df = seg3.di;
  }
  updateEndDates([seg2, seg3]);
  updateTrendCum(clone);
  return clone;
};

export const updateThreeSegmentTerminalDecline: UpdateSegment = (
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }
  const clone = productArps.map((item) => ({ ...item }));

  if (clone.length !== 3) {
    throw "The selected typewell has an invalid number of segments. Please verify and try again.";
  }
  const seg1 = clone[0];
  const seg2 = clone[1];
  const seg3 = clone[2];
  updateSegmentsFromHeaderValues(header, clone, declineType, val);
  const switchMonth = numberOfMonthsBetweenDates(
    new Date(seg1.startDate),
    new Date(seg1.endDate)
  );
  seg1.qf = arpsWasm.getRateAtDay(
    seg1.qi,
    seg1.di,
    seg1.b,
    switchMonth * AVG_DAYS_IN_MONTH
  );
  seg1.df = arpsWasm.getNominalDeclineAtRate(seg1.di, seg1.qi, seg1.b, seg1.qf);
  if (seg1.qf < seg2.qf) {
    seg1.qf = seg2.qf;
    //recalculate df since qf limit hits first
    seg1.df = arpsWasm.getNominalDeclineAtRate(seg1.di, seg1.qi, seg1.b, seg1.qf);
  }
  seg2.qi = seg1.qf;
  seg2.di = seg1.df;
  seg2.qf = arpsWasm.getRateAtNominal(seg2.di, seg2.df, seg2.qi, seg2.b);

  if (seg2.qf < seg3.qf) {
    seg2.qf = seg3.qf;
    //recalculate df since qf limit hits first
    seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);
  }
  seg3.qi = seg2.qf;
  seg3.di = seg2.df;
  seg3.df = seg2.df;
  updateEndDates(clone);
  updateTrendCum(clone);
  return clone;
};

export const updateThreeSegmentTerminalDeclineWithRampUp: UpdateSegment = (
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }
  const clone = productArps.map((item) => ({ ...item }));

  if (clone.length !== 4) {
    throw "The selected typewell has an invalid number of segments. Please verify and try again.";
  }
  // Ignore segment 1, this is ramp-up segment
  // const seg1 = clone[0];
  const seg2 = clone[1];
  const seg3 = clone[2];
  const seg4 = clone[3];
  updateSegmentsFromHeaderValues(header, clone, declineType, val);
  const switchMonth = numberOfMonthsBetweenDates(
    new Date(seg2.startDate),
    new Date(seg2.endDate)
  );
  seg2.qf = arpsWasm.getRateAtDay(
    seg2.qi,
    seg2.di,
    seg2.b,
    switchMonth * AVG_DAYS_IN_MONTH
  );
  seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);
  if (seg2.qf < seg3.qf) {
    seg2.qf = seg3.qf;
    //recalculate df since qf limit hits first
    seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);
  }
  seg3.qi = seg2.qf;
  seg3.di = seg2.df;
  seg3.qf = arpsWasm.getRateAtNominal(seg3.di, seg3.df, seg3.qi, seg3.b);

  if (seg3.qf < seg4.qf) {
    seg3.qf = seg4.qf;
    //recalculate df since qf limit hits first
    seg3.df = arpsWasm.getNominalDeclineAtRate(seg3.di, seg3.qi, seg3.b, seg3.qf);
  }
  seg4.qi = seg3.qf;
  seg4.di = seg3.df;
  seg4.df = seg3.df;
  updateEndDates([seg2, seg3, seg4]);
  updateTrendCum(clone);
  return clone;
};

export const updateThreeSegmentTerminalDeclineWithConstrainedPeriod: UpdateSegment = (
  productArps: ArpSegment[],
  declineType: string,
  header: string,
  val
): ArpSegment[] => {
  if (!arpsWasm) {
    throw "arpsWasm is undefined";
  }
  const clone = productArps.map((item) => ({ ...item }));

  if (clone.length !== 4) {
    throw "The selected typewell has an invalid number of segments. Please verify and try again.";
  }
  const seg2 = clone[1];
  const seg3 = clone[2];
  const seg4 = clone[3];
  updateSegmentsFromHeaderValues(header, clone, declineType, val);
  const switchMonth = numberOfMonthsBetweenDates(
    new Date(seg2.startDate),
    new Date(seg2.endDate)
  );
  seg2.qf = arpsWasm.getRateAtDay(
    seg2.qi,
    seg2.di,
    seg2.b,
    switchMonth * AVG_DAYS_IN_MONTH
  );
  seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);
  if (seg2.qf < seg3.qf) {
    seg2.qf = seg3.qf;
    //recalculate df since qf limit hits first
    seg2.df = arpsWasm.getNominalDeclineAtRate(seg2.di, seg2.qi, seg2.b, seg2.qf);
  }
  seg3.qi = seg2.qf;
  seg3.di = seg2.df;
  seg3.qf = arpsWasm.getRateAtNominal(seg3.di, seg3.df, seg3.qi, seg3.b);

  if (seg3.qf < seg4.qf) {
    seg3.qf = seg4.qf;
    //recalculate df since qf limit hits first
    seg3.df = arpsWasm.getNominalDeclineAtRate(seg3.di, seg3.qi, seg3.b, seg3.qf);
  }
  seg4.qi = seg3.qf;
  seg4.di = seg3.df;
  seg4.df = seg3.df;
  updateEndDates([seg2, seg3, seg4]);
  updateTrendCum(clone);
  return clone;
};
