import { useMemo } from "react";
import { useSelector } from "react-redux";

import { ForecastActivityType, TYPE_WELLS } from "constants/settings.constants";
import { RootState } from "store/rootReducer";
import { transformFolderToNestedTreeData } from "utils/arps/treeDataHelper";

import { useForecastContext } from "../context";

export const useTreeData = (type: ForecastActivityType) => {
  const filteredForecastFolders = useSelector(
    (state: RootState) => state.arps.filteredForecastFolders
  );
  const filteredTypeWellFolders = useSelector(
    (state: RootState) => state.arps.filteredTypeWellFolders
  );
  const { temporaryForecastFolderList, isFiltering } = useForecastContext();
  const filteredFolders =
    type === TYPE_WELLS ? filteredTypeWellFolders : filteredForecastFolders;

  const treeData = useMemo(() => {
    const combinedData = isFiltering
      ? filteredFolders
      : [...filteredFolders, ...temporaryForecastFolderList];

    const filteredData =
      type === TYPE_WELLS ? transformFolderToNestedTreeData(combinedData) : combinedData;

    return filteredData;
  }, [type, filteredFolders, temporaryForecastFolderList, isFiltering]);

  return {
    treeData
  };
};
