import { useProjectContext } from "../context";
import { useProjectListQuery } from "../queries";

export function useSelectedProject() {
  const { selectedProjectId } = useProjectContext();

  // (Layer list, and forecasts) Each row uses this hook, and as each row is added to the virtual list on scroll,
  // it will create a new query and refetches on mount which is not ideal, a stale time will help reduce frequency,
  // while not having to worry about the data being out of date. Our project list query is also used all over the app
  // with auto refetching, so the chance of cached data being out of date is low.
  const projectListQuery = useProjectListQuery({
    staleTime: 20000
  });

  const selectedProject = projectListQuery.data?.find(
    (project) => project.projectId === selectedProjectId
  );

  return { selectedProject };
}
