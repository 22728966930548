import { useForecastDispatch } from "../context";

export function useCancelForecastFolderList() {
  const forecastDispatch = useForecastDispatch();

  function cancel() {
    forecastDispatch({
      payload: {
        forecastFolderNameFieldMap: {},
        temporaryForecastFolderList: [],
        nameChangeError: null,
        nameChangeErrorId: null
      }
    });
  }
  return cancel;
}
