import { useContext } from "react";

import { ForecastContext } from "./ForecastContext";

export function useForecastContext() {
  const context = useContext(ForecastContext);
  if (context === undefined) {
    throw new Error("useForecastContext must be within a Forecast provider");
  }

  return context;
}
