import { FunctionComponent, useRef } from "react";

import classnames from "classnames";
import { ALL_CHART_TYPES } from "constants/chart.constants";
import styled from "styled-components/macro";

import useBetaFeatures from "hooks/useBetaFeatures";

import { useWellList } from "components/well-list/context";

import { visibilityToggleStyles } from "../shared/SharedStyles";
import ChartHeader from "./ChartHeader";
import {
  AverageSeriesToggle,
  DataSourceToggle,
  DevDebugToggle,
  ForecastToggle,
  FullscreenToggle,
  HistogramToggle,
  JitterPlotToggle,
  LassoBrush,
  LegendToggle,
  NormalizeToggle,
  OverflowToggle,
  Popout,
  ProducingToggle,
  ScreenshotToggle,
  SettingsToggle,
  SlopeLinesToggle,
  SpaghettiToggle,
  TimeStepToggle,
  TypewellsToggle
} from "./components";
import AddReferenceLineToggle from "./components/AddReferenceLineToggle";
import AnnotationToggle from "./components/AnnotationToggle";
import ProbitDataSourceToggle from "./components/ProbitDataSourceToggle";
import FocusToggle from "./components/focus/FocusToggle";
import { availableChartCapabilities, useChartState } from "./context";
import { useGlobalNormalizeBy, useOverflowIndex } from "./hooks";

const Divider = styled.div`
  width: 1px;
  height: var(--chart-toolbar-height);
  background-color: #d9e1e2;
`;

export const components = [
  <AnnotationToggle key="annotation" />,
  <FocusToggle key="focus" />,
  <DataSourceToggle key="source" />,
  <TimeStepToggle key="timestep" />,
  <NormalizeToggle key="normalize" />,
  <Divider key="globalSettingsDivider" />,
  <DevDebugToggle key="debugMode" />,
  <SettingsToggle key="settings" />,
  <JitterPlotToggle key="jitterPlotToggle" />,
  <ProbitDataSourceToggle key="probitDataSource" />,
  <HistogramToggle key="histogramToggle" />,
  <LassoBrush key="lasso" />,
  <ProducingToggle key="producing" />,
  <ForecastToggle key="forecast" />,
  <AverageSeriesToggle key="average-series" />,
  <SlopeLinesToggle key="slopeLines" />,
  <TypewellsToggle key="typewells" />,
  <ScreenshotToggle key="screenshot" />,
  <LegendToggle key="legend" />,
  <SpaghettiToggle key="individual" />,
  <AddReferenceLineToggle key="referenceLines" />,
  <Popout key="popout" />,
  <FullscreenToggle key="fullscreen" />
];

type ChartToolbarT = {
  onChartTypeChanged?: (t: string) => void;
  onToggleDataTable?: (visible: boolean) => void;
  canShowDataTable?: boolean;
  dataTableVisible?: boolean;
};

// TODO: optimization: use portal to outportal overflowing components
const ChartToolbar: FunctionComponent<ChartToolbarT> = ({
  onChartTypeChanged = null,
  onToggleDataTable = null,
  canShowDataTable = false,
  dataTableVisible = false
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { hasFeature } = useBetaFeatures();
  useGlobalNormalizeBy();
  useOverflowIndex(ref);
  const [state] = useWellList();

  const { mouseover, overflowIndex, screenshot, chartCapabilities, settings } =
    useChartState();

  const availableComponents = components.filter((c) => {
    // if the component is not in the availableChartCapabilities, it should always be shown
    if (!availableChartCapabilities.includes(c.key as string)) {
      return true;
    }
    return chartCapabilities.findIndex((capability) => capability.key === c.key) >= 0;
  });

  // if the last item is the only item in the overflow menu, just show it in the toolbar
  let visibleComponents = availableComponents
    .slice(0, overflowIndex === components.length - 1 ? overflowIndex + 1 : overflowIndex)
    .filter(
      (c) =>
        !(
          (c.key === "referenceLines" && !hasFeature("Chart Reference Lines")) ||
          (c.key === "slopeLines" &&
            settings.chartType !== ALL_CHART_TYPES.MaterialBalanceTime.label) ||
          (c.key === "annotation" &&
            (settings.chartType !== ALL_CHART_TYPES.RateDate.label ||
              state.wells.length !== 1))
        )
    );
  if (visibleComponents[visibleComponents.length - 1]?.key === "globalSettingsDivider") {
    visibleComponents = visibleComponents.slice(0, visibleComponents.length - 1); // remove divider if it is the last element
  }

  const visible = screenshot.visible || mouseover;
  const wrapperClassnames = classnames({ visible });

  return (
    <Wrapper ref={ref} className={wrapperClassnames}>
      <ChartHeader
        onChange={onChartTypeChanged}
        onToggleDataTable={onToggleDataTable}
        canShowDataTable={canShowDataTable}
        dataTableVisible={dataTableVisible}
      />

      {/* visible toolbar icons */}
      {visibleComponents}

      {/* more-toggle for overflowing toolbar icons */}
      <OverflowToggle />
    </Wrapper>
  );
};

export default ChartToolbar;

const Wrapper = styled.div`
  ${visibilityToggleStyles};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--chart-toolbar-height);
  display: flex;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 14px 16px 0 rgb(45 53 63 / 10%), inset 0 7px 8px -10px rgb(45 53 63 / 15%);
  z-index: 10;
`;
