/**
 * Get the scaling for data values of Cum Time (default unit bbl, bcf) based off Y axis title
 *
 * @param {string} title Y axis title of chart
 * @returns {number} the number to scale data with
 */
export const scaleDataBasedOnYAxisUnits = (title: string): number => {
  const unitToScale: { [key: string]: number } = {
    bcf: 1e-6,
    mmbbl: 1e-6,
    mmboe: 1e-6,
    mbbl: 1e-3,
    mboe: 1e-3,
    mmcf: 1e-3,
    scf: 1000
  };
  const unit = title.match(/\(([^)]+)\)/);

  const titleUnit = (unit?.length > 1 ? unit[1] : "").toLowerCase();

  const scale = Object.keys(unitToScale).find((unit) => titleUnit == unit);
  return scale ? unitToScale[scale] : 1;
};
