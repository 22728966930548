import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Select, Slider } from "antd";
import { RADIUS_DIMENSION_MAX } from "constants/visualization.constants";
import { ISelectedWell } from "store/features";
import { RootState } from "store/rootReducer";

import { XdaData } from "models/xdaData";

import getGeoMapLayers from "components/geo-map/hooks/getGeoMapLayers";
import { useUserSettings } from "components/user/hooks";

import { updateXDASettings, useVisState } from "../../context";
import { XDASettingsT } from "../../context/types";
import { XdaOptionItem, XdaOptionLabel, XdaOptionsWrapper } from "./XdaOptionsLayout";

interface XdaSettingsOptionsProps {
  dataRef: React.RefObject<XdaData>;
  isLoading: boolean;
}
const XdaSettingsOptions = ({ dataRef, isLoading }: XdaSettingsOptionsProps) => {
  const [{ xda }, visDispatch] = useVisState();
  const { topsModelSource, widthScaled } = xda.settings;

  const selectedWells: { [name: string]: ISelectedWell } = useSelector(
    (state: RootState) => state.map.selectedWells
  );
  const wells = Object.keys(selectedWells) || [];
  const padScenario = useSelector((state: RootState) => state.app.padScenario);
  const noWellsAndSticks =
    !wells.length && (!padScenario || padScenario?.sticks?.length == 0);

  const userSettings = useUserSettings();

  const { data: mapLayers } = getGeoMapLayers();

  const mcdanielGroup = [
    {
      label: "McDaniel",
      options: [{ label: "McDaniel", value: "McDaniel" }]
    }
  ];
  const [topsModelSources, setTopsModelSources] = useState(mcdanielGroup);
  const [localSelectedPlays, setLocalSelectedPlays] = useState([]);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (dataRef?.current?.available_plays && localSelectedPlays.length > 0) {
      // filter out localSelectedPlays that are not in available_plays
      const filteredLocalSelectedPlays = localSelectedPlays.filter((item) =>
        dataRef.current.available_plays.includes(item)
      );
      setLocalSelectedPlays(filteredLocalSelectedPlays);
      // if none of localSelectedPlays are in available_plays,
      // set selectedPlays to empty array to refresh xda
      if (filteredLocalSelectedPlays.length === 0) {
        const nextSettings = {
          ...xda.settings,
          selectedPlays: []
        };
        updateXDASettings(visDispatch, nextSettings);
      }
    }
  }, [dataRef?.current?.available_plays]);

  useEffect(() => {
    if (!userSettings?.geoModelSettings?.organizationEnabled) return;
    const orgGroups = {
      label: "Organization",
      options: []
    };
    if (mapLayers?.length > 0) {
      for (const item of mapLayers) {
        if (orgGroups.options.findIndex((o) => o.value === item.group) === -1) {
          orgGroups.options.push({ label: item.group, value: item.group });
        }
      }
    }
    setTopsModelSources([...mcdanielGroup, orgGroups]);
  }, [mapLayers, userSettings?.geoModelSettings?.organizationEnabled]);

  // sync option changes with context
  const handleSettingChange = (key: keyof XDASettingsT) => (value) => {
    let updatedValue = value;
    if (key === "scaleByValue") {
      //Convert value to 32-bit unsigned integer if handlesSettingChange is wrapped in inputWrapper. Math.min to ensure does not go above max if input is inputted via typing
      updatedValue = Math.min(RADIUS_DIMENSION_MAX, value * 1);
    }
    if (key !== "selectedPlays") {
      const nextSettings = { ...xda.settings, [key]: updatedValue };
      updateXDASettings(visDispatch, nextSettings);
    } else {
      setLocalSelectedPlays(value);
      // handles when user presses x on selected play without clicking into the input
      if (!isFocus && value) {
        const nextSettings = {
          ...xda.settings,
          selectedPlays: value
        };
        updateXDASettings(visDispatch, nextSettings);
      }
    }
  };

  // Only update selected plays when user clicks out of the input
  const handleSettingBlur = () => {
    setIsFocus(false);
    if (localSelectedPlays) {
      const nextSettings = {
        ...xda.settings,
        selectedPlays: localSelectedPlays
      };
      updateXDASettings(visDispatch, nextSettings);
    }
  };

  return (
    <XdaOptionsWrapper>
      <XdaOptionItem>
        <XdaOptionLabel>Scale Width</XdaOptionLabel>
        <Slider
          min={1}
          max={10}
          value={widthScaled}
          onChange={handleSettingChange("widthScaled")}
        />
      </XdaOptionItem>
      <XdaOptionItem>
        <XdaOptionLabel>Surfaces Source</XdaOptionLabel>
        <Select
          mode="multiple"
          size="small"
          defaultValue={["McDaniel"]}
          value={topsModelSource}
          options={topsModelSources}
          onChange={handleSettingChange("topsModelSource")}
        />
      </XdaOptionItem>
      {noWellsAndSticks && (
        <XdaOptionItem>
          <XdaOptionLabel>Selected Plays</XdaOptionLabel>
          <Select
            mode="multiple"
            size="small"
            value={localSelectedPlays?.map((item) => ({
              label: item,
              value: item
            }))}
            options={dataRef?.current?.available_plays?.map((item) => ({
              label: item,
              value: item
            }))}
            onChange={handleSettingChange("selectedPlays")}
            onBlur={() => handleSettingBlur()}
            onFocus={() => setIsFocus(true)}
            disabled={isLoading}
          />
        </XdaOptionItem>
      )}
    </XdaOptionsWrapper>
  );
};

export default XdaSettingsOptions;
