// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import DataGrid, { SortableHeaderCell } from "react-data-grid";
import { useDispatch, useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";

import { mdiContentCopy } from "@mdi/js";
import { Alert, Button, Popover } from "antd";
import classnames from "classnames";
import { MCDANIEL_SEARCH_FOLDER_NAME } from "constants/settings.constants";
import { dequal } from "dequal";
import { getCategoryList, setSelectedFacilities, setSelectedWells } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";
import { getColumnPropertyTree } from "utils/data-table";

import { IColumnSet, IColumns, IGroupColumn, IPropertyColumnMap } from "models/columns";
import { PdenSourceEnum } from "models/pdenDataSourceSetting";

import { Tooltip } from "components/base";
import ConfirmDialog from "components/chart/ConfirmDialog";
import { useDashboardDispatch } from "components/dashboard/hooks/useDashboardDispatch";
import { GroupByProvider } from "components/groupBy/group-by.context";

import { useUser } from "../../hooks";
import { EntityKind } from "../../models/entityKind";
import {
  DataTableWidgetKey,
  MidstreamDataTableWidgetKey
} from "../dashboard/constants/widgets.constants";
import { useUserSettings } from "../user/hooks";
import BusyIndicator from "./BusyIndicator";
import "./DataTable.scss";
import DataTableActions, { DataSourceSetting } from "./DataTableActions";
import DataTableColumnChooser from "./DataTableColumnChooser";
import Pagination from "./Pagination";
import type { SortDirectionT } from "./context";
import {
  changePageSize,
  changeSortColumn,
  changeSortDirection,
  turnPaginationOff,
  turnPaginationOn,
  useTable
} from "./context";
import { useDataQuery, useEntitiesOnMap, useOverflowIndex, useRows } from "./hooks";
import type { SortColumn } from "./types";
import { HeaderRendererProps } from "./types";

// for light theme
const rdgThemeClassname = "rdg-light";
const paginationHeight = 40;
// maximum cell count before pagination
const MAX_CELL_COUNT = 10000000;

type DataTableT = {
  id: string;
  initialState: { selectedProperties: string[]; pdenSource: PdenSourceEnum };
};
const DataTable: FunctionComponent<DataTableT> = ({ id, initialState }) => {
  const matchedUWIs = [];

  // context and hooks
  const dispatch = useDispatch();
  const dashboardDispatch = useDashboardDispatch();
  const { hasTrialAccount, isReadonly } = useUser();

  useEntitiesOnMap(); // sync uwi/color hash with entities on Map
  const rows = useRows(); // parse rows from context state
  const [{ pagination, toolbarHeight, uwis, entityKind }, tableDispatch] = useTable();

  const sessionKey = `${
    entityKind === EntityKind.Facility ? MidstreamDataTableWidgetKey : DataTableWidgetKey
  }::${id}`;
  const toolbarRef = useRef<HTMLDivElement>(null);
  useOverflowIndex(toolbarRef);

  // state from store
  const categories = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.groupBy.categoryList
      : state.groupBy.facilityFields
  );
  const selectedWellsOnMap = useSelector((state: RootState) => state.map.selectedWells);
  const groupBy = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.groupBy.globalGroupBy
      : state.groupBy.globalFacilityFocus
  );

  const pdenDataSourceSetting = useSelector(
    (state: RootState) => state.app.pdenDataSourceSetting
  );

  // state from store
  const filterCounts = useSelector((state: RootState) =>
    entityKind == EntityKind.Well
      ? state.filter.filterCounts
      : state.facilityFilter.filterCounts
  );
  // derive total results from filterCounts state
  const totalResults = filterCounts.Active ?? filterCounts.UniqueId ?? 0;

  // state
  const [columns, setColumns] = useState([]);
  const [dataSources, setDataSources] = useState<DataSourceSetting>({
    forecastSource: MCDANIEL_SEARCH_FOLDER_NAME,
    pdenSource: PdenSourceEnum.Public,
    rescat: "P+PDP"
  });
  const [lastSettings, setLastSettings] = useState<string>();
  const [propertyTree, setPropertyTree] = useState<IGroupColumn[]>([]);
  const [propertyMap, setPropertyMap] = useState<IPropertyColumnMap>({});
  const [selectedProperties, setSelectedProperties] = useState<string[]>();
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [sortColumns, setSortColumns] = useState<readonly Readonly<SortColumn>[]>([]);
  const [hasToConfirmPagination, setHasToConfirmPagination] = useState(false);

  const [showCopySuccessMessage, setShowCopySuccessMessage] = useState<{
    [key: string]: boolean;
  }>({});

  // fetches data from API
  const { isFetching, refetch, errorMessage } = useDataQuery(
    selectedProperties,
    dataSources
  );

  const activeEntities = useSelector((state: RootState) => state.app.activeEntityKinds);
  const userSettings = useUserSettings();
  const isMidstreamDataSourceEnabled = userSettings?.midstreamSettings?.enabled;
  const isWell = entityKind === EntityKind.Well;
  const isFacility = entityKind === EntityKind.Facility;

  const hiddenMessage = useMemo(() => {
    if (isWell && !activeEntities.includes(EntityKind.Well)) {
      return "Wells are hidden";
    }
    if (isFacility && !activeEntities.includes(EntityKind.Facility)) {
      return isMidstreamDataSourceEnabled
        ? "Midstream is hidden"
        : "Midstream is disabled";
    }
    return null;
  }, [activeEntities, isMidstreamDataSourceEnabled]);

  // sync selected properties from initial state
  useEffect(() => {
    if (!initialState?.selectedProperties) return;
    setSelectedProperties(initialState.selectedProperties);
    setDataSources(() => ({
      ...dataSources,
      pdenSource: PdenSourceEnum[pdenDataSourceSetting.source]
    }));
  }, [initialState]);

  // sync columns
  useEffect(() => {
    if (!selectedProperties) return;

    const nextColumns = selectedProperties.reduce(
      (list: IColumns[], propertyKey, index) => {
        const property = propertyMap[propertyKey];
        if (property) {
          if (
            property.property === "Header.EntityName" ||
            property.property === "Header.FacilityId"
          ) {
            list.push({
              key: 0,
              name: property.title,
              width: 230,
              resizable: true,
              sortable: true,
              color: property.hexColor,
              dataType: property.dataType,
              sortOrder: property.sortOrder,
              frozen: true,
              formatter: ({ row }) => getEntityNameCell(row)
            });
          } else {
            list.push({
              key: index,
              name: property.title,
              width: 200,
              resizable: true,
              sortable: true,
              color: property.hexColor,
              dataType: property.dataType,
              sortOrder: property.sortOrder,
              formatter: ({ column, row }) => getFormattedCell(column, row)
            });
          }
        }
        return list;
      },
      []
    );

    setColumns([...nextColumns]);
  }, [propertyMap, selectedProperties]);

  const getEntityNameCell = (row): ReactElement => {
    return (
      <NameCellWrapper
        onClick={() => {
          if (row.uwi) {
            const obj = {};
            obj[row.uwi] = { Uwi: row.uwi };
            if (isWell) {
              dispatch(setSelectedWells(obj));
            } else if (isFacility) {
              dispatch(setSelectedFacilities(obj));
            }
          }
        }}>
        <EntityColorIcon uwiHex={row.color} />
        {row[0]}
      </NameCellWrapper>
    );
  };

  const getFormattedCell = (column, row): ReactElement => {
    return (
      <CellWrapper
        isNumber={column.dataType === "Number" || column.dataType === "Integer"}>
        {row[column.idx]}
      </CellWrapper>
    );
  };

  // Update selected wells on map upon selection of rows on data table
  useEffect(() => {
    // retrieve UWI of wells in data table, both formatted and unformatted
    for (let index = 0; index < rows.length; index++) {
      matchedUWIs.push({
        formatted: rows[index][0],
        unformatted: uwis[index]
      });
    }
    const selectedWells = {};
    selectedRows.forEach((row) => {
      let alternate = "";
      for (let index = 0; index < matchedUWIs.length; index++) {
        if (row === matchedUWIs[index]["formatted"]) {
          alternate = matchedUWIs[index]["unformatted"];
        }
      }
      selectedWells[alternate] = { Uwi: alternate };
    });

    dispatch(setSelectedWells(selectedWells));
  }, [selectedRows]);

  // Update rows on data table upon select of wells on map
  useEffect(() => {
    // Get uwis
    const mapWells = Object.keys(selectedWellsOnMap);

    // Clear selected rows if no selected wells on map
    selectedRows.clear();

    // retrieve UWI of wells in data table, both formatted and unformatted
    for (let index = 0; index < rows.length; index++) {
      matchedUWIs.push({
        formatted: rows[index][0],
        unformatted: uwis[index]
      });
    }

    // Add selected rows
    mapWells.forEach((well) => {
      let alternate = "";
      for (let index = 0; index < matchedUWIs.length; index++) {
        if (well == matchedUWIs[index]["unformatted"]) {
          alternate = matchedUWIs[index]["formatted"];
        }
      }
      selectedRows.add(alternate);
    });
  }, [selectedWellsOnMap]);

  // Track any changes in categories
  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getCategoryList(entityKind));
    }
  }, [categories, dispatch]);

  useEffect(() => {
    if (selectedProperties && selectedProperties.length) {
      refetch();
    }
  }, [refetch, groupBy, selectedProperties, dataSources]);

  useEffect(() => {
    if (selectedProperties && selectedProperties.length) {
      const settings = {
        selectedProperties: selectedProperties
      };
      const newSettings = JSON.stringify(settings);

      // Update the session storage.
      sessionStorage.setItem(sessionKey, newSettings);

      if (!!lastSettings && !dequal(lastSettings, newSettings)) {
        // The data table settings have changed, so mark the dashboard as modified.
        dashboardDispatch({
          payload: {
            hasModifiedWidgets: true
          }
        });
      }
      setLastSettings(newSettings);
    }

    // check if pagination is needed
    const totalCells = selectedProperties?.length * totalResults;
    if (totalCells > MAX_CELL_COUNT) {
      setHasToConfirmPagination(true);
      turnPaginationOn(tableDispatch);
      changePageSize(
        tableDispatch,
        Math.floor(MAX_CELL_COUNT / selectedProperties?.length)
      );
    } else {
      turnPaginationOff(tableDispatch);
      changePageSize(tableDispatch, totalResults);
    }
  }, [selectedProperties]);

  useEffect(() => {
    // Array to store correct order of categories
    const orderedCategories = categories.slice().sort(function (a, b) {
      return a.sortOrder - b.sortOrder;
    });

    const [propertyTree, newPropertyMap] = getColumnPropertyTree(
      [...orderedCategories],
      entityKind
    );

    setPropertyTree(propertyTree);
    setPropertyMap(newPropertyMap);
  }, [categories]);

  function copyColumnContents(columnName, rows, columnKey: string) {
    const columnData = rows.map((row) => row[columnKey]);
    const headerAndData = [columnName, ...columnData].join("\n");
    navigator.clipboard
      .writeText(headerAndData)
      .then(() => {
        setShowCopySuccessMessage((prev) => ({ ...prev, [columnKey]: true }));
        setTimeout(() => {
          setShowCopySuccessMessage((prev) => ({ ...prev, [columnKey]: false }));
        }, 2000);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console -- Necessary for debugging copy errors.
        console.error(`Failed to copy. ${err}`);
      });
  }

  const handleCellClick = (_, row, column) => {
    if (hasTrialAccount || isReadonly) {
      return;
    }
    const cellValue = row[column.key];
    navigator.clipboard.writeText(cellValue).catch((err) => {
      // eslint-disable-next-line no-console -- Necessary for debugging copy errors.
      console.error(`Failed to copy. ${err}`);
    });
  };

  // Create columns that can be sorted
  const sortableColumns = useMemo(() => {
    // Custom header renderer function
    function HeaderRenderer(props: HeaderRendererProps<never>) {
      // SortableHeaderCell is the element react-data-grid uses if custom header renderer is not used but columns are sortable

      const handleColumnCopyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        copyColumnContents(props.column.name, rows, props.column.key);
      };

      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <SortableHeaderCell {...props}>
          <HeaderContainer
            title={props.column.name?.toString() ?? ""} //tooltip
            style={{
              color: props.column.color,
              backgroundColor: props.column.color + 20,
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              position: "fixed",
              left: "0px",
              right: "0px",
              textIndent: "5px"
            }}>
            {props.column.name}
            {!hasTrialAccount && !isReadonly && (
              <Tooltip title="Copy Data">
                <Popover
                  open={showCopySuccessMessage[props.column.key] || false}
                  trigger="click"
                  overlayClassName="overlay-no-padding"
                  content={<Alert type="success" message="Copied to Clipboard!" />}>
                  <CopyButton onClick={handleColumnCopyClick}>
                    <Icon path={mdiContentCopy} size={1} />
                  </CopyButton>
                </Popover>
              </Tooltip>
            )}
          </HeaderContainer>
        </SortableHeaderCell>
      );
    }

    // Return columns with the header renderer applied
    return columns.map((c) => {
      if (c.key === "select-row") {
        return c;
      }
      return { ...c, headerRenderer: HeaderRenderer };
    });
  }, [columns, rows, showCopySuccessMessage]);

  const onSortColumnsChange = useCallback(
    (sortColumns: SortColumn[]) => {
      const { columnKey, direction } = sortColumns.length
        ? sortColumns[0]
        : { columnKey: 0, direction: "NONE" };
      const sortDirection = direction.toLocaleLowerCase() as SortDirectionT;

      const sortColumn = selectedProperties[columnKey];

      setSortColumns(sortColumns.slice(-1));
      changeSortColumn(tableDispatch, sortColumn);
      changeSortDirection(tableDispatch, sortDirection);
    },
    [selectedProperties, tableDispatch]
  );

  const gridClassnames = classnames(rdgThemeClassname);

  const onConfirmLoad = () => {
    setHasToConfirmPagination(false);
  };

  return (
    <Wrapper
      className={`${pagination ? "paginationToolbar" : ""}`}
      toolbarHeight={toolbarHeight}>
      {hiddenMessage && (
        <AlertContainer>
          <Alert message={hiddenMessage} type="warning" />
        </AlertContainer>
      )}

      <Toolbar ref={toolbarRef}>
        <DataTableColumnChooser
          categories={propertyTree}
          onPropertiesChanged={(propertyKeys) => setSelectedProperties(propertyKeys)}
          selectedPropertyKeys={selectedProperties}
        />
        <GroupByProvider entityKind={entityKind}>
          <DataTableActions
            selectedProperties={selectedProperties}
            onSelectedColumnSetChanged={(columnSet: IColumnSet) => {
              setSelectedProperties(columnSet.properties);
            }}
            dataSources={dataSources}
            initialState={initialState}
            onSourcesChanged={(sources) => {
              const settings = {
                selectedProperties: selectedProperties,
                pdenSource: sources.pdenSource
              };
              const newSettings = JSON.stringify(settings);
              sessionStorage.setItem(sessionKey, newSettings);
              setDataSources({
                ...sources
              });
            }}
            rows={rows}
            columns={columns}
          />
        </GroupByProvider>
      </Toolbar>

      <AutoSizer>
        {({ width, height }) => (
          <StyledGrid
            className={gridClassnames}
            columns={sortableColumns}
            rows={!hiddenMessage ? rows : []}
            rowKeyGetter={(row) => row[0]}
            sortColumns={sortColumns}
            selectedRows={selectedRows}
            style={{
              width: `${Number(width)}px`,
              height: pagination
                ? `${Number(height - toolbarHeight - paginationHeight)}px`
                : `${Number(height - toolbarHeight)}px`
            }}
            onSortColumnsChange={onSortColumnsChange}
            onSelectedRowsChange={setSelectedRows}
            onRowClick={handleCellClick}
          />
        )}
      </AutoSizer>

      {pagination && <Pagination />}

      <ConfirmIndicator className={`${!hasToConfirmPagination ? "hidden" : ""}`}>
        <ConfirmDialog
          title="Large amount of data"
          message="You are attempting to load a large amount of data which will be loaded across multiple pages."
          onConfirm={onConfirmLoad}
        />
      </ConfirmIndicator>

      {errorMessage && (
        <AlertContainer>
          <Alert type="error" message={errorMessage}></Alert>
        </AlertContainer>
      )}

      <BusyIndicator visible={isFetching} />
    </Wrapper>
  );
};

export default DataTable;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

const CopyButton = styled(Button)`
  // Overlay copy icon on data table columns if header width is small.
  position: absolute;
  right: 0; // Place button on the right edge of the container.
  margin-right: 15px;
  background-color: transparent;
  border: none;
  padding: 0;
  opacity: 0;
  transition: opacity 0.2s;

  ${HeaderContainer}:hover & {
    opacity: 1;
    background: none;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: ${(p) => Number(p.toolbarHeight) || "50"}px 1fr;

  &.paginationToolbar {
    grid-template-rows: ${(p) => Number(p.toolbarHeight) || "50"}px 1fr 40px;
  }

  width: 100%;
  height: 100%;
`;

const Toolbar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(0, max-content);
  gap: 8px;
  align-items: center;
  padding: 8px;
`;

const StyledGrid = styled(DataGrid)`
  overflow: hidden;

  &:hover {
    overflow: scroll;
  }

  // Highlighting individual cells on hover.
  .rdg-cell {
    &:hover {
      background-color: rgba(220, 233, 246, 1);
    }
  }
`;

const CellWrapper = styled.div`
  font-family: var(--fontMono);
  text-align: ${(props) => (props.isNumber ? "right" : "left")};
`;
const NameCellWrapper = styled.div`
  font-family: var(--fontMono);
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;
const EntityColorIcon = styled.span`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  background-color: ${(p) => p.uwiHex};
  border-radius: 2px;
`;

const AlertContainer = styled.div`
  position: absolute;
  left: 0;
  top: 86px;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cccccc8d;
`;

const ConfirmIndicator = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(211, 211, 211, 0.424);
  z-index: 5;

  &.hidden {
    display: none;
  }

  .title {
    font-size: 2.2rem;
    font-weight: var(--fontWeightBold);
    color: var(--color-text);
  }

  .content {
    p {
      font-size: 1.4rem;
      font-weight: var(--fontWeightMedium);
      margin-bottom: 15px;
    }

    .button {
      height: 38px;
      width: 100px;
      border-radius: 4px;

      span {
        font-size: 1.6rem;
      }
    }

    .actions {
      gap: 10px;
    }
  }
`;
