import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Add, Delete, Info, Save } from "@material-ui/icons";
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  Popconfirm,
  Popover,
  Row,
  Select,
  Switch,
  Tabs,
  Tooltip,
  Typography
} from "antd";
import _debounce from "lodash/debounce";
import {
  setActiveColorPalette,
  setColorPalettes
} from "store/features/userSettings/userSettingsSlice";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { getOrganizationPalettes } from "api/userSettings";

import { BusyIndicator } from "components/activity/shared";
import { Heading } from "components/base";

import { useUserContext } from "../user/context";
import { updateSettingPalette } from "../user/context/reducer";
import { useUserDefaults, useUserSettingMutation } from "../user/hooks";
import PaletteColors from "./PaletteColors";
import PreferredColors from "./PreferredColors";
import { DEFAULT_COLOR_PALETTE_SETTING } from "./constants/userSetting.constants";
import useColorPalettes from "./hooks/useColorPalettes";
import useDeletePalette from "./hooks/useDeletePalette";
import useUpdateColorPalette from "./hooks/useUpdateColorPalette";
import { DefaultPalette as DefaultPaletteModel } from "./models";
import {
  DEFAULT_COLOR_PALETTE_NAME,
  IColorPalette,
  PaletteType
} from "./models/IColorPalette";

const { Title } = Typography;
export default function ColorPalette() {
  const { data, isLoading, isError, refetch } = useColorPalettes("user");
  const [selectedPalette, setSelectedPalette] = useState<IColorPalette>(null);
  const updateMutation = useUpdateColorPalette();
  const [numberOfColors, setNumberOfColors] = useState<number>(null);
  const [thickness, setThickness] = useState<number>(null);
  const [form] = Form.useForm();
  const [isChanged, setIsChanged] = useState<boolean>();
  const [currentPaletteId, setCurrentPaletteId] = useState<string>(null);
  const [defaultPalette, setDefaultPalette] = useState([]);
  const [activeKey, setActiveKey] = useState("Colours");
  const [isPaletteLoaded, setIsPaletteLoaded] = useState(false);
  const isNewPalette = !data?.some((p) => p.name === selectedPalette?.name);

  const userSettingMutation = useUserSettingMutation<DefaultPaletteModel>(
    DEFAULT_COLOR_PALETTE_SETTING
  );

  const { colorPalette: userDefaultColorPalette } = useUserDefaults();

  const getUserDefaultColorPalette = useCallback(() => {
    return userDefaultColorPalette;
  }, [userDefaultColorPalette]);

  const activeColorPalette = useSelector(
    (state: RootState) => state.userSetting.activeColorPalette
  );

  // Load the first palette in the users list of palettes
  useEffect(() => {
    if (data && data.length > 0 && !isPaletteLoaded) {
      setSelectedPalette(data[0]);
      setCurrentPaletteId(data[0].id);
      setNumberOfColors(data[0]?.colors?.length);
      setIsPaletteLoaded(true);
    }
  }, [data, isPaletteLoaded]);

  const list = [
    {
      label: "Colours",
      key: "Colours",
      children: (
        <PaletteColors
          selectedPalette={selectedPalette}
          thickness={thickness}
          numberOfColors={numberOfColors}
          setSelectedPalette={setSelectedPalette}
          setNumberOfColors={setNumberOfColors}
          setIsChanged={setIsChanged}
          updateThickness={updateThickness}
        />
      )
    },
    {
      label: "Preferred Colours",
      key: "Preferred Colours",
      children: (
        <PreferredColors
          preferredColorsFromPalette={selectedPalette?.preferredColors}
          selectedPalette={selectedPalette}
          isNewPalette={isNewPalette}
        />
      )
    }
  ];
  const [, userContextDispatch] = useUserContext();

  const dispatch = useDispatch();
  const onTabChange = (activeKey: string) => {
    setActiveKey(activeKey);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateMutationError: any = updateMutation.error;
  const deleteMutation = useDeletePalette(() => {
    setSelectedPalette(null);
    refetch();
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deleteMutationError: any = deleteMutation.error;
  const palettes = useSelector((state: RootState) => state.userSetting.colorPalettes);

  // Set color palette type to discrete (interpolation OFF) if the user has < 2 colors selected
  useEffect(() => {
    if (numberOfColors < 2 && selectedPalette?.paletteType !== PaletteType.Discrete) {
      setSelectedPalette({
        ...selectedPalette,
        paletteType: PaletteType.Discrete
      });
    }
  }, [numberOfColors, selectedPalette]);

  useEffect(() => {
    let paletteId = 0;
    if (data?.length > 0) {
      paletteId = Math.min(...data.map((obj) => parseInt(obj.id)));
    }
    const newColorPaletteList: IColorPalette[] = [
      ...(palettes?.map((palette: IColorPalette) => {
        paletteId--;
        return {
          colors: palette.colors,
          colorScale: palette.colorScale,
          id: paletteId.toString(),
          name: palette.name,
          paletteType: 0,
          shared: false,
          thickness: Array.from({ length: palette.colors.length }, () => 2),
          preferredColors: palette.preferredColors
        };
      }) || [])
    ];
    setDefaultPalette(newColorPaletteList);
    if (!data || data.length == 0) {
      return;
    }
    const found = data.find((f) => f.id === currentPaletteId);
    setSelectedPalette(found ?? data[0]);
  }, [data]);

  async function onSavePalette(palette) {
    if (!palette) {
      return;
    }
    await updateMutation.mutateAsync(palette);
    refetch();
  }

  function updateColors(numColors: number, palette: IColorPalette) {
    if (!numColors || !palette) {
      return;
    }
    if (palette.colors.length > numColors) {
      palette.colors = palette.colors.slice(0, numColors);
      palette.thickness = palette.thickness.slice(0, numColors);
    } else {
      palette.colors = palette.colors.concat(
        [...Array(numColors - palette.colors.length).keys()].map(() => "#000")
      );
      palette.thickness = palette.thickness.concat(
        [...Array(numColors - palette.thickness.length).keys()].map(() => 2)
      );
    }
    const updatedPalette = Object.assign({}, palette, {
      colors: [...palette.colors],
      thickness: [...palette.thickness]
    });
    setIsChanged(true);
    setSelectedPalette(updatedPalette);
  }

  const updateColorsDebounce = useRef(
    _debounce((num, palette) => {
      updateColors(num, palette);
    }, 800)
  ).current;

  function updateThickness(thickness: number) {
    const newThickness = selectedPalette.colors.map(() => thickness);
    const updatedPalette = Object.assign({}, selectedPalette, {
      thickness: newThickness,
      colors: [...selectedPalette.colors]
    });
    setIsChanged(true);
    setThickness(thickness);
    setSelectedPalette(updatedPalette);
  }

  useEffect(() => {
    updateColorsDebounce(numberOfColors, selectedPalette);
  }, [numberOfColors, updateColorsDebounce]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!userSettingMutation?.data?.id) return;

    updateSettingPalette(userContextDispatch, {
      id: userSettingMutation?.data?.id,
      name: userSettingMutation?.data?.name,
      reverse: userSettingMutation?.data?.reverse
    });
  }, [userSettingMutation?.data]);

  if (isError) {
    return <RootContainer>{"We're sorry, an error occurred"}</RootContainer>;
  }
  if (isLoading) {
    return <BusyIndicator />;
  }
  return (
    <RootContainer>
      <SelectWrapper>
        <Heading element="h4">Colour Palette</Heading>
        <Select
          value={selectedPalette?.name ?? ""}
          onChange={(val) => {
            const index = data.findIndex((p) => p.name === val);
            if (index >= 0) {
              setSelectedPalette(data[index]);
              setThickness(null);
              setIsChanged(selectedPalette && currentPaletteId === data[index]?.id);
              setNumberOfColors(data[index]?.colors?.length);
              setCurrentPaletteId(data[index]?.id);
            }
          }}
          options={(data ?? []).map((palette) => ({
            label: palette.name,
            value: palette.name
          }))}
        />
        <Popover
          trigger="click"
          content={
            <NewPaletteContainer>
              <Form layout="vertical" form={form}>
                <Form.Item label={"Name"} name="name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Start From" name="startFrom">
                  <Select
                    options={[{ label: "Blank", value: "" }].concat(
                      (defaultPalette ?? []).map((item) => ({
                        label: item.name,
                        value: item.id
                      }))
                    )}
                  />
                </Form.Item>
                <RightJustified>
                  <Button
                    className="new-palette-btn"
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                      form
                        .validateFields()
                        .then(() => {
                          const startFrom = form.getFieldValue("startFrom");
                          const name = form.getFieldValue("name");
                          let newPalette = {
                            colors: [],
                            thickness: [],
                            shared: false,
                            id:
                              data.length == 0
                                ? "-1"
                                : (
                                    Math.min(...data.map((d) => parseInt(d.id))) - 1
                                  ).toString(),
                            name: name,
                            paletteType: PaletteType.Continuous
                          };
                          if (startFrom) {
                            const palette = defaultPalette.find(
                              (p) => p.id === startFrom
                            );
                            if (palette) {
                              newPalette = Object.assign({}, palette, {
                                name,
                                colorScale: [],
                                id:
                                  data.length == 0
                                    ? "-1"
                                    : (
                                        Math.min(...data.map((d) => parseInt(d.id))) - 1
                                      ).toString()
                              });
                            }
                          }
                          setSelectedPalette(newPalette);
                          setCurrentPaletteId(newPalette.id);
                          setNumberOfColors(newPalette?.colors?.length);
                          setIsChanged(true);
                        })
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        .catch(() => {
                          toast.error("An error occurred while validating form.");
                        });
                    }}>
                    New Palette
                  </Button>
                </RightJustified>
              </Form>
            </NewPaletteContainer>
          }>
          <Button icon={<Add />} type="link">
            New Palette
          </Button>
        </Popover>
      </SelectWrapper>
      <Divider />
      {selectedPalette?.name && (
        <ContentContainer>
          <ContentRow>
            <Title level={5}>Name</Title>
            <Input
              style={{ width: 300 }}
              required
              minLength={2}
              onChange={(evt) => {
                const newName = evt.target.value;
                const updatedPalette = Object.assign({}, selectedPalette, {
                  name: newName
                });

                // Colour palette should change to default if the name has been changed.
                if (selectedPalette.id === activeColorPalette.id) {
                  const systemDefaultColorPalette = defaultPalette.find(
                    (p) => p.name === DEFAULT_COLOR_PALETTE_NAME
                  );
                  userSettingMutation.mutate({
                    id: getUserDefaultColorPalette()?.id,
                    name: systemDefaultColorPalette.name,
                    reverse: systemDefaultColorPalette?.reverse
                  });
                }

                setSelectedPalette(updatedPalette);
              }}
              value={selectedPalette?.name}
            />
          </ContentRow>
          <ColorTabsContainer>
            <Tabs activeKey={activeKey} type="card" items={list} onChange={onTabChange} />
          </ColorTabsContainer>
          {activeKey === "Colours" && (
            <>
              <ContentRow>
                <Title className="interopolate-title" level={5}>
                  Interpolate colours
                  <Tooltip title="When toggled on the colours will not repeat if there are more items than colours. The colours will be interpolated between two key colours. Select two or more colours to enable this option.">
                    <Info />
                  </Tooltip>
                </Title>
                <Switch
                  checked={
                    selectedPalette?.paletteType === PaletteType.Continuous &&
                    !(numberOfColors < 2)
                  }
                  disabled={numberOfColors < 2}
                  onChange={(val) => {
                    setSelectedPalette(
                      Object.assign({}, selectedPalette, {
                        paletteType:
                          val && !(numberOfColors < 2)
                            ? PaletteType.Continuous
                            : PaletteType.Discrete
                      })
                    );
                  }}
                />
              </ContentRow>
              <ContentRow>
                <Title className="interopolate-title" level={5}>
                  Share with others
                  <Tooltip title="When toggled on the colour palette will be visible to everyone in the organization.">
                    <Info />
                  </Tooltip>
                </Title>
                <Switch
                  checked={selectedPalette?.shared ?? false}
                  onChange={(val) => {
                    setSelectedPalette(
                      Object.assign({}, selectedPalette, {
                        shared: val
                      })
                    );
                  }}
                />
              </ContentRow>
              <SaveRow>
                <Popconfirm
                  title={`Are you sure you want to delete "${selectedPalette?.name}" palette?`}
                  onConfirm={() => {
                    deleteMutation.mutate(selectedPalette.id);
                    // Reset the active color palette if the one being deleted is the active one to prevent errors.
                    if (selectedPalette.id === activeColorPalette.id) {
                      const systemDefaultColorPalette = defaultPalette.find(
                        (p) => p.name === DEFAULT_COLOR_PALETTE_NAME
                      );
                      userSettingMutation.mutate({
                        id: getUserDefaultColorPalette()?.id,
                        name: systemDefaultColorPalette.name,
                        reverse: systemDefaultColorPalette?.reverse
                      });
                      dispatch(setActiveColorPalette(systemDefaultColorPalette));
                    }

                    // Remove palette being deleted from the list of palettes.
                    const updatedPalettes = palettes.filter(
                      (p) => p.id !== selectedPalette.id
                    );
                    dispatch(setColorPalettes(updatedPalettes));
                  }}
                  onCancel={null}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    icon={<Delete />}
                    disabled={
                      !selectedPalette ||
                      !data.some((p) => p.name === selectedPalette.name)
                    }
                    onClick={() => {
                      confirm;
                    }}>
                    Delete
                  </Button>
                </Popconfirm>

                <Button
                  icon={<Save />}
                  type={isChanged ? "primary" : "default"}
                  disabled={!selectedPalette || !numberOfColors || numberOfColors <= 0}
                  onClick={async () => {
                    updateMutation.reset();
                    await onSavePalette(selectedPalette);
                    let organizationPalettes = await getOrganizationPalettes();
                    organizationPalettes = organizationPalettes.map((p) => ({
                      ...p,
                      reverse: false
                    }));
                    dispatch(setColorPalettes(organizationPalettes));
                    setIsChanged(false);
                  }}>
                  Save
                </Button>
              </SaveRow>
            </>
          )}
          <ContentRow>
            {updateMutation.isSuccess && (
              <Alert
                type="success"
                closable
                banner
                message={`Successfully updated ${selectedPalette?.name ?? ""}`}></Alert>
            )}
            {updateMutation.isError && (
              <Alert
                type="error"
                closable
                banner
                message={
                  <div>{updateMutationError?.response?.data ?? updateMutationError}</div>
                }
              />
            )}
            {deleteMutation.isError && (
              <Alert
                type="error"
                closable
                banner
                message={
                  <div>{deleteMutationError?.response?.data ?? updateMutationError}</div>
                }
              />
            )}
          </ContentRow>
        </ContentContainer>
      )}
    </RootContainer>
  );
}

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  max-width: 700px;

  .ant-btn {
    min-height: 50px;
    display: flex;
    align-items: center;
  }

  .heading {
    min-width: 200px;
  }

  .ant-select {
    width: 100%;
  }
`;

const RightJustified = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const RootContainer = styled.div`
  width: 100%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  padding: 32px 100px;

  .new-color-btn-row {
    width: 100%;
    justify-content: flex-end !important;
  }

  .ant-divider {
    margin: 10px 0;
  }

  .ant-input {
    min-height: 40px;
  }

  .ant-btn {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .ant-row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }

  .ant-select {
    font-size: 1.4em;

    .ant-select-selector {
      min-height: 50px;
      align-items: center;
    }
  }

  .ant-typography {
    margin-bottom: 0;
  }

  .ant-card {
    width: 100%;
  }
`;

const NewPaletteContainer = styled.div`
  min-width: 300px;
`;

const ContentRow = styled(Row)`
  padding: 5px;
  padding-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;

  .ant-card {
    height: 400px;
    max-height: 400px;
    overflow-y: auto;
  }

  .interopolate-title {
    gap: 4px;
    display: flex;
    align-items: center;
  }
`;

const ContentContainer = styled.div`
  width: 800px;
  padding-right: 100px;
`;

const SaveRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  padding: 10px 5px;
  padding-top: 20px;

  .ant-btn {
    min-width: 100px;
    justify-content: center;
    min-height: 40px;
  }
`;

const ColorTabsContainer = styled.div`
  height: 100%;

  .ant-tabs {
    height: 100%;
    overflow: hidden;

    .ant-tabs-content {
      height: 100%;
      overflow: hidden;

      .ant-tabs-tabpane {
        height: 100%;
        overflow: hidden;
      }
    }
  }
`;
