import { FC, useState } from "react";

import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { Input, Select, Slider, Switch } from "antd";
import styled from "styled-components";

import { PdenSourceEnum } from "models/pdenDataSourceSetting";

import { BaseButton, BaseIconToggle, BaseRadioInput } from "../../base";
import GroupBy from "../../groupBy";
import { BubbleOptionsT, MapVisOptionItem } from "./MapVis";

export interface WellBubbleVisProps {
  bubbleExtrudeField: { property: string; title: string }[];
  setBubbleExtrudeField: (
    value: {
      property: string;
      title: string;
      product?: string;
      unit?: string;
      pdenSource?: PdenSourceEnum;
    }[]
  ) => void;
  setShowBubbleExtrudeFields: (value: boolean) => void;
  onChange: (options: BubbleOptionsT) => void;
  options: BubbleOptionsT;
}

export const WellBubbleVis: FC<WellBubbleVisProps> = ({
  bubbleExtrudeField,
  setBubbleExtrudeField,
  setShowBubbleExtrudeFields,
  options,
  onChange
}) => {
  const [maxRadiusSizeMeters, setMaxRadiusSizeMeters] = useState<string>(
    options.maxRadiusSizeMeters?.toString() || ""
  );

  return (
    <>
      <BubbleContainer className="ipdb-option-item bubble-container">
        <GroupBy
          value={{ property: "", title: "Select Field" }}
          dataTypeFilters={["Number", "Integer"]}
          onChange={(
            value,
            forecastToggleOn,
            selectedForecastFolder,
            selectedForecastFolderName,
            selectedPdenSource
          ) => {
            const unit = value.title.match(/\(([^)]+)\)/);
            setBubbleExtrudeField([
              ...bubbleExtrudeField,
              {
                property: value.property,
                title: value.title,
                product: value.product,
                unit: unit?.length > 1 ? unit[1] : undefined,
                pdenSource: selectedPdenSource
              }
            ]);
            setShowBubbleExtrudeFields(false);
          }}>
          <TriggerWrapper>
            <BaseButton appearance="primary">Select Field</BaseButton>
          </TriggerWrapper>
        </GroupBy>
        <FieldListContainer>
          {bubbleExtrudeField.map((field, index) => (
            <div key={field.property}>
              <label>
                {field.property && (
                  <span className="removable-property flex-row items-center">
                    {field.title}
                    <BaseIconToggle size={0} squareIcon>
                      <RemoveCircleOutlineIcon
                        onClick={(e) => {
                          const dup = [...bubbleExtrudeField];
                          dup.splice(index, 1);
                          setBubbleExtrudeField(dup);
                          e.stopPropagation();
                        }}
                        style={{ fontSize: "16px" }}
                      />
                    </BaseIconToggle>
                  </span>
                )}
              </label>
            </div>
          ))}
        </FieldListContainer>
      </BubbleContainer>
      <div className="separator" />
      <MapVisOptionItem>
        <label>Max Radius (m)</label>
        <Input
          type="number"
          value={maxRadiusSizeMeters}
          onChange={(value) => {
            setMaxRadiusSizeMeters(value.target.value);
            onChange({ ...options, maxRadiusSizeMeters: parseFloat(value.target.value) });
          }}
        />
      </MapVisOptionItem>
      <MapVisOptionItem>
        <label>Placement</label>
        <Select
          options={["midpoint", "bottom"].map((o) => ({ label: o, value: o }))}
          value={options.location}
          onChange={(value) => {
            onChange({ ...options, location: value });
          }}
        />
      </MapVisOptionItem>
      <MapVisOptionItem>
        <label>Opacity</label>
        <Slider
          value={options.opacity}
          onChange={(val) => onChange({ ...options, opacity: val })}
        />
      </MapVisOptionItem>
      <MapVisOptionItem>
        <label>Show Legend</label>
        <Switch
          checked={options.visible}
          onChange={(val) => {
            onChange({ ...options, visible: val });
          }}
        />
      </MapVisOptionItem>
      <MapVisOptionItem>
        <label>Bubble Style</label>
        <StyleWrapper>
          <div className="flex-row">
            <BaseRadioInput
              name="showBubbleExtrude"
              value={options.visualType}
              modelValue="showBubble"
              onChange={(val) => onChange({ ...options, visualType: val })}
              label=""></BaseRadioInput>
            <label className={`${options.visualType === "showBubble" && "active"}`}>
              2D
            </label>
          </div>
          <div className="flex-row">
            <BaseRadioInput
              value={options.visualType}
              modelValue="showExtrude"
              name="showBubbleExtrude"
              disabled={bubbleExtrudeField.length > 1}
              onChange={(val) => onChange({ ...options, visualType: val })}
              label=""></BaseRadioInput>
            <label className={`${options.visualType === "showExtrude" && "active"}`}>
              3D
            </label>
          </div>
        </StyleWrapper>
      </MapVisOptionItem>
      <div className="separator" />
    </>
  );
};

const StyleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px 0;
`;

const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FieldListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TriggerWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  cursor: pointer;
`;
