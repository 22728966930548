import { EntityKind } from "models/entityKind";

import { LegendItemModel } from "../../../models";
import { EChartsSeries, IChartSeries, IChartSettings } from "../../../models/chart";
import { ChartSeries } from "../../../models/model";
import { MinMaxT } from "../ChartWrapper";
import { StateT } from "../context";
import { createLegendItem } from "../utils";
import { BaseChart } from "./baseChart";

export class WellContributionChart extends BaseChart {
  getDefaultAxisMinMax(): MinMaxT {
    return {
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null
    };
  }

  getCustomLegendTitle(chartState: StateT): string {
    if (!chartState?.settings?.useChartFocus) {
      //if useChartFocus is off, we need to override the facility legend title with
      //the well legend title and well legend defaults to Operator
      return "Operator";
    }
    //return null so that the default legend title is used
    return null;
  }

  getCustomLegendItems(
    series: ChartSeries[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _settings: IChartSettings,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    entityKind: EntityKind
  ): LegendItemModel[] {
    if (!series) {
      return [];
    }
    return series.map((s) => createLegendItem(s.label, true, s.style.hexColor));
  }

  finalizeSeries(series: EChartsSeries): EChartsSeries {
    if (series.group !== "Facility") {
      return series;
    }
    const s = { ...series };
    delete s["areaStyle"];
    return s;
  }

  getSeriesStackGroup(series: IChartSeries): string {
    if (series.groupTitle === "Facility") {
      return "";
    }
    return "group";
  }

  getDefaultAxis(sessionMinMax?: MinMaxT): MinMaxT {
    const defaultMinMax = this.getDefaultAxisMinMax();
    if (!sessionMinMax) {
      return defaultMinMax;
    }
    const yMax = !isNaN(parseFloat(sessionMinMax?.yMax?.toString()))
      ? parseFloat(sessionMinMax?.yMax?.toString())
      : defaultMinMax.yMax;
    const yMin = !isNaN(parseFloat(sessionMinMax?.yMin?.toString()))
      ? parseFloat(sessionMinMax?.yMin?.toString())
      : defaultMinMax.yMin;
    const xMax = !isNaN(parseFloat(sessionMinMax?.xMax?.toString()))
      ? parseFloat(sessionMinMax?.xMax?.toString())
      : defaultMinMax.xMax;
    const xMin = !isNaN(parseFloat(sessionMinMax?.xMin?.toString()))
      ? parseFloat(sessionMinMax?.xMin?.toString())
      : defaultMinMax.xMin;
    return {
      xMin,
      xMax,
      yMin,
      yMax
    };
  }
}
