import { useQuery } from "@apollo/client";

import { DASHBOARDS, WorkspaceClientName } from "api/workspace";

import { WorkspaceQuery } from "models/workspace";

///hook to fetch Dashboard
export default function useDashboards() {
  return useQuery<WorkspaceQuery>(DASHBOARDS, {
    context: {
      clientName: WorkspaceClientName
    }
    // fetchPolicy: 'no-cache', // Avoid any caching
    // notifyOnNetworkStatusChange: true // Ensure updates trigger re-renders
  });
}
